import { AxiosError } from "axios";
import { useMutation } from "react-query";
import httpService from "../httpService";
import { ApiEndPoints, ApiResponse } from "../Types";
import { useEpisodeDataStore } from "../../DataStores";
import { IEpisodeTopic } from "../../Types";

type ContentUpdateVariables = {
  episodeId: string;
  file: File;
  topicId: string;
};

export type ContentUpdateResponse = {
  topics: IEpisodeTopic[];
  type: string;
  updatedTopicId: string;
  url: string;
};

const updateContent = async (contentObj: ContentUpdateVariables) => {
  const formData = new FormData();

  (Object.keys(contentObj) as (keyof ContentUpdateVariables)[]).forEach(key => {
    formData.append(key, contentObj[key]);
  });

  const { data } = await httpService.post<ApiResponse<ContentUpdateResponse>>(
    ApiEndPoints.TopicContent,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );

  return data.result;
};

export const useTopicContent = () => {
  const { setSectionLoading, handleTopicContentUpdate } = useEpisodeDataStore();

  return useMutation<ContentUpdateResponse, AxiosError<{ error: unknown }>, ContentUpdateVariables>(
    contentObj => updateContent(contentObj),
    {
      retry: 0,
      onMutate: async () => {
        setSectionLoading("content");
      },
      onSuccess: data => {
        handleTopicContentUpdate(data);
      },
      onSettled: () => {
        setSectionLoading("content", false);
      }
    }
  );
};

type ContentTransparentVariables = {
  episodeId: string;
  topicId: string;
};

const transparentContent = async (contentObj: ContentTransparentVariables) => {
  const { data } = await httpService.post<ApiResponse<ContentUpdateResponse>>(
    `episode-topics-upload/topic-content-transparent`,
    contentObj
  );

  return data.result;
};

export const useTopicContentTransparent = () => {
  const { setSectionLoading, handleTopicContentUpdate } = useEpisodeDataStore();

  return useMutation<
    ContentUpdateResponse,
    AxiosError<{ error: unknown }>,
    ContentTransparentVariables
  >(contentObj => transparentContent(contentObj), {
    retry: 0,
    onMutate: async () => {
      setSectionLoading("content");
    },
    onSuccess: data => {
      handleTopicContentUpdate(data);
    },
    onSettled: () => {
      setSectionLoading("content", false);
    }
  });
};

type ContentYoutubeVariables = {
  episodeId: string;
  topicId: string;
  videoUrl: string;
};

const youtubeContent = async (contentObj: ContentYoutubeVariables) => {
  const { data } = await httpService.post<ApiResponse<ContentUpdateResponse>>(
    `episode-topics-upload/topic-content-youtube`,
    contentObj
  );

  return data.result;
};

export const useTopicContentYoutube = () => {
  const { setSectionLoading, handleTopicContentUpdate } = useEpisodeDataStore();

  return useMutation<
    ContentUpdateResponse,
    AxiosError<{ error: unknown }>,
    ContentYoutubeVariables
  >(contentObj => youtubeContent(contentObj), {
    retry: 0,
    onMutate: async () => {
      setSectionLoading("content");
    },
    onSuccess: data => {
      handleTopicContentUpdate(data);
    },
    onSettled: () => {
      setSectionLoading("content", false);
    }
  });
};

type ContentDeleteVariables = {
  episodeId: string;
  topicId: string;
};

const deleteContent = async (contentObj: ContentDeleteVariables) => {
  const { data } = await httpService.delete<ApiResponse<ContentUpdateResponse>>(
    `${process.env.REACT_APP_REST_API}episode-topics-upload/${contentObj.episodeId}/content/${contentObj.topicId}`
  );

  return data.result;
};

export const useTopicContentDelete = () => {
  const { setSectionLoading, handleTopicContentUpdate } = useEpisodeDataStore();

  return useMutation<ContentUpdateResponse, AxiosError<{ error: unknown }>, ContentDeleteVariables>(
    contentObj => deleteContent(contentObj),
    {
      retry: 0,
      onMutate: async () => {
        setSectionLoading("content");
      },
      onSuccess: data => {
        handleTopicContentUpdate(data);
      },
      onSettled: () => {
        setSectionLoading("content", false);
      }
    }
  );
};
