import { AxiosError } from "axios";
import { useQuery, useMutation } from "react-query";
import httpService from "../httpService";
import {
  ApiResponse,
  EpisodeHostFetchResponse,
  EpisodeHostUpdateResponse,
  EpisodeHostUpdateVariables
} from "../Types";
import { useToaster } from "../../Hooks";

const fetchEpisodeHosts = async (episodeId: string) => {
  const { data } = await httpService.get<ApiResponse<EpisodeHostFetchResponse>>(
    `episode-segments/hosts/${episodeId}`
  );

  return data.result;
};

export const useFetchEpisodeHosts = (episodeId: string) => {
  return useQuery<EpisodeHostFetchResponse, AxiosError<{ error: unknown }>>(
    ["episodeInfoFetch", episodeId],
    () => fetchEpisodeHosts(episodeId),
    {
      retry: 0,
      enabled: !!episodeId
    }
  );
};

const updateEpisodeHosts = async (hostObj: EpisodeHostUpdateVariables) => {
  const { data } = await httpService.post<ApiResponse<EpisodeHostUpdateResponse>>(
    `episode-segments/hosts/${hostObj.episodeId}`,
    { episodeHosts: hostObj.episodeHosts }
  );

  return data.result;
};

export const useUpdateEpisodeHosts = () => {
  const { toaster } = useToaster();
  return useMutation<
    EpisodeHostUpdateResponse,
    AxiosError<{ error: unknown }>,
    EpisodeHostUpdateVariables
  >(["episodeHostsUpdate"], hostObj => updateEpisodeHosts(hostObj), {
    retry: 0,
    onSuccess: () => toaster("Hosts updated successfully", "success")
  });
};
