import React from "react";
import { Card } from "../../../theme/UI";
import { Box, Button, Flex, Grid, Image, Text } from "theme-ui";

import CONFIG from "../../../config.json";
import { useCommonDataStore } from "../../../DataStores";

export const Overlays: React.FC = () => {
  const { templates } = useCommonDataStore();

  return (
    <>
      <Grid columns={[1, 2, 3, 4]} mb={4}>
        {templates.map(item => (
          <Card key={item._id} sx={{ marginBottom: "0" }}>
            <Box
              sx={{
                borderBottom: ".0625rem dashed",
                borderColor: "accent1",
                paddingBottom: ".5rem"
              }}
            >
              <Image src={item?.thumbnail || `${CONFIG.defaultImages.template}${item.name}`} />
            </Box>

            <Card.Body>
              <Flex sx={{ flexDirection: "column" }}>
                <Text sx={{ fontWeight: "500" }}>{item.name}</Text>
                <Text>Max Hosts: {item.maxHosts}</Text>

                {!!item?.images?.logo?.maxAmount ? (
                  <Text>
                    Logo: {item?.images?.logo?.width || 0}w x {item?.images?.logo?.height || 0}h
                  </Text>
                ) : (
                  <Text>Logo: n/a</Text>
                )}

                {!!item?.images?.sponsors?.maxAmount ? (
                  <Text>
                    Sponsors: {item?.images?.sponsors?.width || 0}w x{" "}
                    {item?.images?.sponsors?.height || 0}h
                  </Text>
                ) : (
                  <Text>Sponsors: n/a</Text>
                )}
              </Flex>
            </Card.Body>

            <Card.Body>
              <Flex sx={{ justifyContent: "space-between" }}>
                <Button sx={{ width: "100%" }}>Demo</Button>
              </Flex>
            </Card.Body>
          </Card>
        ))}
      </Grid>
    </>
  );
};
