import React from "react";
import { Box, Grid, Select } from "theme-ui";

import _map from "lodash/map";
import _includes from "lodash/includes";
import _filter from "lodash/filter";
import _find from "lodash/find";
import _range from "lodash/range";
import _cloneDeep from "lodash/cloneDeep";

import { Card } from "../../../../../theme/UI";
import { IEpisodeHost, IHosts } from "../../../../../Types";

import { useFetchEpisodeHosts, useUpdateEpisodeHosts } from "../../../../../Api";
import { useEpisodeId } from "../../../../../Hooks";

export const ProfileHost: React.FC = () => {
  const episodeId = useEpisodeId();
  const [availableHosts, setAvailableHosts] = React.useState<IHosts[]>([]);
  const [episodeHosts, setEpisodeHosts] = React.useState<IEpisodeHost[]>([]);
  const [maxHosts, setMaxHosts] = React.useState<number>(0);
  const hostCountArray = _range(1, maxHosts + 1);

  const {
    data: episodeHostsData,
    isLoading: episodeHostsLoading,
    error: episodeHostsError
  } = useFetchEpisodeHosts(episodeId as string);

  const {
    data: episodeUpdateHostsData,
    isLoading: episodeUpdateHostsLoading,
    error: episodeUpdateHostsError,
    mutate: updateEpisodeHostsMutate
  } = useUpdateEpisodeHosts();

  React.useEffect(() => {
    if (!episodeHostsData || episodeHostsLoading || episodeHostsError) return;

    setAvailableHosts(episodeHostsData.availableHosts);
    setEpisodeHosts(episodeHostsData.episodeHosts);
    setMaxHosts(episodeHostsData.maxHosts);
  }, [episodeHostsData, episodeHostsError, episodeHostsLoading]);

  React.useEffect(() => {
    if (!episodeUpdateHostsData || episodeUpdateHostsLoading || episodeUpdateHostsError) return;

    setEpisodeHosts(episodeUpdateHostsData.episodeHosts);
  }, [episodeUpdateHostsData, episodeUpdateHostsError, episodeUpdateHostsLoading]);

  const handleDisable = (seatNumber: number): boolean => {
    if (seatNumber === 1 || episodeHosts.find(f => f.seatNum === seatNumber - 1)) return false;
    return true;
  };

  const filterHost = (hostId: string | undefined): IHosts[] => {
    const seatedHost = episodeHosts?.filter(f => f.hostId !== hostId);
    const exceptions: string[] = [];
    seatedHost.map(m => exceptions.push(m.hostId));
    return _filter(availableHosts, f => _includes(exceptions, f._id) === false);
  };

  const handleOnchange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    handleHostSelect(e.target.value, e.target.name);
  };

  const handleHostSelect = async (hostId: string, seatNum: string): Promise<void> => {
    let newEpisodeHosts = _cloneDeep(episodeHosts) || [];

    if (hostId !== "none") {
      newEpisodeHosts = newEpisodeHosts.filter(f => f.seatNum !== Number(seatNum));
      newEpisodeHosts.push({ seatNum: Number(seatNum), hostId });
    }

    if (hostId === "none") {
      newEpisodeHosts = _filter(newEpisodeHosts, f => f.seatNum < Number(seatNum));
    }

    updateEpisodeHostsMutate({
      episodeId: episodeId as string,
      episodeHosts: newEpisodeHosts
    });
  };

  if (episodeHostsLoading || !episodeHosts) {
    return null;
  }

  return (
    <>
      <Card variant="profileFullPage">
        <Card.Header>
          <Box>Hosts ({`${episodeHosts?.length || 0}/${episodeHostsData?.maxHosts}`})</Box>
        </Card.Header>

        <Card.Body>
          <Grid variant="table">
            {_map(hostCountArray, (seatNumber: number, index: number) => {
              const value = _find(episodeHosts, f => f.seatNum === seatNumber)?.hostId || "none";

              return (
                <Grid key={index} variant="defaultGridList">
                  <Box variant="grids.listDefaultIcon">{index + 1}</Box>
                  <Select
                    disabled={handleDisable(seatNumber)}
                    name={String(seatNumber)}
                    onChange={e => handleOnchange(e)}
                    value={value || "none"}
                  >
                    <option value="none">None</option>

                    {_map(
                      filterHost(_find(episodeHosts, f => f.seatNum === seatNumber)?.hostId) || [],
                      mHost => (
                        <option key={mHost._id} value={mHost._id}>
                          {mHost.name}
                        </option>
                      )
                    )}
                  </Select>
                </Grid>
              );
            })}
          </Grid>
        </Card.Body>
      </Card>
    </>
  );
};
