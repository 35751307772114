import React from "react";
import { useLocation, useNavigate, Outlet, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Icon from "@mdi/react";
import { Box, Flex, Grid } from "theme-ui";
import { IHeader, profileHeaders } from "./ProfileHeaders";
import { Card, Drawer } from "../../../../theme/UI";

import { useBreakpointIndex } from "@theme-ui/match-media";
import { ActiveTopic } from "./Topics/ActiveTopic";
import { useEpisodeDataStore } from "../../../../DataStores";

export const EpisodeProfile: React.FC = () => {
	const location = useLocation();
	const breakPointIndex = useBreakpointIndex();

	const { isTopicDrawerOpen, toggleTopicDrawer } = useEpisodeDataStore();

	const section = location.pathname.split("/")[6];

	const { id } = useParams();
	const navigate = useNavigate();

	const parseNavigate = (link: string) => {
		navigate(link.replace(":id", id as string));
	};

	return (
		<>
			{breakPointIndex <= 1 && (
				<>
					<Drawer
						isDrawerOpen={isTopicDrawerOpen}
						handleCloseDrawer={() => toggleTopicDrawer(false)}
					>
						<Card>
							<Card.Body sx={{ paddingBottom: "100px" }}>
								<ActiveTopic />
							</Card.Body>
						</Card>
					</Drawer>
				</>
			)}

			<Grid columns={`repeat(${profileHeaders.length}, 1fr)`} variant="tabs.menu">
				{profileHeaders.map((header: IHeader) => (
					<Box
						data-tooltip-id={`my-tooltip-${header.name}}`}
						onClick={() => parseNavigate(header.url as string)}
						key={header.name}
						variant={
							header.link.toLowerCase() === section ? "tabs.menuItem.active" : "tabs.menuItem"
						}
					>
						<Box sx={{ display: ["block", "none"] }}>
							<Tooltip
								id={`my-tooltip-${header.name}}`}
								place="top"
								variant="info"
								content={header.name}
							/>
						</Box>

						<Flex sx={{ gap: ".5rem", "&:hover": { filter: "brightness(1.5)" } }}>
							<Box
								sx={{
									color: header.link.toLowerCase() === section ? "text1" : "accent9"
								}}
							>
								<Icon path={header.icon} size={1} />
							</Box>

							<Box sx={{ display: ["none", "inline-block"] }}>{header.name}</Box>
						</Flex>
					</Box>
				))}
			</Grid>

			<Box sx={{ mt: "1rem" }}>
				<Outlet />
			</Box>
		</>
	);
};
