import React, { FC, useCallback, useEffect, useState } from "react";
import { useBreakpointIndex } from "@theme-ui/match-media";
import { Box, Button, Flex, Grid } from "theme-ui";
import { Card, Collapse } from "../../../../../theme/UI";
import { ImageListing } from "./ImageList";
import { ImageUploader } from "./ImageUploader";

import { useFetchEpisodeImages } from "../../../../../Api";
import { ITemplateImages, SponsorImages } from "../../../../../Types";
import { ImageType } from "./ImageTypes";
import { useEpisodeId } from "../../../../../Hooks";

export const ProfileImages: FC = () => {
  const episodeId = useEpisodeId();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [logoImage, setLogoImage] = useState<string>("");
  const [sponsorImages, setSponsorImages] = useState<SponsorImages[]>([]);

  const [templateImages, setTemplateImages] = useState<ITemplateImages | null>();
  const breakPointIndex = useBreakpointIndex();
  const columns = ["1fr", "1fr", "5fr 2fr"];

  const { data, isLoading } = useFetchEpisodeImages(episodeId || "");

  useEffect(() => {
    let isMounted = true;
    if (!data || isLoading || !isMounted) return;

    setLogoImage(data.logo);
    setSponsorImages(data.sponsorImages);
    setTemplateImages(data.templateImages);

    return () => {
      isMounted = false;
    };
  }, [data, isLoading]);

  const setImageCallback = useCallback(
    (type: ImageType, images: SponsorImages[] | string): void => {
      if (type === ImageType.Logo) {
        setLogoImage(images as string);
      }

      if (type === ImageType.Sponsors) {
        setSponsorImages(images as SponsorImages[]);
      }
    },
    [setLogoImage, setSponsorImages]
  );

  if (!episodeId || !templateImages) return null;

  return (
    <>
      {breakPointIndex <= 1 && (
        <Collapse isOpen={isOpen}>
          <Card>
            <Card.Header>
              <Box>Upload</Box>
              <Button onClick={() => setIsOpen(false)}>Close</Button>
            </Card.Header>
            <Card.Body>
              <ImageUploader templateImages={templateImages} setImageCallback={setImageCallback} />
            </Card.Body>
          </Card>
        </Collapse>
      )}

      <Grid columns={columns}>
        <Flex sx={{ flexDirection: "column", gap: ".5rem" }}>
          <Card>
            <Card.Header>
              <Box>Images Logo {`${templateImages.logo.width}x${templateImages.logo.height}`}</Box>
              {!isOpen && breakPointIndex <= 1 && (
                <Button onClick={() => setIsOpen(true)}>Upload</Button>
              )}
            </Card.Header>
            <Card.Body>
              <ImageListing
                images={logoImage}
                imageType={ImageType.Logo}
                setImageCallback={setImageCallback}
              />
            </Card.Body>
          </Card>

          <Card>
            <Card.Header>
              Sponsor Images {`${templateImages.sponsors.width}x${templateImages.sponsors.height}`}{" "}
              ({sponsorImages.length})
            </Card.Header>
            <Card.Body>
              <ImageListing
                images={sponsorImages}
                imageType={ImageType.Sponsors}
                setImageCallback={setImageCallback}
              />
            </Card.Body>
          </Card>
        </Flex>

        {breakPointIndex > 1 && (
          <Card>
            <Card.Header>Upload</Card.Header>
            <Card.Body>
              <ImageUploader templateImages={templateImages} setImageCallback={setImageCallback} />
            </Card.Body>
          </Card>
        )}
      </Grid>
    </>
  );
};
