import React, { FC, useEffect, useState } from "react";
import { Box, Button, Input, Spinner } from "theme-ui";

import { FieldSet } from "../ActiveTopicForm/FieldSet";
import { TheLabel } from "../ActiveTopicForm/Label";
import { useContent, useEpisodeId } from "../../../../../../Hooks";
import { useEpisodeDataStore } from "../../../../../../DataStores";

export const ActiveTopicYoutube: FC = () => {
  const episodeId = useEpisodeId();
  const [videoUrl, setVideoUrl] = useState<string>("");
  const { isLoading, isYoutubeLink, addTopicContentYoutube } = useContent();
  const { activeTopic } = useEpisodeDataStore();

  useEffect(() => {
    if (!activeTopic) return;
    setVideoUrl("");
  }, [activeTopic]);

  const handleSaveContent = async () => {
    if (!episodeId || !activeTopic?._id) return;
    addTopicContentYoutube(episodeId, activeTopic._id, videoUrl);
  };

  const disabled = !videoUrl || isLoading || !isYoutubeLink(videoUrl);

  return (
    <Box mt={3} pt={3} sx={{ borderTop: "1px dashed", borderColor: "accent3" }}>
      <TheLabel sx={{ mb: 2 }}>Youtube Link:</TheLabel>
      <FieldSet>
        <Input
          aria-label="Enter youtube video link"
          autoComplete="off"
          onChange={e => setVideoUrl(e.target.value)}
          placeholder="Youtube Link..."
          value={videoUrl}
        />
      </FieldSet>

      <Button mb={3} onClick={handleSaveContent} aria-label="Add Youtube Video" disabled={disabled}>
        <Spinner
          sx={{
            display: isLoading ? "block" : "none",
            color: "accent2"
          }}
          variant="mediaUploader.spinner"
        />
        Convert Youtube Video
      </Button>
    </Box>
  );
};
