import { create, StoreApi } from "zustand";
import { CommonSocials, CommonTemplates } from "../Api/Types";

export interface CommonDataStore {
  socials: CommonSocials[];
  templates: CommonTemplates[];
  hydrateData: (socials: CommonSocials[], templates: CommonTemplates[]) => void;
}

export const useCommonDataStore = create<CommonDataStore>(
  (set: StoreApi<CommonDataStore>["setState"], get: StoreApi<CommonDataStore>["getState"]) => ({
    socials: [],
    templates: [],
    hydrateData: (socials, templates) => {
      set({ socials, templates });
    }
  })
);
