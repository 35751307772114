import jwtDecode from "jwt-decode";

type VerifiedToken =
  | {
      _id: string;
      gtkAi: boolean;
      name: string;
      picture: string;
    }
  | undefined;

type UseTokenHook = () => {
  verifiedToken: VerifiedToken;
};

const useTokenHook: UseTokenHook = () => {
  const token =
    process.env?.REACT_APP_JWT_TOKEN &&
    localStorage.getItem(process.env.REACT_APP_JWT_TOKEN);

  const verifiedToken: VerifiedToken = token ? jwtDecode(token) : undefined;

  return {
    verifiedToken
  };
};

export default useTokenHook;
