import React from "react";
import { Button, Grid, Input } from "theme-ui";
import { Card } from "../../../../theme/UI";
import httpService from "../../../../Api/httpService";

interface IHostNewProps {
  handleNewCallBack: (host: any, hosts: any) => void;
  handleNewHostToggle: () => void;
  showHeader?: boolean;
}

export const HostNew: React.FC<IHostNewProps> = ({
  handleNewCallBack,
  handleNewHostToggle,
  showHeader = true
}) => {
  const [hostName, setHostName] = React.useState<string>("");

  const handleFormSubmit = async () => {
    if (!hostName) {
      console.error("Error, Name can not be empty");
      return;
    }

    const { data } = await httpService.post(process.env.REACT_APP_REST_API + "podcast-hosts", {
      name: hostName,
      socials: []
    });

    handleNewCallBack(data.result.host, data.result.hosts);

    setHostName("");
  };

  const handleClose = () => {
    setHostName("");
    handleNewHostToggle();
  };

  return (
    <Card>
      {showHeader && (
        <>
          <Card.Header>
            <div>Add New Host</div>
          </Card.Header>
        </>
      )}

      <Card.Body>
        <Grid columns={["1fr", "1fr 80px 80px"]}>
          <Input
            type="text"
            placeholder="Host Name"
            value={hostName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHostName(e.target.value)}
          />

          <Button disabled={!hostName} onClick={handleFormSubmit}>
            <span>Submit</span>
          </Button>

          <Button onClick={handleClose} variant="secondary">
            <span>Close</span>
          </Button>
        </Grid>
      </Card.Body>
    </Card>
  );
};
