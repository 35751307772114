import React from "react";
import { useNavigate } from "react-router-dom";

import { mdiCloseCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";

import { Button, Checkbox, Flex, Image, Input, Label, Select, Spinner } from "theme-ui";
import { Card } from "../../../../../theme/UI";
import { AppRoutes } from "../../../../../Types";
import { CommonTemplates } from "../../../../../Api/Types";
import CONFIG from "../../../../../config.json";
import { useCreateEpisode } from "./../../../../../Api";
import { useCommonDataStore } from "../../../../../DataStores";

export type Options = {
  hosts: boolean;
  logo: boolean;
  news: boolean;
  podcastName: boolean;
  socialNetworks: boolean;
  sponsors: boolean;
  topics: boolean;
};

interface EpisodeNewProps {
  handleCloseDrawer?: () => void;
  showHeader?: boolean;
}

export const EpisodeNew: React.FC<EpisodeNewProps> = ({ handleCloseDrawer, showHeader = true }) => {
  const navigate = useNavigate();
  const [episodeName, setEpisodeName] = React.useState<string>("");
  const [templateId, setTemplateId] = React.useState<string>("");
  const { templates } = useCommonDataStore();

  const [currentState, setCurrentState] = React.useState<Options>({
    hosts: true,
    logo: true,
    news: true,
    podcastName: true,
    socialNetworks: true,
    sponsors: true,
    topics: true
  });

  const { data, error, isLoading, mutate } = useCreateEpisode();

  React.useEffect(() => {
    setTemplateId(templates[0]?._id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let stillHere = true;

    if (!isLoading && data && stillHere) {
      setEpisodeName("");
      setTemplateId(templates[0]?._id);
      navigate(`${AppRoutes.EpisodeProfileLink}${data.episodeId}/topics`);
    }

    return () => {
      stillHere = false;
    };
  }, [templates, data, error, isLoading, navigate]);

  const handleCheckbox = (checked: boolean, param: string): void => {
    setCurrentState({ ...currentState, [param]: checked });
  };

  const handleFormSubmit = async () => {
    if (!episodeName || !setTemplateId) {
      console.error("Error, Name and Template can not be empty");
      return;
    }

    mutate({ name: episodeName, templateId, currentState });
  };

  const parseTemplateImage = () => {
    const defaultImage = CONFIG.defaultImages.template;

    const template = templates?.find((template: CommonTemplates) => template._id === templateId);

    if (!template) {
      return defaultImage;
    }

    return template?.thumbnail || `${defaultImage}${template.name}`;
  };

  return (
    <Card>
      {showHeader && (
        <Card.Header>
          <div>Create New Episode</div>
          {handleCloseDrawer && (
            <Button aria-label="Close new episode button" onClick={handleCloseDrawer}>
              <Icon path={mdiCloseCircleOutline} size="16" />
              Close
            </Button>
          )}
        </Card.Header>
      )}

      <Card.Body>
        <Flex sx={{ flexDirection: "column", gap: "1rem" }}>
          <Image
            alt="New episode template image"
            src={parseTemplateImage()}
            sx={{
              borderBottom: "1px dashed",
              borderColor: "accent2",
              paddingBottom: "1rem"
            }}
          />

          <Input
            sx={{ mt: ".375rem" }}
            type="text"
            placeholder="Episode Name"
            value={episodeName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEpisodeName(e.target.value)}
          />

          <Select
            aria-label="Choose a overlay template for the new episode"
            id="templateSelect"
            onChange={(e: any) => setTemplateId(e.target.value)}
            value={templateId}
          >
            {templates?.map((template: CommonTemplates) => (
              <option key={template._id} value={template._id}>
                {template.name}
              </option>
            ))}
          </Select>

          <Label data-testid="aaaaa">
            <Checkbox
              data-testid="xxxxx"
              aria-checked={currentState.podcastName}
              aria-label="Use podcast name for new episode"
              checked={currentState.podcastName}
              onChange={e => handleCheckbox(e.target.checked, "podcastName")}
              role="checkbox"
              tabIndex={0}
            />
            Use Podcast Name
          </Label>

          <Label>
            <Checkbox
              aria-checked={currentState.logo}
              aria-label="Use logo for new episode"
              checked={currentState.logo}
              onChange={e => handleCheckbox(e.target.checked, "logo")}
              role="checkbox"
              tabIndex={0}
            />
            Use Current Logo
          </Label>

          <Label>
            <Checkbox
              aria-checked={currentState.hosts}
              aria-label="Use hosts for new episode"
              checked={currentState.hosts}
              onChange={e => handleCheckbox(e.target.checked, "hosts")}
              tabIndex={0}
            />
            Use Current Hosts
          </Label>

          <Label>
            <Checkbox
              aria-checked={currentState.socialNetworks}
              aria-label="Use social networks for new episode"
              checked={currentState.socialNetworks}
              onChange={e => handleCheckbox(e.target.checked, "socialNetworks")}
            />
            Use Current Socials
          </Label>

          <Label>
            <Checkbox
              aria-checked={currentState.sponsors}
              aria-label="Use sponsors for new episode"
              checked={currentState.sponsors}
              onChange={e => handleCheckbox(e.target.checked, "sponsors")}
            />
            Use Current Sponsors
          </Label>

          <Label>
            <Checkbox
              aria-checked={currentState.news}
              aria-label="Use news for new episode"
              checked={currentState.news}
              onChange={e => handleCheckbox(e.target.checked, "news")}
            />
            Use Current News
          </Label>

          <Label>
            <Checkbox
              aria-checked={currentState.topics}
              aria-label="Use topics for new episode"
              checked={currentState.topics}
              onChange={e => handleCheckbox(e.target.checked, "topics")}
            />
            Use Current Topics
          </Label>

          <Button
            aria-label="Submit new episode form"
            disabled={isLoading}
            onClick={handleFormSubmit}
            variant="loading"
          >
            submit
            {isLoading ? <Spinner sx={{ color: "white", height: "1rem" }} /> : null}
          </Button>
        </Flex>
      </Card.Body>
    </Card>
  );
};
