import React from "react";
import Icon from "@mdi/react";
import { mdiMenu } from "@mdi/js";
import { useBreakpointIndex } from "@theme-ui/match-media";
import * as styles from "./Header.styles";

import { HeaderUser } from "./HeaderUser/HeaderUser";

import { Box, Grid, Input } from "theme-ui";

interface IHeaderProps {
  isSideBarOpen: boolean;
  isUserMenuOpen: boolean;
  setIsSideBarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsUserMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userMenuButtonRef: React.RefObject<HTMLDivElement>;
}

export const Header: React.FC<IHeaderProps> = ({
  isSideBarOpen,
  isUserMenuOpen,
  setIsSideBarOpen,
  setIsUserMenuOpen,
  userMenuButtonRef
}) => {
  const breakPointIndex = useBreakpointIndex();
  const toggleRef = React.useRef<HTMLDivElement>(null);

  const handleToggleSideBar = () => {
    setIsSideBarOpen((prevState: boolean) => !prevState);
  };

  React.useEffect(() => {
    const toggleRefCurrent = toggleRef.current;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" || event.key === " ") {
        handleToggleSideBar();
      }
    };

    if (toggleRefCurrent) {
      toggleRefCurrent.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (toggleRefCurrent) {
        toggleRefCurrent.removeEventListener("keydown", handleKeyDown);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid sx={styles.header}>
        <Box
          aria-label="Menu Button"
          aria-expanded={isSideBarOpen ? true : undefined}
          onClick={handleToggleSideBar}
          ref={toggleRef}
          role="button"
          tabIndex={0}
          sx={styles.menuButton}
        >
          <Icon path={mdiMenu} />
        </Box>

        <Box aria-label="GTK Logo" sx={styles.logo}>
          GTK
        </Box>

        {breakPointIndex >= 2 && (
          <Input type="text" placeholder="Search products" sx={{ width: "25rem" }} />
        )}

        <HeaderUser
          isUserMenuOpen={isUserMenuOpen}
          setIsUserMenuOpen={setIsUserMenuOpen}
          userMenuButtonRef={userMenuButtonRef}
        />
      </Grid>
    </>
  );
};
