import React from "react";

import { Button, Flex, Input, Textarea } from "theme-ui";
import { Card } from "../../../../theme/UI";
import { CannedMessage } from "../../../../Api/Types";
import httpService from "../../../../Api/httpService";
import { ConfirmDelete } from "../../../Shared/Delete/Delete";
import { useEditMessage } from "../../../../Api";

export type Options = {
  hosts: boolean;
  logo: boolean;
  news: boolean;
  socialNetworks: boolean;
  sponsors: boolean;
};

interface EpisodeNewProps {
  activeMessage: CannedMessage | undefined;
  handleCloseDrawer?: () => void;
  showHeader?: boolean;
  handleEditCallBack: (message: CannedMessage) => void;
  handleDeleteCallBack: (id: string) => void;
}

export const CannedMessageEdit: React.FC<EpisodeNewProps> = ({
  activeMessage,
  handleCloseDrawer,
  showHeader = true,
  handleEditCallBack,
  handleDeleteCallBack
}) => {
  const [isDeleteColapsed, setIsDeleteColapsed] = React.useState<boolean>(true);

  const [theMessage, setTheMessage] = React.useState<CannedMessage | undefined>(undefined);
  const { data, error, isLoading, mutate } = useEditMessage();

  React.useEffect(() => {
    setTheMessage(activeMessage || undefined);
    setIsDeleteColapsed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeMessage]);

  React.useEffect(() => {
    let stillHere = true;

    if (!isLoading && data && stillHere && theMessage) {
      handleEditCallBack({
        _id: theMessage._id,
        name: theMessage.name,
        message: theMessage.message
      });
    }

    return () => {
      stillHere = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, isLoading]);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!theMessage) return;
    const { name, value } = e.target;
    setTheMessage({ ...theMessage, [name]: value });
  };

  const handleFormSubmit = async () => {
    if (!theMessage) {
      console.error("Error, Name and Template can not be empty");
      return;
    }

    mutate(theMessage);
    handleCloseDrawer && handleCloseDrawer();
  };

  const deleteMessage = async () => {
    if (!theMessage?._id) return;
    const _id = theMessage._id;
    const { data } = await httpService.delete(
      `${process.env.REACT_APP_REST_API}podcast-canned-messages/${_id}`
    );

    if (data?.resultStatus.success) {
      setIsDeleteColapsed(true);
      handleDeleteCallBack(_id);
    }
  };

  return (
    <Card>
      {showHeader && (
        <>
          <Card.Header>
            <div>Canned Message Edit</div>
          </Card.Header>
        </>
      )}

      <Card.Body>
        <Flex sx={{ flexDirection: "column", gap: "1rem" }}>
          <Input
            disabled={!theMessage}
            name="name"
            onChange={handleFormChange}
            placeholder="name"
            sx={{ mt: ".375rem" }}
            type="text"
            value={theMessage?.name || ""}
          />

          <Textarea
            disabled={!theMessage}
            name="message"
            onChange={handleFormChange}
            placeholder="message"
            sx={{ mt: ".375rem" }}
            value={theMessage?.message || ""}
            rows={5}
          />

          <Button disabled={isLoading || !theMessage} onClick={handleFormSubmit} variant="loading">
            Submit Changes
          </Button>
        </Flex>
      </Card.Body>

      <ConfirmDelete
        deleteMethod={deleteMessage}
        isColapsed={isDeleteColapsed}
        isVisible={!!theMessage?._id}
        toggleColapsed={() => setIsDeleteColapsed(!isDeleteColapsed)}
      />
    </Card>
  );
};
