import * as React from "react";

import { Box, Button, Input, Select } from "theme-ui";
import { Card } from "../../../../../theme/UI";
import { CommonTemplates } from "../../../../../Api/Types";
import { useCommonDataStore } from "../../../../../DataStores";

interface IEpisodeSearchProps {
  handleFormSubmit: () => void;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  setTemplateId: (templateId: string) => void;
  templateId: string;
}

export const EpisodeSearch: React.FC<IEpisodeSearchProps> = ({
  handleFormSubmit,
  searchTerm,
  setSearchTerm,
  setTemplateId,
  templateId
}) => {
  const { templates } = useCommonDataStore();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    handleFormSubmit();
  };

  return (
    <form role="search" onSubmit={handleSubmit}>
      <Card.HeaderForm>
        <Box>
          <Input
            aria-label="Search the episodes"
            name="episodeSearch"
            onChange={e => setSearchTerm(e.target.value)}
            placeholder="Search..."
            type="search"
            value={searchTerm}
          />
          <Select
            aria-label="Choose a overlay template for the episode search"
            onChange={e => setTemplateId(e.target.value)}
            value={templateId}
          >
            <option value="">All Templates</option>
            {templates?.map((template: CommonTemplates) => (
              <option key={template._id} value={template._id}>
                {template.name}
              </option>
            ))}
          </Select>

          <Button type="submit">submit</Button>
        </Box>
      </Card.HeaderForm>
    </form>
  );
};
