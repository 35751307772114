import * as React from "react";
import { useBreakpointIndex } from "@theme-ui/match-media";
import { Grid } from "theme-ui";
import { Collapse, Drawer } from "../../../theme/UI";
import { CannedMessageList, CannedMessageListState } from "./CannedMessageList/CannedMessageList";
import { CannedMessage } from "../../../Api/Types";
import { CannedMessageNew } from "./CannedMessageNew/CannedMessageNew";
import { CannedMessageEdit } from "./CannedMessageEdit/CannedMessageEdit";

export const CannedMessages: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);
  const [isNewMessageOpen, setIsNewMessageOpen] = React.useState<boolean>(false);
  const [activeMessage, setActiveMessage] = React.useState<CannedMessage>();
  const [listState, setListState] = React.useState<CannedMessageListState | null>(null);
  const breakPointIndex = useBreakpointIndex();
  const columns = ["1fr", "1fr", "2fr 1fr"];

  const handleNewCallBack = (message: CannedMessage) => {
    if (!listState || !message) return;
    const newState = structuredClone(listState);
    newState.messages.unshift(message);
    setListState(newState);
  };

  const handleEditCallBack = (message: CannedMessage) => {
    if (!listState || !message) return;
    const newState = structuredClone(listState);

    const index = newState?.messages.findIndex(
      (theMessage: CannedMessage) => theMessage._id === message._id
    );

    if (index === -1) return;
    newState.messages[index] = message;
    setListState(newState);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleNewMessageToggle = () => {
    setIsNewMessageOpen(!isNewMessageOpen);
  };

  const handleDeleteCallBack = (id: string) => {
    if (!listState) return;
    const newState = structuredClone(listState);
    newState.messages = newState.messages.filter((message: CannedMessage) => message._id !== id);
    setListState(newState);
    setActiveMessage(undefined);
  };

  return (
    <>
      <Collapse isOpen={isNewMessageOpen} sx={{ gridColumn: ["span 1", "span 2"] }}>
        <CannedMessageNew
          handleNewCallBack={handleNewCallBack}
          handleNewMessageClose={handleNewMessageToggle}
        />
      </Collapse>

      <Grid columns={columns}>
        <CannedMessageList
          handleNewMessageOpen={handleNewMessageToggle}
          handleOpenDrawer={handleOpenDrawer}
          setActiveMessage={setActiveMessage}
          listState={listState}
          setListState={setListState}
        />
        {breakPointIndex > 1 && (
          <CannedMessageEdit
            activeMessage={activeMessage}
            handleEditCallBack={handleEditCallBack}
            handleDeleteCallBack={handleDeleteCallBack}
          />
        )}
      </Grid>

      {breakPointIndex <= 1 && (
        <Drawer
          isDrawerOpen={isDrawerOpen}
          handleCloseDrawer={handleCloseDrawer}
          title="Canned Messages Edit"
        >
          <CannedMessageEdit
            activeMessage={activeMessage}
            handleCloseDrawer={handleCloseDrawer}
            handleEditCallBack={handleEditCallBack}
            showHeader={false}
            handleDeleteCallBack={handleDeleteCallBack}
          />
        </Drawer>
      )}
    </>
  );
};
