import React from "react";
import { useBreakpointIndex } from "@theme-ui/match-media";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../../../../Types";

import { Box, Button, Checkbox, Flex, Grid, Image, Input, Label, Select } from "theme-ui";
import { Card } from "../../../../../theme/UI";
import { ConfirmDelete } from "../../../../Shared/Delete/Delete";
import { useCopy, useEpisodeId } from "../../../../../Hooks";
import { useDeleteEpisode, useFetchEpisodeInfo, useUpdateEpisodeInfo } from "../../../../../Api";
import { useCommonDataStore } from "../../../../../DataStores";

interface IEpisodeInfo {
  name: string;
  number: number;
  airDate: string;
  current: boolean;
  podcastName: string;
  templateId: string;
}

const defaultState: IEpisodeInfo = {
  name: "",
  number: 1,
  airDate: "",
  current: false,
  podcastName: "",
  templateId: ""
};

export const ProfileInformation: React.FC = () => {
  const episodeId = useEpisodeId();
  const { templates } = useCommonDataStore();
  const navigate = useNavigate();
  const breakPointIndex = useBreakpointIndex();
  const [episodeInfo, setEpisodeInfo] = React.useState<IEpisodeInfo>(defaultState);
  const [isCollapsed, setIsCollapsed] = React.useState<boolean>(true);
  const { copyText } = useCopy();

  const { data: episodeInfoData, isLoading: episodeInfoLoading } = useFetchEpisodeInfo(episodeId);

  const { isLoading: episodeInfoUpdateLoading, mutate: episodeInfoUpdateMutate } =
    useUpdateEpisodeInfo();

  const {
    data: episodeInfoDeleteData,
    isLoading: episodeInfoDeleteLoading,
    mutate: episodeInfoDeleteMutate
  } = useDeleteEpisode();

  React.useEffect(() => {
    if (episodeInfoData && !episodeInfoLoading) {
      setEpisodeInfo(episodeInfoData);
    }
  }, [episodeInfoData, episodeInfoLoading]);

  React.useEffect(() => {
    if (episodeInfoDeleteData && !episodeInfoDeleteLoading) {
      navigate(AppRoutes.EpisodeList);
    }
  }, [episodeInfoDeleteData, episodeInfoDeleteLoading, navigate]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;

    if (name === "current") {
      setEpisodeInfo(prevState => ({
        ...prevState,
        [name]: checked
      }));
    } else {
      setEpisodeInfo(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target;

    setEpisodeInfo(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleInfoTopic = async () => {
    try {
      const episodeUpdateInfo: any = episodeInfo;
      delete episodeUpdateInfo.templateName;

      episodeInfoUpdateMutate({
        episodeId: episodeId as string,
        episode: episodeUpdateInfo as Omit<IEpisodeInfo, "templateName">
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteEpisode = async () => {
    episodeId && episodeInfoDeleteMutate(episodeId as string);
  };

  const parseTemplateImage = () => {
    const template = templates?.find(template => template._id === episodeInfo.templateId);

    return template?.thumbnail;
  };

  const handleShowRunnerCopy = () => {
    const url = window.location.href;
    const domain = url.split("/")[2];
    const showRunnerLink = `${domain}${AppRoutes.ShowRunnerLink}${episodeId}`;
    copyText(showRunnerLink);
  };

  const handleShowRunner = () => {
    window.open(`${AppRoutes.ShowRunnerLink}${episodeId}`, "_blank");
  };

  const handleShowYoutube = () => {
    window.open(`${AppRoutes.YoutubeDescLink}${episodeId}`, "_blank");
  };

  const isMobile = breakPointIndex <= 1;
  const title = isMobile ? "Information" : "Episode Information";
  const showRunnerButtonTitle = isMobile ? "SR" : "Go to Show Runner";
  const showRunnerCopyButtonTitle = isMobile ? "SRL " : "Copy SR Link";
  const youtubeButtonTitle = isMobile ? "YT" : "Youtube Description";

  return (
    <>
      <Card variant="profileFullPage">
        <Card.Header>
          <Box>{title}</Box>
          <Flex sx={{ gap: 2 }}>
            <Button onClick={handleShowRunnerCopy}>{showRunnerCopyButtonTitle}</Button>
            <Button onClick={handleShowRunner}>{showRunnerButtonTitle}</Button>
            <Button onClick={handleShowYoutube}>{youtubeButtonTitle}</Button>
          </Flex>
        </Card.Header>

        <Grid columns={["1fr", "2fr 3fr"]}>
          <Card.Body>
            <Image
              src={parseTemplateImage()}
              sx={{
                borderBottom: "1px dashed",
                borderColor: "accent2",
                paddingBottom: "1rem",
                width: "100%"
              }}
            />
          </Card.Body>
          <Card.Body>
            <Box mt={3}>
              <Label htmlFor="name">Podcast Name</Label>
              <Input
                autoComplete="off"
                name="podcastName"
                onChange={handleChange}
                placeholder="Name of Podcast..."
                value={episodeInfo?.podcastName || ""}
              />
            </Box>

            <Box mt={3}>
              <Label htmlFor="templateName">Template</Label>
              <Select
                name="templateId"
                value={episodeInfo?.templateId || ""}
                onChange={handleSelectChange}
              >
                {templates.map(template => (
                  <option key={template._id} value={template._id}>
                    {template.name}
                  </option>
                ))}
              </Select>
            </Box>

            <Box mt={3}>
              <Label htmlFor="name">Episode Name</Label>
              <Input
                autoComplete="off"
                name="name"
                onChange={handleChange}
                placeholder="Episode Name..."
                value={episodeInfo?.name || ""}
              />
            </Box>

            <Box mt={3}>
              <Label htmlFor="number">Episode Number</Label>
              <Input
                name="number"
                onChange={handleChange}
                placeholder="Episode Number..."
                type="number"
                value={episodeInfo?.number || ""}
              />
            </Box>

            <Box mt={3}>
              <Label htmlFor="airDate">Date</Label>
              <Input
                name="airDate"
                onChange={handleChange}
                type="date"
                value={episodeInfo?.airDate || ""}
              />
            </Box>

            <Box mt={3}>
              <Label sx={{ margin: 0, padding: 0 }}>
                <Checkbox checked={!!episodeInfo.current} name="current" onChange={handleChange} />{" "}
                Current Episode
              </Label>
            </Box>

            <Flex mt={3} sx={{ gap: ".5rem" }}>
              <Button disabled={episodeInfoUpdateLoading} onClick={handleInfoTopic}>
                Submit
              </Button>
            </Flex>
          </Card.Body>

          <Box />

          <Box sx={{ width: ["100%", "60%"] }}>
            <ConfirmDelete
              deleteMethod={handleDeleteEpisode}
              isColapsed={isCollapsed}
              toggleColapsed={() => setIsCollapsed(!isCollapsed)}
            />
          </Box>
        </Grid>
      </Card>
    </>
  );
};
