import { AxiosError } from "axios";
import { useMutation } from "react-query";
import httpService from "../httpService";
import { ApiResponse } from "../Types";
import { useEpisodeDataStore } from "../../DataStores";
import { useToaster } from "../../Hooks";
import { IEpisodeTopic } from "../../Types";

type EpisodeTopicCopyVariables = { episodeId: string; topicId: string };
type EpisodeTopicCopyResponse = { activeIndex: number; topics: IEpisodeTopic[] };

const copyEpisodeTopic = async (
  copyTopicObj: EpisodeTopicCopyVariables
): Promise<EpisodeTopicCopyResponse> => {
  const { data } = await httpService.post<ApiResponse<EpisodeTopicCopyResponse>>(
    `/episode-topics/copy/${copyTopicObj.episodeId}`,
    { topicId: copyTopicObj.topicId }
  );

  return data.result;
};

export const useEpisodeTopicCopy = () => {
  const { toaster } = useToaster();
  const { setSectionLoading, setTopics } = useEpisodeDataStore();
  return useMutation<
    EpisodeTopicCopyResponse,
    AxiosError<{ error: unknown }>,
    EpisodeTopicCopyVariables
  >(["copy-episode-topic"], copyTopicObj => copyEpisodeTopic(copyTopicObj), {
    retry: 0,
    onMutate: () => {
      setSectionLoading("topic", true);
    },
    onSuccess: data => {
      if (data) {
        toaster("Topic Copied");
        setTopics(data.topics, data.activeIndex);
      }
    },
    onSettled: () => {
      setSectionLoading("topic", false);
    }
  });
};
