import React from "react";
import { Outlet } from "react-router-dom";

import { Header } from "../Template/Header/Header";
import { SideBar } from "../Template/SideBar/SideBar";
import { HeaderUserMenu } from "../Template/Header/HeaderUserMenu/HeaderUserMenu";
import { Flex } from "theme-ui";

import * as styles from "./ApplicationWrapper.styles";

export const ApplicationWrapper: React.FC = () => {
  const [isSideBarOpen, setIsSideBarOpen] = React.useState<boolean>(true);
  const [isUserMenuOpen, setIsUserMenuOpen] = React.useState<boolean>(false);
  const userMenuButtonRef = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <Header
        isSideBarOpen={isSideBarOpen}
        isUserMenuOpen={isUserMenuOpen}
        setIsSideBarOpen={setIsSideBarOpen}
        setIsUserMenuOpen={setIsUserMenuOpen}
        userMenuButtonRef={userMenuButtonRef}
      />

      <HeaderUserMenu
        isUserMenuOpen={isUserMenuOpen}
        userMenuButtonRef={userMenuButtonRef}
        setIsUserMenuOpen={setIsUserMenuOpen}
      />
      <SideBar isSideBarOpen={isSideBarOpen} />

      <Flex data-testid="xxxxxx" sx={styles.primaryContent(isSideBarOpen)}>
        <Outlet />
      </Flex>
    </>
  );
};
