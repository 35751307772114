import React from "react";
import { Box, Textarea } from "theme-ui";
import { Divider } from "../../theme/UI";
const STORAGE_KEY = "@gtk/hostNotes_";

interface ShowRunnerTopicNotesProps {
  hostNotes: string;
  id: string;
}

export const ShowRunnerTopicNotes: React.FC<ShowRunnerTopicNotesProps> = ({ hostNotes, id }) => {
  const [topicNote, setTopicNote] = React.useState<string>(hostNotes);

  const handleTopicNoteChange = (e: any, topicId: string) => {
    const { value } = e.target;

    const storage = localStorage.getItem(STORAGE_KEY + id);
    const localHostNotes = storage ? JSON.parse(storage) : {};

    localHostNotes[topicId] = value;
    localStorage.setItem(STORAGE_KEY + id, JSON.stringify(localHostNotes));
    setTopicNote(value);
  };

  React.useEffect(() => {
    const storage = localStorage.getItem(STORAGE_KEY + id);
    const localHostNotes = storage ? JSON.parse(storage) : {};

    setTopicNote(localHostNotes[id] || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box mt={3}>
      <Divider sx={{ color: "#333" }} />
      <Textarea
        sx={{ resize: "vertical" }}
        rows={5}
        placeholder="Your notes..."
        value={topicNote}
        onChange={e => handleTopicNoteChange(e, id)}
      />
    </Box>
  );
};
