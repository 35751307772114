import React from "react";
import { ActiveTopicForm } from "./ActiveTopicForm/ActiveTopicForm";

import { Box, Grid } from "theme-ui";
import { ActiveTopicImageUploader } from "./ActiveTopicMedia/ActiveTopicImage";

import { ActiveTopicContentUploader } from "./ActiveTopicMedia/ActiveTopicContent";
import { ActiveTopicYoutube } from "./ActiveTopicMedia/ActiveTopicYoutube";
import { useEpisodeDataStore } from "../../../../../DataStores";

export const ActiveTopic: React.FC = () => {
	const { activeTopic, templateInfo, updateActiveTopic } = useEpisodeDataStore();

	const handleChange = (
		event:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLSelectElement>
			| React.ChangeEvent<HTMLTextAreaElement>
	): void => {
		if (!activeTopic) return;

		event.preventDefault();
		const { name, value } = event.target;

		updateActiveTopic({
			[name]: value === "true" ? true : value === "false" ? false : value
		});
	};

	const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (!activeTopic) return;
		const { checked, name } = event.target;

		if (name === "isParent" && checked) {
			updateActiveTopic({ isChild: false, isParent: true, parentId: "" });
			return;
		}

		if (name === "isChild" && !checked) {
			updateActiveTopic({ isChild: false, parentId: "" });
			return;
		}

		updateActiveTopic({ [name]: checked });
	};

	const hasMedia = templateInfo?.topicConfig?.hasImage || templateInfo?.topicConfig?.hasVideo;
	const columns = hasMedia ? ["1fr", "2fr 5fr"] : ["1fr", "1fr", "70%"];

	return (
		<Grid columns={columns}>
			{hasMedia && (
				<Box>
					{templateInfo?.topicConfig?.hasImage && <ActiveTopicImageUploader />}

					{templateInfo?.topicConfig?.hasVideo && (
						<>
							<ActiveTopicContentUploader />
							<ActiveTopicYoutube />
						</>
					)}
				</Box>
			)}

			<ActiveTopicForm handleChange={handleChange} handleCheckBoxChange={handleCheckBoxChange} />
		</Grid>
	);
};
