import React, { useState, useEffect, useRef } from "react";

interface UseCollaspeResult {
  height: string;
  childRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const useCollaspe = (isOpen: boolean): UseCollaspeResult => {
  const [height, setHeight] = useState(isOpen ? "auto" : "0");

  const childRef = useRef<HTMLDivElement | null>(null);

  const hideContent = () => {
    setHeight("0");
  };

  const showContent = () => {
    if (!childRef.current) return;
    const currentHeight = childRef.current.scrollHeight;
    setHeight(`${currentHeight}px`);
  };

  useEffect(() => {
    isOpen ? showContent() : hideContent();
  }, [isOpen]);

  useEffect(() => {
    const observer = new MutationObserver(() => {
      showContent();
    });

    childRef.current &&
      observer.observe(childRef.current, {
        attributes: true,
        childList: true,
        subtree: true
      });

    return () => {
      observer.disconnect();
    };
  }, []);

  return { height, childRef };
};
