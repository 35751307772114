import { ThemeUICSSObject } from "theme-ui";

export const sidebarWrapper = (isSideBarOpen: boolean): ThemeUICSSObject => ({
  width: "15.625rem",
  height: "calc(100vh - 4.5rem)",
  background: "background3",
  position: "fixed",
  left: isSideBarOpen ? "0" : "-15.625rem",
  top: "4.5rem",
  transition: "left 0.5s ease-in-out",
  zIndex: "1000",
  boxShadow: [0, 0, "7px 5px 5px rgba(0, 0, 0, 0.25)"]
});

export const scroller: ThemeUICSSObject = {
  width: "100%",
  height: "100%",
  overflowY: "auto"
};

export const navTitle: ThemeUICSSObject = {
  color: "text2",
  fontSize: 2,
  fontWeight: "medium",
  height: "auto",
  padding: 0,
  paddingBottom: 2,
  paddingLeft: 2
};

export const menuItem = (isActive: boolean): ThemeUICSSObject => ({
  alignItems: "center",
  background: isActive ? "background1" : "transparent",
  borderLeft: "thick",
  borderLeftColor: isActive ? "accent1" : "transparent",
  borderRadius: "navigation",
  color: isActive ? "text" : "text2",
  cursor: "pointer",
  display: "grid",
  fontSize: 2,
  gridTemplateColumns: "1.875rem 1fr 2rem",
  height: "2.875rem",
  justifyContent: "center",
  marginBottom: 1,
  paddingLeft: 3,
  transitionDuration: "0.5s",
  transitionProperty: "color, backgroundColor, borderLeft",
  width: "calc(100% - 0.875rem)",

  "&:hover": {
    color: "text3",
    background: "background1"
  }
});

export const icon: ThemeUICSSObject = {
  width: 7,
  height: 7,
  borderRadius: "rounded",
  alignItems: "center",
  justifyContent: "center",
  color: "text2",
  background: "color1",
  overflow: "hidden"
};

export const title: ThemeUICSSObject = {
  display: "inline-block",
  lineHeight: "content",
  verticalAlign: "middle",
  paddingLeft: 0.5
};

export const subMenuIcon: ThemeUICSSObject = {
  alignItems: "center",
  justifyContent: "left",
  paddingRight: 2,
  svg: {
    color: "accent1",
    width: 5,
    "&:hover": {
      color: "accent5"
    }
  }
};

export const subMenu = (isActive: boolean, count: number): ThemeUICSSObject => ({
  overflow: "hidden",
  height: isActive ? `calc(${count} * 2rem)` : "0",
  transitionDuration: "0.5s",
  transitionProperty: "height",
  transitionTimingFunction: "ease-in-out",

  marginBottom: 0,
  marginTop: 0,
  padding: 0,
  listStyle: "none"
});

export const subMenuItem: ThemeUICSSObject = {
  display: "flex",
  alignItems: "center",
  justifyContent: "left",
  textIndent: "3.5rem",
  fontSize: 2,
  height: 8,
  transitionProperty: "all",
  transitionDuration: "0.5s",

  a: {
    color: "text2",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center"
  },

  "&:hover": {
    a: {
      color: "text3",
      background: "background1"
    }
  }
};
