import React from "react";
import { Box, Button, Divider, Flex, Grid, Input, Label, Text } from "theme-ui";
import { useAuth } from "../../Hooks";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../Types";

export const Login: React.FC<{}> = () => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const { signIn, signInWithGoogle } = useAuth();

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    signIn(email, password);
  };

  return (
    <Flex variant="auths.wrapper">
      <Box variant="auths.container">
        <h1>Gamer Tool Kit</h1>
        <h4>Enter your login credentials</h4>
        <Divider my={3} sx={{ color: "accent1" }} />
        <form onSubmit={handleFormSubmit}>
          <Label variant="auths.label" htmlFor="email">
            Email:
          </Label>
          <Input
            id="email"
            name="email"
            onChange={e => setEmail(e.target.value)}
            placeholder="Enter your Email Address"
            required={true}
            sx={{
              marginBottom: "1.25rem",
              padding: ".625rem"
            }}
            type="email"
            value={email}
          />

          <Label variant="auths.label" htmlFor="password">
            Password:
          </Label>
          <Input
            id="password"
            name="password"
            onChange={e => setPassword(e.target.value)}
            placeholder="Enter your Password"
            required={true}
            sx={{
              marginBottom: "1.25rem",
              padding: ".625rem"
            }}
            type="password"
            value={password}
          />

          <Grid variant="auths.buttonWrapper">
            <Button type="submit" variant="auths.submit">
              Submit
            </Button>

            <Text> - or - </Text>

            <Button
              onClick={signInWithGoogle}
              type="button"
              variant="auths.submitGoogle"
            >
              Login with Google
            </Button>
          </Grid>
        </form>
        <Text>
          Not registered?{" "}
          <span>
            <Link to={AppRoutes.SignUp}>Create an account</Link>
          </span>
        </Text>

        <Box mt={2}>
          <Text>
            <Link to={AppRoutes.Forgot}>Forgot Password?</Link>
          </Text>
        </Box>
      </Box>
    </Flex>
  );
};
