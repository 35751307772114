import React from "react";
import Dropzone from "react-dropzone";
import { Box, Button, Flex, Image, Spinner } from "theme-ui";
import { topicImageParser } from "../../../../../../Utils";
import httpService from "../../../../../../Api/httpService";
import Icon from "@mdi/react";
import { mdiTrashCan } from "@mdi/js";
import { useEpisodeId, useGlobalData, useToaster } from "../../../../../../Hooks";
import { useEpisodeDataStore } from "../../../../../../DataStores";

export const ActiveTopicImageUploader: React.FC = () => {
  const { handleUpdateStateImage } = useGlobalData();

  const { activeTopic, isButtonDisabled, setSectionLoading, topicImageDimensions } =
    useEpisodeDataStore();

  const { toaster } = useToaster();
  const isDisabled = isButtonDisabled();

  const topicId = activeTopic?._id;
  const topicImage = activeTopic?.img;

  const episodeId = useEpisodeId();
  const [progressState, setProgressState] = React.useState(false);
  const [isDragOver, setIsDragOver] = React.useState<boolean>(false);

  const handleFileUploads = async (acceptedFiles: File[]) => {
    const allowedFileTypes = ["image/jpeg", "image/png", "image/webp"];
    const maxFileSize = 2 * 1024 * 1024;

    if (!topicId || !acceptedFiles[0] || isDisabled) return;

    if (!allowedFileTypes.includes(acceptedFiles[0].type)) {
      toaster("Invalid file type. Only JPEG and PNG are allowed.", "error");
      return;
    }

    if (acceptedFiles[0].size > maxFileSize) {
      toaster(`File size exceeds the maximum limit of ${maxFileSize}Kb`, "error");
      return;
    }

    try {
      setSectionLoading("topic-image");
      const formData = new FormData();
      formData.append("episodeId", episodeId as string);
      formData.append("file", acceptedFiles[0]);
      formData.append("imageType", "topic");
      formData.append("topicId", topicId);

      const { data } = await httpService.post(`episode-topics-upload/topic/thumbnail`, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        },
        onUploadProgress: progressEvent => {
          setProgressState(true);
        }
      });

      if (data.resultStatus.success) {
        handleUpdateStateImage(data.result.url, "add");
      } else {
        throw new Error(data.resultStatus.resultMessage);
      }
    } catch (error: any) {
      toaster(String(error.message), "error");
    } finally {
      setProgressState(false);
      setSectionLoading("topic-image", false);
    }
  };

  const handleDeleteImage = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): Promise<void> => {
    e.stopPropagation();

    if (!topicId || isDisabled) return;

    try {
      const { data } = await httpService.delete(
        `${process.env.REACT_APP_REST_API}episode-topics-upload/${episodeId}/topic/${topicId}`
      );

      if (!data?.resultStatus.success) {
        throw new Error();
      } else {
        handleUpdateStateImage("", "delete");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Flex variant="mediaUploader.container">
      <Dropzone onDrop={acceptedFiles => handleFileUploads(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} style={{ width: "100%" }}>
            <input {...getInputProps()} />

            <Box
              variant="mediaUploader.wrapper"
              sx={{
                aspectRatio: `${topicImageDimensions?.width} /  ${topicImageDimensions?.height}`,
                borderColor: isDragOver ? "accent2" : "accent3"
              }}
              onDragOver={() => setIsDragOver(true)}
              onDragLeave={() => setIsDragOver(false)}
              onDrop={() => setIsDragOver(false)}
            >
              <Box variant="mediaUploader.spinnerWrapper">
                <Spinner
                  sx={{ display: progressState ? "block" : "none" }}
                  variant="mediaUploader.spinner"
                />
              </Box>

              <Box
                as="button"
                aria-label="Delete Topic Image"
                onClick={handleDeleteImage}
                sx={{ opacity: topicImage ? 1 : 0 }}
                variant="mediaUploader.deleteButtonWrapper"
              >
                <Icon path={mdiTrashCan} size={0.5} />
              </Box>

              {topicImage && (
                <Image
                  alt={"topic"}
                  src={topicImageParser(
                    topicImage,
                    topicImageDimensions?.width,
                    topicImageDimensions?.height
                  )}
                  sx={{ width: "100%" }}
                />
              )}
            </Box>

            <Box my={2}>
              <Button disabled={isDisabled} sx={{ width: "100%" }}>
                Choose Thumbnail Image
              </Button>
            </Box>
          </div>
        )}
      </Dropzone>
    </Flex>
  );
};
