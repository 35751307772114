export const colors = {
  text: "#dddddd",
  text2: "#999",
  text3: "#ffffff",
  text4: "#6c7293",
  background: "#000000",
  background1: "#0f1015",
  background2: "#111",
  background3: "#191c24",
  background4: "#222",
  primary: "#1a73e8",
  secondary: "#9c27b0",
  muted: "#f1f3f4",

  accent1: "#0090e7",
  accent2: "#00d25b",
  accent3: "#55398B",
  accent4: "#fc424a",
  accent5: "#ffab00",
  accent6: "#2c2e33",
  accent7: "#bd3d18",
  accent9: "#3a0a93",
  accent10: "#737373",

  border1: "#2c2e33",
  color1: "2a2d3a"
};
