import { AxiosError } from "axios";
import { useQuery, useMutation } from "react-query";
import httpService from "../httpService";
import { ApiResponse } from "../Types";

export interface IUserCommandsCommands {
	_id: string;
	command: string;
	description: string;
	status: boolean;
	subType: string;
	type: string;
	usage?: string;
}

type OverlayCommandsResponse = {
	commands: IUserCommandsCommands[];
};

type OverlayCommandsUpdateVariables = {
	_id: string;
	type: string;
	status: boolean;
};

type OverlayCommandsUpdateResponse = {
	success: 0 | 1;
	setting: IUserCommandsCommands;
};

type CommandType = "videoRequest" | "podcastOverlays";

const fetchCommands = async (type: CommandType) => {
	const { data } = await httpService.get<ApiResponse<OverlayCommandsResponse>>(
		`/podcast-commands?type=${type}`
	);

	return data.result;
};

export const useFetchCommands = (type: CommandType) => {
	return useQuery<OverlayCommandsResponse, AxiosError<{ error: unknown }>>(
		["overlayCommandsFetch", type],
		() => fetchCommands(type),
		{
			retry: 0
		}
	);
};

const updateCommands = async (useSettingObj: OverlayCommandsUpdateVariables) => {
	const { data } = await httpService.patch<ApiResponse<OverlayCommandsUpdateResponse>>(
		`/podcast-commands`,
		useSettingObj
	);

	return data.result;
};

export const useUpdateCommands = () => {
	return useMutation<
		OverlayCommandsUpdateResponse,
		AxiosError<{ error: unknown }>,
		OverlayCommandsUpdateVariables
	>(["episodeHostsUpdate"], useSettingObj => updateCommands(useSettingObj), {
		retry: 0
	});
};
