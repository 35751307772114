import { AxiosError } from "axios";
import { useMutation } from "react-query";
import httpService from "../httpService";

import {
  CannedMessageSearchVariables,
  CannedMessageSearchResponse,
  CannedMessageCreationVariables,
  CannedMessageCreationResponse,
  CannedMessageEditResponse,
  CannedMessageEditVariables,
  ApiResponse
} from "../Types";

const searchMessages = async (searchObj: CannedMessageSearchVariables) => {
  const { data } = await httpService.get<ApiResponse<CannedMessageSearchResponse>>(
    `podcast-canned-messages/s/${searchObj.page}/${searchObj.sort}/${searchObj.order}?st=${
      searchObj.searchTerm || ""
    }`
  );

  return data.resultStatus.success
    ? { ...data.result }
    : { currentPage: 1, messages: [], totalPages: 0 };
};

export const useSearchMessages = () => {
  return useMutation<
    CannedMessageSearchResponse,
    AxiosError<{ error: unknown }>,
    CannedMessageSearchVariables
  >(["cannedMessages"], searchObj => searchMessages(searchObj), {
    retry: 0
  });
};

const createMessage = async (messageObj: CannedMessageCreationVariables) => {
  const { data } = await httpService.post<ApiResponse<CannedMessageCreationResponse>>(
    `podcast-canned-messages`,
    messageObj
  );

  return data.result;
};

export const useCreateMessage = () => {
  return useMutation<
    CannedMessageCreationResponse | null,
    AxiosError<{ error: unknown }>,
    CannedMessageCreationVariables
  >(["cannedMessages"], messageObj => createMessage(messageObj), {
    retry: 0
  });
};

const editMessage = async (messageObj: CannedMessageEditVariables) => {
  const { data } = await httpService.put<ApiResponse<CannedMessageEditResponse>>(
    `podcast-canned-messages/${messageObj._id}`,
    messageObj
  );

  return data.result;
};

export const useEditMessage = () => {
  return useMutation<
    CannedMessageEditResponse,
    AxiosError<{ error: unknown }>,
    CannedMessageEditVariables
  >(["cannedMessagesEdit"], messageObj => editMessage(messageObj), {
    retry: 0
  });
};
