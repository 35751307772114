import _cloneDeep from "lodash/cloneDeep";

import { useEpisodeDataStore } from "../../DataStores/useEpisodeDataStore";

const useGloblaDataHook = () => {
  const globalDataStore = useEpisodeDataStore();

  if (globalDataStore === undefined) {
    throw new Error("useGlobalDataHook must be used within a GlobalDataProvider");
  }

  const getIndexfromId = (_id: string | undefined = undefined): number => {
    const topicId = _id || globalDataStore.activeTopic?._id;
    return globalDataStore.topics.findIndex(item => item._id === topicId);
  };

  const handleUpdateStateImage = (fileName: string, type: string = "add") => {
    const index = getIndexfromId();

    const newTopics = _cloneDeep(useEpisodeDataStore.getState().topics);
    newTopics[index].img = type === "add" ? fileName : "";

    const newActiveTopic = useEpisodeDataStore.getState().activeTopic;
    if (newActiveTopic) {
      newActiveTopic.img = fileName;
    }

    useEpisodeDataStore.setState({
      topics: newTopics,
      activeTopic: newActiveTopic ? newActiveTopic : newTopics[index]
    });
  };

  const handleUpdateStateVideo = (video: string) => {
    const index = getIndexfromId();

    const newTopics = _cloneDeep(useEpisodeDataStore.getState().topics);
    newTopics[index].video = video;
    newTopics[index].content = { type: "video", file: video };

    const newActiveTopic = useEpisodeDataStore.getState().activeTopic;
    if (newActiveTopic) {
      newActiveTopic.video = video;
      newActiveTopic.content = { type: "video", file: video };
    }

    useEpisodeDataStore.setState({
      topics: newTopics,
      activeTopic: newActiveTopic ? newActiveTopic : newTopics[index]
    });
  };

  const handleUpdateStateContent = (
    contentUrl: string,
    contentFileName: string,
    type: string | null
  ) => {
    const index = getIndexfromId();

    const newTopics = _cloneDeep(useEpisodeDataStore.getState().topics);
    newTopics[index].video = contentFileName;
    newTopics[index].content = { type, file: contentUrl };

    const newActiveTopic = useEpisodeDataStore.getState().activeTopic;
    if (newActiveTopic) {
      newActiveTopic.video = contentUrl;
      newActiveTopic.content = { type, file: contentUrl };
    }

    useEpisodeDataStore.setState({
      topics: newTopics,
      activeTopic: newActiveTopic ? newActiveTopic : newTopics[index]
    });
  };

  return {
    handleUpdateStateImage,
    handleUpdateStateVideo,
    handleUpdateStateContent
  };
};

export default useGloblaDataHook;
