import React from "react";
import { CompactPicker } from "react-color";
import { Box, Flex } from "theme-ui";
import Icon from "@mdi/react";
import { mdiChevronRight, mdiClose } from "@mdi/js";
import { PreviewControls } from "./Controls";

interface IColorPickerProps {
  handlePreviewBGColor: (color: any) => void;
  showColorPicker: boolean;
  setShowColorPicker: (show: boolean) => void;
}

export const IFrameColorPicker: React.FC<IColorPickerProps> = ({
  handlePreviewBGColor,
  showColorPicker,
  setShowColorPicker
}) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: showColorPicker ? ".5rem" : "-245px",
        transform: "translateY(-50%)",
        zIndex: "9999",
        transition: "left .75s ease-in-out",
        width: "245px",
        background: "white",
        boxShadow: "0 0 1rem rgba(0,0,0,.25)",
        "*": {
          boxShadow: "none !important"
        }
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          right: "-2rem",
          transform: "translateY(-50%)",
          zIndex: "10",

          backgroundColor: "red",
          borderRadius: "0 .25rem .25rem 0",
          height: "2rem",
          width: "2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer"
        }}
        onClick={() => setShowColorPicker(!showColorPicker)}
      >
        <Icon path={showColorPicker ? mdiClose : mdiChevronRight} size={1} />
      </Box>
      <Flex>
        <CompactPicker onChange={handlePreviewBGColor} />
      </Flex>

      <Flex
        sx={{
          justifyContent: "center",
          alignItems: "center",
          padding: ".5rem 0",
          borderTop: "1px solid #ccc"
        }}
      >
        <PreviewControls />
      </Flex>
    </Box>
  );
};
