const defaultCard = {
  width: "100%",
  padding: 0,
  borderRadius: "0.375rem",
  boxShadow: ["none", "0 4px 8px 0 rgba(0,0,0,0.2)"],
  backgroundColor: "background1",
  overflow: "hidden",
  transition: "0.3s",
  "&:hover": {
    boxShadow: ["none", "0 8px 16px 0 rgba(0,0,0,0.2)"]
  }
};

export const cards = {
  primary: { ...defaultCard },
  fullPage: {
    ...defaultCard,
    minHeight: "100%"
  },
  profileFullPage: {
    ...defaultCard,
    minHeight: "calc( 100vh - 13rem)"
  },
  compact: {
    padding: 1,
    borderRadius: 2,
    border: "1px solid",
    borderColor: "muted"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    font: "1.125rem",
    color: "text2",
    padding: ".75rem",
    marginBottom: "0.75rem",
    borderBottom: ".0625rem solid",
    borderColor: "accent3",
    zIndex: "200",
    height: "3.5rem"
  },
  headerForm: {
    padding: ".5rem 1rem",
    ">div": {
      paddingBottom: ".5rem",
      display: "grid",
      gridTemplateColumns: ["1fr", "3fr 2fr max-content"],
      gap: "1rem",
      borderBottom: ".0625rem dotted",
      borderColor: "accent3"
    }
  },
  body: {
    flex: "1 1 auto",
    minHeight: "2rem",
    padding: "0.5rem .5rem 0.5rem  .5rem"
  },
  footer: {}
};
