import * as React from "react";
import { Box } from "theme-ui";

interface CheckboxProps {
  checked: boolean;
  _id: string;
  label: string;
  onChange: (_id: string) => Promise<void>;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  _id,
  label,
  onChange
}) => {
  return (
    <Box variant="checkbox.wrapper">
      <Box variant="checkbox.label">{label}</Box>
      <input type="checkbox" checked={checked} onChange={() => {}} />
      <span onClick={() => onChange(_id)} />
    </Box>
  );
};
