import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { AppRoutes } from "../Types";
import ProtectedRoute from "./ProtectedRoute";

import { ApplicationWrapper } from "../Components/ApplicationWrapper/ApplicationWrapper";

import { EpisodeListing } from "../Components/PodcastOverlays/Episodes/Listing/Listing";
import { EpisodeProfile } from "../Components/PodcastOverlays/Episodes/Profile/Profile";

import { Login } from "../Components/Login/Login";
import { Socials } from "../Components/PodcastOverlays/Socials/Socials";

import { ProfileTopics } from "../Components/PodcastOverlays/Episodes/Profile/Topics/Topics";
import { ProfileHost } from "../Components/PodcastOverlays/Episodes/Profile/Host/Host";
import { ProfileNewsTicker } from "../Components/PodcastOverlays/Episodes/Profile/NewsTicker/NewsTicker";
import { ProfileSocials } from "../Components/PodcastOverlays/Episodes/Profile/Socials/Socials";
import { ProfileImages } from "../Components/PodcastOverlays/Episodes/Profile/Images/Images";
import { ProfileBrowserLinks } from "../Components/PodcastOverlays/Episodes/Profile/BrowserLinks/BrowserLinks";
import { Controls } from "../Components/PodcastOverlays/Controls/Controls";
import { Overlays } from "../Components/PodcastOverlays/Overlays/Overlays";
import { ProfileInformation } from "../Components/PodcastOverlays/Episodes/Profile/Information/Information";
import { ChatBotsListing } from "../Components/Connections/Twitch/Twitch";
import { TwitchConnect } from "../Components/Connections/Twitch/TwitchConnect";
import { ComingSoon } from "../Components/ComingSoon/ComingSoon";
import { HostListing } from "../Components/PodcastOverlays/Host/Hosting";
import { ShowRunner } from "../Public/ShowRunner/ShowRunner";

import { Commands } from "../Components/PodcastOverlays/Commands/Commands";

import { SignUp } from "../Components/Login/SignUp";
import { Forgot } from "../Components/Login/Forgot";
import { Home } from "./../Components/Home/Home";
import { YoutubeDesc } from "../Public/YoutubeDesc/YoutubeDesc";
import { CannedMessages } from "../Components/PodcastOverlays/CannedMessages/CannedMessages";
import { LandingPage } from "../Components/Shared/LandingPage/LandingPage";
import { useCommonDataStore } from "../DataStores";
import { Loading } from "../Components/Shared/Loading/Loading";

export const applicationRouter = createBrowserRouter([
	{
		path: "/",
		element: <Home />,
		errorElement: <h1>error</h1>
	},
	{
		path: AppRoutes.Login,
		element: <Login />,
		errorElement: <h1>error</h1>
	},
	{
		path: AppRoutes.SignUp,
		element: <SignUp />,
		errorElement: <h1>error</h1>
	},
	{
		path: AppRoutes.Forgot,
		element: <Forgot />,
		errorElement: <h1>error</h1>
	},
	{
		path: AppRoutes.Home,
		element: <Home />,
		errorElement: <h1>error</h1>
	},
	{
		path: AppRoutes.ShowRunner,
		element: <ShowRunner />,
		errorElement: <h1>error</h1>
	},
	{
		path: AppRoutes.YoutubeDesc,
		element: <YoutubeDesc />,
		errorElement: <h1>error</h1>
	},
	{
		path: "/admin",
		element: (
			<ProtectedRoute>
				<ApplicationWrapper />
			</ProtectedRoute>
		),
		errorElement: <h1>error</h1>,

		children: [
			{
				path: AppRoutes.DashBoard,
				element: (
					<LandingPage section="dashboard">
						<ComingSoon />
					</LandingPage>
				)
			},
			{
				path: AppRoutes.Connections,
				element: <LandingPage section="connections" />,
				children: [
					{
						path: AppRoutes.ConnectionTwitch,
						element: <ChatBotsListing />
					},
					{
						path: AppRoutes.ConnectionTwitchRedirect,
						element: <TwitchConnect />
					},
					{
						path: AppRoutes.ConnectionYoutube,
						element: <ComingSoon />
					}
				]
			},
			{
				path: AppRoutes.Documentation,
				element: (
					<LandingPage section="documentation">
						<ComingSoon />
					</LandingPage>
				)
			},

			{
				path: AppRoutes.Episodes,
				element: <LandingPage section="podcast" />,

				children: [
					{
						path: AppRoutes.EpisodeList,
						element: <EpisodeListing />
					},
					{
						path: AppRoutes.EpisodeProfile,
						element: <EpisodeProfile />,
						children: [
							{
								path: AppRoutes.EpisodeProfileInfo,
								element: <ProfileInformation />
							},

							{
								path: AppRoutes.EpisodeProfileTopics,
								element: <ProfileTopics />
							},

							{
								path: AppRoutes.EpisodeProfileHosts,
								element: <ProfileHost />
							},

							{
								path: AppRoutes.EpisodeProfileNews,
								element: <ProfileNewsTicker />
							},

							{
								path: AppRoutes.EpisodeProfileSocials,
								element: <ProfileSocials />
							},

							{
								path: AppRoutes.EpisodeProfileImages,
								element: <ProfileImages />
							},

							{
								path: AppRoutes.EpisodeProfileLinks,
								element: <ProfileBrowserLinks />
							}
						]
					}
				]
			},
			{
				path: AppRoutes.Hosts,
				element: <LandingPage section="podcast" />,

				children: [
					{
						path: AppRoutes.HostList,
						element: <HostListing />
					},
					{
						path: AppRoutes.HostProfile,
						element: <h1>host profile</h1>
					}
				]
			},
			{
				path: AppRoutes.Socials,
				element: <LandingPage section="podcast" />,

				children: [
					{
						path: AppRoutes.SocialList,
						element: <Socials />
					},
					{
						path: AppRoutes.SocialProfile,
						element: <Socials />
					}
				]
			},
			{
				path: AppRoutes.Controls,
				element: <LandingPage section="podcast" />,

				children: [
					{
						path: AppRoutes.ControlList,
						element: <Controls />
					}
				]
			},
			{
				path: AppRoutes.Commands,
				element: <LandingPage section="podcast" />,

				children: [
					{
						path: AppRoutes.CommandList,
						element: <Commands />
					}
				]
			},
			{
				path: AppRoutes.CannedMessages,
				element: <LandingPage section="podcast" />,

				children: [
					{
						path: AppRoutes.CannedMessagesList,
						element: <CannedMessages />
					}
				]
			},
			{
				path: AppRoutes.Overlays,
				element: <LandingPage section="podcast" />,

				children: [
					{
						path: AppRoutes.OverlayList,
						element: <Overlays />
					}
				]
			}
		]
	}
]);

export const AppRouter: React.FC<{}> = () => {
	const { templates } = useCommonDataStore();

	if (templates.length === 0) {
		return <Loading />;
	}

	return <RouterProvider router={applicationRouter} />;
};
