import React, { useState, useEffect, useRef } from "react";
import { Box, BoxProps } from "theme-ui";

interface ContainerProps {
  children: React.ReactNode;
  marginBottom?: number;
}

export const Container: React.FC<ContainerProps & BoxProps> = ({
  children,
  sx,
  marginBottom = 16
}) => {
  const [containerHeight, setContainerHeight] = useState<number>(
    window.innerHeight
  );
  const containerRef = useRef<HTMLDivElement>(null);

  const handleResize = () => {
    const rect = containerRef.current?.getBoundingClientRect();
    if (rect) {
      setContainerHeight(window.innerHeight - rect.top - marginBottom);
    }
  };

  useEffect(() => {
    const rect = containerRef.current?.getBoundingClientRect();
    if (rect) {
      handleResize();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      data-testid="hello"
      ref={containerRef}
      sx={{
        "&::-webkit-scrollbar": {
          width: ["0.25rem", "0.375rem"]
        },
        height: `${containerHeight}px`,
        overflowY: "scroll",
        overflowX: "hidden",
        marginTop: "0px",
        ...sx
      }}
    >
      <Box
        sx={{
          paddingRight: ["0.375rem", "0.5rem"]
        }}
      >
        {children}
      </Box>
    </Box>
  );
};
