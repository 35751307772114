import React, { FC, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Box, Button, Flex, Label, Radio, Spinner } from "theme-ui";
import { ITemplateImages, SponsorImages } from "../../../../../Types";
import { ImageType } from "./ImageTypes";
import { useUploadEpisodeImages } from "../../../../../Api";
import { useEpisodeId } from "../../../../../Hooks";

interface IImageUploaderProps {
  templateImages: ITemplateImages;
  setImageCallback: (type: ImageType, images: SponsorImages[] | string) => void;
}

export const ImageUploader: FC<IImageUploaderProps> = ({ templateImages, setImageCallback }) => {
  const episodeId = useEpisodeId();
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [imageType, setImageType] = useState<ImageType>(ImageType.Logo);
  const { data, isLoading, mutate } = useUploadEpisodeImages();

  const handleFileUploads = async (acceptedFiles: File[]) => {
    mutate({
      episodeId: episodeId as string,
      file: acceptedFiles[0],
      imageType: imageType
    });
  };

  useEffect(() => {
    if (data && !isLoading) {
      setImageCallback(data.imageType, data.images);
    }
  }, [data, isLoading, setImageCallback]);

  const handleRadioChange = (e: any) => {
    setImageType(e.target.value);
  };

  return (
    <Flex sx={{ flexDirection: "column", gap: ".5rem" }}>
      <Flex>
        <Label>
          <Radio
            checked={imageType === ImageType.Logo}
            name="imageType"
            onChange={handleRadioChange}
            value={ImageType.Logo}
          />
          Logo
        </Label>

        {templateImages.sponsors.maxAmount > 0 && (
          <Label>
            <Radio
              checked={imageType === ImageType.Sponsors}
              name="imageType"
              onChange={handleRadioChange}
              value={ImageType.Sponsors}
            />
            Sponsors
          </Label>
        )}
      </Flex>
      <Dropzone onDrop={acceptedFiles => handleFileUploads(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <>
            <div {...getRootProps()} style={{ width: "100%" }}>
              <input {...getInputProps()} />
              <Flex
                sx={{
                  width: "100%",
                  height: "5rem",
                  justifyContent: "center",
                  alignItems: "center",
                  border: ".0625rem dashed",
                  borderColor: isDragOver ? "accent2" : "accent3",
                  fontWeight: isDragOver ? "600" : "300",
                  borderRadius: ".375rem",
                  transition: "border-color .3s ease-in-out",
                  fontSize: ".875rem"
                }}
                onDragOver={() => setIsDragOver(true)}
                onDragLeave={() => setIsDragOver(false)}
                onDrop={() => setIsDragOver(false)}
              >
                {!isLoading && <>Drag & drop files here!</>}
                <Spinner
                  sx={{
                    color: "accent3",
                    display: isLoading ? "block" : "none",
                    position: "absolute"
                  }}
                />
              </Flex>
              <Box sx={{ my: ".5rem" }}>
                <Button>Browse Images</Button>
              </Box>{" "}
            </div>{" "}
          </>
        )}
      </Dropzone>{" "}
    </Flex>
  );
};
