import { ThemeUICSSObject } from "theme-ui";

export const userMenuWrapper: ThemeUICSSObject = {
  alignItems: "center",
  justifyContent: "right",
  gap: 3,
  cursor: "pointer"
};

export const userIcon: ThemeUICSSObject = {
  width: 8,
  height: 8,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "rounded",
  overflow: "hidden",
  backgroundColor: "accent3",
  img: {
    width: "100%"
  }
};

export const username: ThemeUICSSObject = {
  alignItems: "center",
  justifyContent: "right",
  height: "100%"
};
