import { ThemeUICSSObject } from "theme-ui";

export const userMenuWrapper = (isUserMenuOpen: boolean): ThemeUICSSObject => ({
  backgroundColor: "background3",
  borderRadius: "small",
  boxShadow: "black 0px 0px 35px -3px",
  minWidth: "12rem",
  position: "absolute",
  right: "0.375rem",
  transition: "all 0.5s ease-in-out",
  opacity: isUserMenuOpen ? 1 : 0,
  top: isUserMenuOpen ? "calc(4.5rem - .25rem)" : 0,
  zIndex: "100",
  pointerEvents: isUserMenuOpen ? "auto" : "none"
});

export const userMenuWrapperItem: ThemeUICSSObject = {
  height: 14,
  width: "100%",
  borderTop: "thin",
  borderColor: "border1",
  alignItems: "center",
  transition: "background-color 0.2s ease-in-out",
  fontSize: 2,

  "&:nth-of-type(1)": {
    borderTop: "none"
  },

  "&:hover": {
    backgroundColor: "background1"
  }
};

export const userMenuWrapperItemLink: ThemeUICSSObject = {
  display: "flex",
  width: "100%",

  "> div:nth-of-type(1)": {
    alignItems: "center",
    backgroundColor: "background",
    borderRadius: "rounded",
    display: "flex",
    height: 8,
    justifyContent: "center",
    margin: "0.5rem 0.75rem 0.5rem 0.5rem",
    overflow: "hidden",
    width: 8,

    svg: {
      width: 4,
      color: "accent2"
    }
  },

  "> div:nth-of-type(2)": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "text2",

    "&:hover": {
      color: "text3"
    }
  }
};
