import React from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Card } from "../../../theme/UI";
import { Box } from "theme-ui";

import httpService from "../../../Api/httpService";
import { AppRoutes } from "../../../Types";

export const TwitchConnect: React.FC<{}> = () => {
  const navigate = useNavigate();
  const [connected, setConnected] = React.useState<boolean>(false);
  const [apiError, setApiError] = React.useState<boolean>(false);

  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get("code");

    const TWITCH_CLIENT_ID = process.env.REACT_APP_TWITCH_CLIENT_ID;
    const TWITCH_CLIENT_SECRET = process.env.REACT_APP_TWITCH_CLIENT_SECRET;
    const redirect = process.env.REACT_APP_TWITCH_REDIRECT_URL || "";

    const setChat = async (token: any) => {
      try {
        const { data } = await httpService.post(
          `${process.env.REACT_APP_REST_API}twitch-chat/add-to-chat`,
          token
        );

        if (data.resultStatus.success) {
          setConnected(true);
        }
      } catch (error) {
        console.error(32, error);
      }
    };

    const fetchData = async () => {
      const link = `https://id.twitch.tv/oauth2/token?client_id=${TWITCH_CLIENT_ID}&client_secret=${TWITCH_CLIENT_SECRET}&code=${code}&grant_type=authorization_code&redirect_uri=${encodeURIComponent(
        redirect
      )}`;

      try {
        const { data } = await axios.post(link);

        if (data.access_token) {
          setChat(data);
        } else {
          throw new Error("No access token");
        }
      } catch (error) {
        console.error(45, error);
        setApiError(true);
      }
    };

    code && fetchData();
  }, []);

  React.useEffect(() => {
    if (connected) {
      setTimeout(() => {
        navigate(AppRoutes.ConnectionTwitch);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connected]);

  return (
    <>
      <Card>
        <Card.Header>Twitch Connection</Card.Header>

        <Card.Body>
          <Box mb={2}>
            {apiError && !connected && (
              <Box>There was an error connecting to Twitch. Please try again.</Box>
            )}

            {connected && <Box>You have successfully connected to Twitch.</Box>}
          </Box>
        </Card.Body>
      </Card>
    </>
  );
};
