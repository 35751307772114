export const checkbox = {
  wrapper: {
    display: "flex",
    position: "relative",
    cursor: "pointer",
    fontSize: ".875rem",
    userSelect: "none",
    height: "18px",

    alignItems: "center",
    justifyContent: "center",

    "div:first-of-type": {},

    input: {
      position: "absolute",
      opacity: 0,
      cursor: "pointer",
      height: 0,
      width: 0
    },

    span: {
      position: "absolute",
      top: 0,
      left: 0,
      height: "18px",
      width: "18px",
      backgroundColor: "accent6",
      border: "2px solid",
      borderColor: "accent2",
      borderRadius: "3px"
    },

    "&:hover input ~ span": {
      backgroundColor: "accent6"
    },

    "input:checked ~ span": {
      backgroundColor: "accent2",
      "&:after": {
        content: '""',
        position: "absolute",
        left: "3px",
        top: "-1px",
        width: "5px",
        height: "10px",
        border: "solid",
        borderWidth: "0 3px 3px 0",
        borderColor: "black",
        transform: "rotate(45deg)"
      }
    }
  },

  label: { fontSize: "0.875rem", paddingLeft: "35px" }
};
