import { AxiosError } from "axios";
import { useQuery, useMutation } from "react-query";
import httpService from "../httpService";

import { useToaster } from "../../Hooks";
import {
  EpisodeInfoFetchResponse,
  EpisodeInfoUpdateVariables,
  EpisodeInfoUpdateResponse,
  ApiResponse
} from "../Types";

const fetchEpisodeInfo = async (episodeId: string): Promise<EpisodeInfoFetchResponse> => {
  const { data } = await httpService.get<ApiResponse<EpisodeInfoFetchResponse>>(
    `episode-segments/information/${episodeId}`
  );

  return data.result;
};

export const useFetchEpisodeInfo = (episodeId?: string | undefined) => {
  return useQuery<EpisodeInfoFetchResponse, AxiosError<{ error: unknown }>>(
    ["episodeInfoFetch", episodeId],
    () => fetchEpisodeInfo(episodeId as string),
    {
      retry: 0,
      enabled: !!episodeId
    }
  );
};

const updateEpisodeInfo = async (episodeObj: EpisodeInfoUpdateVariables) => {
  const { data } = await httpService.put<ApiResponse<EpisodeInfoUpdateResponse>>(
    `episode-segments/information/${episodeObj.episodeId}`,
    { ...episodeObj.episode }
  );

  return data.result;
};

export const useUpdateEpisodeInfo = () => {
  const { toaster } = useToaster();
  return useMutation<
    EpisodeInfoUpdateResponse,
    AxiosError<{ error: unknown }>,
    EpisodeInfoUpdateVariables
  >(["episodeInfoUpdate"], episodeObj => updateEpisodeInfo(episodeObj), {
    retry: 0,
    onSuccess: () => toaster("Episode updated successfully", "success")
  });
};
