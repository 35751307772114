import { ThemeUICSSObject } from "theme-ui";

export const TopicCard: ThemeUICSSObject = {
  borderBottom: "1px dotted",
  borderColor: "#2c2e33",
  mb: 2,
  pb: 2
};

export const TopicCardGrid: ThemeUICSSObject = {
  gridTemplateColumns: ["1fr", "100px 1fr"]
};

export const AdditionalInfo: ThemeUICSSObject = {
  gridColumn: "span 2"
};

export const Options: ThemeUICSSObject = {
  gridColumn: "span 2",
  my: 3,
  display: "flex",
  gap: 3
};
