import React from "react";

import { Label, ThemeUIStyleObject } from "theme-ui";

interface ILabelProps {
  children: React.ReactNode;
  sx?: ThemeUIStyleObject;
  variant?: string;
}

export const TheLabel: React.FC<ILabelProps> = ({
  children,
  sx = {},
  variant = ""
}) => {
  return (
    <>
      <Label mb={0} sx={sx} variant={variant}>
        {children}
      </Label>
    </>
  );
};
