import {
  mdiAccount,
  mdiApplicationOutline,
  mdiBook,
  mdiInstagram,
  mdiSnapchat,
  mdiTwitch,
  mdiTwitter,
  mdiYoutube
} from "@mdi/js";

export const socialIconsMap: { [key: string]: { color: string; icon: string } } = {
  Aka: { color: "#222", icon: mdiAccount },
  Discord: { color: "#222", icon: mdiApplicationOutline },
  Facebook: { color: "#4064ac", icon: mdiBook },
  Instagram: { color: "#cf2872", icon: mdiInstagram },
  Snapchat: { color: "#333333", icon: mdiSnapchat },
  TikTok: { color: "#18a39f", icon: mdiApplicationOutline },
  Twitch: { color: "accent3", icon: mdiTwitch },
  Twitter: { color: "#1d9bf0", icon: mdiTwitter },
  Youtube: { color: "#ff0000", icon: mdiYoutube }
};
