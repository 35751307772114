import React, { useEffect } from "react";
import { Box, Grid } from "theme-ui";
import Icon from "@mdi/react";
import { mdiTrashCan } from "@mdi/js";
import { SponsorImages } from "../../../../../Types";
import { ImageType } from "./ImageTypes";
import { useDeleteEpisodeImages } from "../../../../../Api";
import { useEpisodeId } from "../../../../../Hooks";

interface IImageListerProps {
  images: string | SponsorImages[];
  imageType: ImageType;
  setImageCallback: (type: ImageType, images: SponsorImages[] | string) => void;
}

export const ImageListing: React.FC<IImageListerProps> = ({
  images,
  imageType,
  setImageCallback
}) => {
  const episodeId = useEpisodeId();
  const imageArray = Array.isArray(images) ? images : images ? [images] : [];
  const { data, isLoading, mutate } = useDeleteEpisodeImages();

  useEffect(() => {
    if (data && !isLoading) {
      setImageCallback(data.imageType, data.images);
    }
  }, [data, isLoading, setImageCallback]);

  const handleDeleteImage = async (id: string): Promise<void> => {
    mutate({
      episodeId: String(episodeId),
      imageType,
      imageId: imageType === ImageType.Logo ? "logo" : id
    });
  };

  return (
    <>
      <Grid gap={".5rem"} columns={[2, 3, 4]}>
        {imageArray.map((data, index: number) => (
          <Box
            key={index}
            sx={{
              width: "100%",
              position: "relative",
              overflow: "hidden",
              border: "1px solid",
              borderColor: "transparent",
              borderBottomColor: "accent3",
              transition: "border-color .3s ease-in-out",
              "&:hover": {
                borderColor: "accent3"
              }
            }}
          >
            <Box
              sx={{
                width: "100%",
                overflow: "hidden",
                ">img": { width: "100%" }
              }}
            >
              <img src={typeof data === "object" ? data.url : data} alt={imageType} />
            </Box>
            <Box
              sx={{
                height: "1.5rem",
                width: "2rem",
                position: "absolute",
                top: "0",
                left: "0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "accent3",
                color: "white",
                fontSize: "0.8rem",
                borderRadius: "0 0 0.5rem 0"
              }}
              onClick={() => handleDeleteImage(typeof data === "object" ? data?._id : data)}
            >
              <Icon path={mdiTrashCan} size={0.75} />
            </Box>
          </Box>
        ))}
      </Grid>
    </>
  );
};
