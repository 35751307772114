import React from "react";
import { useBreakpointIndex } from "@theme-ui/match-media";
import { Config } from "../../../Config/Config";

interface IImageParser {
  image: string | undefined;
  name: string | undefined;
  logo: string | undefined;
}

export const ShowRunnerTopicImage: React.FC<IImageParser> = ({ image, name, logo }) => {
  const breakPointIndex = useBreakpointIndex();
  const isMobile = breakPointIndex <= 1;

  let imager = "";
  const alt = name || "GTK";

  if (image) {
    imager = image;
  } else if (logo) {
    imager = Config.REACT_APP_CLOUD_IMAGES + logo;
  }

  if (!imager && isMobile) {
    return null;
  }

  if (!imager)
    return (
      <div
        style={{
          width: "100%",
          height: "50px",
          background: "#222",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "#777"
        }}
      >
        GTK
      </div>
    );

  return <img src={imager} alt={alt} width="100%" />;
};
