import { ThemeUICSSObject } from "theme-ui";

export const primaryContent = (isSideBarOpen: boolean): ThemeUICSSObject => ({
  flexDirection: "column",
  flex: 1,
  padding: "0.5rem",
  paddingTop: "5.125rem",
  paddingLeft: isSideBarOpen ? ["0.75rem", "16.375rem"] : "0.5rem",
  transition: "padding-left 0.5s ease-in-out"
});
