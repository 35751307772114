import { useEffect, useState } from "react";

import {
  useTopicContent,
  useTopicContentDelete,
  useTopicContentTransparent,
  useTopicContentYoutube
} from "../../Api";
import { useEpisodeDataStore } from "../../DataStores";

const useContentHook = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setSectionLoading } = useEpisodeDataStore();

  const contentDelete = useTopicContentDelete();
  const contentTopic = useTopicContent();
  const contentTrans = useTopicContentTransparent();
  const contentYoutube = useTopicContentYoutube();

  useEffect(() => {
    const loading =
      contentDelete.isLoading ||
      contentTopic.isLoading ||
      contentTrans.isLoading ||
      contentYoutube.isLoading;
    setIsLoading(loading);
    setSectionLoading("content", loading);
  }, [
    contentDelete.isLoading,
    contentTopic.isLoading,
    contentTrans.isLoading,
    contentYoutube.isLoading,
    setSectionLoading
  ]);

  const deleteContent = (episodeId: string, topicId: string) => {
    contentDelete.mutate({ episodeId, topicId });
  };

  const addTopicContent = (episodeId: string, file: File, topicId: string) => {
    contentTopic.mutate({ episodeId, file, topicId });
  };

  const addTopicContentTrans = (episodeId: string, topicId: string) => {
    contentTrans.mutate({ episodeId, topicId });
  };

  const addTopicContentYoutube = (episodeId: string, topicId: string, videoUrl: string) => {
    contentYoutube.mutate({ episodeId, topicId, videoUrl });
  };

  const isYoutubeLink = (url: string): boolean => {
    return (
      url.trim().startsWith("https://www.youtube") || url.trim().startsWith("https://youtu.be/")
    );
  };

  return {
    isLoading,
    addTopicContent,
    addTopicContentTrans,
    addTopicContentYoutube,
    deleteContent,
    isYoutubeLink
  };
};

export default useContentHook;
