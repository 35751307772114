import React, { useEffect } from "react";
import { Grid } from "theme-ui";
import { Card, Container } from "../../../../../theme/UI";
import { useBreakpointIndex } from "@theme-ui/match-media";

import { ActiveTopic } from "./ActiveTopic";
import { ProfileTopicList } from "./TopicList";

import { useEpisodeTopicsFetch } from "../../../../../Api/useEpisodeTopics";
import { useEpisodeDataStore } from "../../../../../DataStores";
import { useEpisodeId } from "../../../../../Hooks";

export const ProfileTopics: React.FC = () => {
  const episodeId = useEpisodeId();
  const breakPointIndex = useBreakpointIndex();
  const { mutate } = useEpisodeTopicsFetch();
  const { setTopics } = useEpisodeDataStore();

  useEffect(() => {
    if (!episodeId) return;
    mutate(episodeId as string);

    return () => {
      setTopics([], -1);
    };
  }, [episodeId, mutate, setTopics]);

  return (
    <>
      <Grid columns={["1fr", "2fr 4fr"]}>
        <ProfileTopicList />

        {breakPointIndex > 1 && (
          <Card>
            <Card.Body sx={{ paddingRight: "0" }}>
              <Container marginBottom={32}>
                <ActiveTopic />
              </Container>
            </Card.Body>
          </Card>
        )}
      </Grid>
    </>
  );
};
