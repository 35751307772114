import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, Grid, Input, Select } from "theme-ui";
import { Card } from "../../../../theme/UI";
import { ISocials } from "../../../../Types";
import { CommonSocials } from "../../../../Api/Types";
import { useCreateSocial } from "../../../../Api/useSocial/useSocial";
import { useCommonDataStore } from "../../../../DataStores";

export type Options = {
	hosts: boolean;
	logo: boolean;
	news: boolean;
	socialNetworks: boolean;
	sponsors: boolean;
};

interface EpisodeNewProps {
	showHeader?: boolean;
	handleNewCallBack: (social: ISocials) => void;
	handleNewSocialClose?: () => void;
}

export const SocialNew: React.FC<EpisodeNewProps> = ({
	showHeader = true,
	handleNewCallBack,
	handleNewSocialClose
}) => {
	const { socials } = useCommonDataStore();
	const navigate = useNavigate();
	const [username, setUsername] = React.useState<string>("");
	const [site, setSite] = React.useState<string>("");

	const { data, error, isLoading, mutate } = useCreateSocial();

	React.useEffect(() => {
		socials[0]?.site && setSite(socials[0].site);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		let stillHere = true;

		if (!isLoading && data && stillHere) {
			handleNewCallBack(data);
			setUsername("");
		}

		return () => {
			stillHere = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [socials, data, error, isLoading, navigate]);

	const handleFormSubmit = async () => {
		if (!username || !setSite) {
			console.error("Error, Name and Template can not be empty");
			return;
		}

		mutate({ site, username });
	};

	return (
		<Card>
			{showHeader && (
				<>
					<Card.Header>
						<div>Add Social Media Platform</div>
					</Card.Header>
				</>
			)}

			<Card.Body>
				<Grid columns={["1fr", "3fr 2fr 80px 80px"]}>
					<Input
						type="text"
						placeholder="Username"
						value={username}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.target.value)}
					/>

					<Select
						value={site}
						onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setSite(e.target.value)}
					>
						{socials?.map((social: CommonSocials) => (
							<option key={social._id} value={social.site}>
								{social.site}
							</option>
						))}
					</Select>

					<Button disabled={isLoading || !username} onClick={handleFormSubmit}>
						<span>Submit</span>
					</Button>

					<Button onClick={handleNewSocialClose} variant="secondary">
						<span>Close</span>
					</Button>
				</Grid>
			</Card.Body>
		</Card>
	);
};
