import * as React from "react";
import { Outlet } from "react-router-dom";
import { Flex, Grid } from "theme-ui";
import Icon from "@mdi/react";
import { mdiViewDashboard, mdiFile, mdiPodcast, mdiConnection } from "@mdi/js";

import * as styles from "./LandingPage.styles";
import { Container } from "../../../theme/UI";

interface ILandingPageProps {
  children?: React.ReactElement;
  section: "connections" | "dashboard" | "documentation" | "podcast";
}

type SectionMap = {
  [key: string]: {
    title: string;
    icon: string;
  };
};

const sectionMap: SectionMap = {
  connections: {
    title: "GTK Connection and Chat Bots",
    icon: mdiConnection
  },

  dashboard: {
    title: "Dashboard & Data Visualization",
    icon: mdiViewDashboard
  },

  documentation: {
    title: "GTK Documentation",
    icon: mdiFile
  },

  podcast: {
    title: "Podcast & Talk Show Overlays",
    icon: mdiPodcast
  }
};

export const LandingPage: React.FC<ILandingPageProps> = ({ children, section }) => {
  const pageSection = sectionMap[section];

  return (
    <>
      <Container>
        <Flex sx={{ flex: 1, flexDirection: "column" }}>
          <Grid sx={styles.landingPageHeader(section)}>
            <Icon path={pageSection.icon} />
            <h3>{pageSection.title}</h3>
          </Grid>

          {children ? children : <Outlet />}
        </Flex>
      </Container>
    </>
  );
};
