export const buttonDefaults = {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  fontSize: "0.875rem",
  color: "white",
  transition: "0.3s",
  padding: "0.375rem 0.375rem",
  justifyContent: "center",

  "&:hover": {
    filter: "brightness(1.35)"
  },

  "&:disabled": {
    filter: "brightness(0.75)",
    cursor: "not-allowed"
  }
};

export const buttons = {
  primary: {
    ...buttonDefaults,
    bg: "accent3"
  },

  secondary: {
    ...buttonDefaults,
    bg: "accent1"
  },

  danger: {
    ...buttonDefaults,
    bg: "accent4"
  },

  pagination: {
    ...buttonDefaults,
    bg: "accent3",
    padding: "0.125rem 0.5rem",
    minWidth: "2rem"
  },

  loading: {
    ...buttonDefaults,
    width: "100%",
    bg: "accent3"
  },

  drawerCloseButton: {
    ...buttonDefaults,
    marginTop: "-.75rem",
    bg: "accent3",
    borderRadius: ".0 0 .5rem .5rem"
  }
};
