export const scrollbars = {
  "*": {
    "&::-webkit-scrollbar": {
      width: ["0", "0.375rem"]
    },

    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 .375rem rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 .375rem rgba(0,0,0,0.00)",
      borderRadius: ".625rem",
      backgroundColor: "background1"
    },

    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "background4",
      borderRadius: ".625rem",
      "&:hover": {
        backgroundColor: "accent3"
      }
    }
  }
};
