import React, { FC, useEffect, useState } from "react";
import { Box, Grid, Select } from "theme-ui";

import _map from "lodash/map";
import _includes from "lodash/includes";
import _filter from "lodash/filter";
import _find from "lodash/find";
import _range from "lodash/range";
import _cloneDeep from "lodash/cloneDeep";

import { Card } from "../../../../../theme/UI";
import { IEpisodeSocials, ISocials } from "../../../../../Types";

import { useFetchEpisodeSocials, useUpdateEpisodeSocials } from "../../../../../Api";
import { useEpisodeId } from "../../../../../Hooks";

export const ProfileSocials: FC = () => {
  const episodeId = useEpisodeId();
  const [availableSocials, setAvailableSocials] = useState<ISocials[]>([]);
  const [episodeSocials, setEpisodeSocials] = useState<IEpisodeSocials[]>([]);
  const { data, isLoading } = useFetchEpisodeSocials(episodeId);
  const { data: updateData, isLoading: updateIsLoading, mutate } = useUpdateEpisodeSocials();

  useEffect(() => {
    if (data && !isLoading) {
      setAvailableSocials(data.availableSocials);
      setEpisodeSocials(data.episodeSocials);
    }
  }, [data, isLoading]);

  useEffect(() => {
    if (updateData && !updateIsLoading) {
      setEpisodeSocials(updateData.episodeSocials);
    }
  }, [updateData, updateIsLoading]);

  const maxTotalSocials = 10;
  let maxSocials = episodeSocials?.length + 1 || 1;

  maxSocials = maxSocials > maxTotalSocials ? maxTotalSocials : maxSocials;
  const socialCountArray = _range(1, maxSocials + 1);

  const handleDisable = (order: number): boolean => {
    if (order === 1 || episodeSocials.find(f => f.order === order - 1)) return false;

    return true;
  };

  const filterSocials = (_id: string | undefined): ISocials[] => {
    const seatedSocial = episodeSocials?.filter(f => f.socialId !== _id);
    const exceptions: string[] = [];
    _map(seatedSocial, m => exceptions.push(m.socialId));
    return _filter(availableSocials, f => !_includes(exceptions, f._id));
  };

  const handleOnchange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();

    const { name: order, value } = e.target;
    let socialNetworks = _cloneDeep(episodeSocials);

    if (value !== "none") {
      socialNetworks = socialNetworks.filter(f => f.order !== Number(order));
      socialNetworks.push({ order: Number(order), socialId: value });
    }

    if (value === "none") {
      socialNetworks = socialNetworks.filter(f => f.order !== Number(order));

      socialNetworks = socialNetworks.map(social => {
        if (social.order > Number(order)) {
          social.order = social.order - 1;
        }
        return social;
      });
    }

    mutate({ episodeId: String(episodeId), episodeSocials: socialNetworks });
  };

  if (isLoading) return null;

  return (
    <>
      <Card variant="profileFullPage">
        <Card.Header>
          <Box>Socials {`(${episodeSocials?.length || 0}/${maxTotalSocials})`}</Box>
        </Card.Header>

        <Card.Body>
          <Grid variant="table">
            {_map(socialCountArray, (order: number, index: number) => {
              const value = _find(episodeSocials, f => f.order === order)?.socialId || "none";

              return (
                <Grid key={index} variant="defaultGridList">
                  <Box variant="grids.listDefaultIcon">{index + 1}</Box>
                  <Select
                    disabled={handleDisable(order)}
                    name={String(order)}
                    onChange={e => handleOnchange(e)}
                    value={value}
                  >
                    <option value="none">None</option>

                    {_map(
                      filterSocials(_find(episodeSocials, f => f.order === order)?.socialId),
                      social => (
                        <option value={social._id} key={social._id}>
                          {social.site}: {social.username}
                        </option>
                      )
                    )}
                  </Select>
                </Grid>
              );
            })}
          </Grid>
        </Card.Body>
      </Card>
    </>
  );
};
