import React from "react";

import { Button, Grid, Input, Spinner } from "theme-ui";
import { Card } from "../../../../../../theme/UI";
import { generateID } from "../../../../../../Utils";
import { useCreateEpisodeNews } from "../../../../../../Api/useEpisodeSegments/episodeNews";

export type Options = {
  hosts: boolean;
  logo: boolean;
  news: boolean;
  socialNetworks: boolean;
  sponsors: boolean;
};

interface EpisodeNewProps {
  showHeader?: boolean;
  handleNewCallBack: (ticker: any) => void;
  handleNewClose?: () => void;
  profileId: string;
}

export const NewsTickerNew: React.FC<EpisodeNewProps> = ({
  showHeader = true,
  handleNewCallBack,
  handleNewClose,
  profileId
}) => {
  const [newsTickerText, setNewsTickerText] = React.useState<string>("");

  const { data, isLoading, mutate } = useCreateEpisodeNews(profileId);

  React.useEffect(() => {
    if (data && !isLoading) {
      handleNewCallBack(data.news);
      setNewsTickerText("");
    }
  }, [data, handleNewCallBack, isLoading]);

  const handleFormSubmit = async () => {
    if (!newsTickerText) {
      console.error("Error, Name and Template can not be empty");
      return;
    }
    mutate({
      _id: generateID() as string,
      title: "",
      text: newsTickerText
    });
  };

  return (
    <Card>
      {showHeader && (
        <>
          <Card.Header>
            <div>Add News Ticker Text</div>
            <Spinner
              variant="styles.spinner"
              sx={{ size: 32, display: isLoading ? "block" : "none" }}
            />
          </Card.Header>
        </>
      )}

      <Card.Body>
        <Grid columns={["1fr", "1fr 80px 80px"]}>
          <Input
            type="text"
            placeholder="News Ticker Text"
            value={newsTickerText}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewsTickerText(e.target.value)}
          />

          <Button disabled={!newsTickerText} onClick={handleFormSubmit}>
            <span>Add</span>
          </Button>

          <Button onClick={handleNewClose}>
            <span>Close</span>
          </Button>
        </Grid>
      </Card.Body>
    </Card>
  );
};
