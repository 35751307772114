import { AxiosError } from "axios";
import { useQuery, useMutation } from "react-query";
import httpService from "../httpService";
import { ApiResponse } from "../Types";
import { ITemplateImages, SponsorImages } from "../../Types";
import { ImageType } from "../../Components/PodcastOverlays/Episodes/Profile/Images/ImageTypes";

type EpisodeImagesFetchResponse = {
  logo: string;
  sponsorImages: SponsorImages[];
  templateImages: ITemplateImages;
};

const fetchEpisodeImages = async (episodeId: string): Promise<EpisodeImagesFetchResponse> => {
  const { data } = await httpService.get<ApiResponse<EpisodeImagesFetchResponse>>(
    `episode-segments/imaging/${episodeId}`
  );

  return data.result;
};

export const useFetchEpisodeImages = (episodeId?: string | undefined) => {
  return useQuery<EpisodeImagesFetchResponse, AxiosError<{ error: unknown }>>(
    ["episodeInfoFetch", episodeId],
    () => fetchEpisodeImages(episodeId as string),
    {
      retry: 0,
      enabled: !!episodeId
    }
  );
};

type EpisodeImagesUploadVariables = {
  episodeId: string;
  file: File;
  imageType: ImageType;
};

type EpisodeImagesUploadResponse = {
  episodeId: string;
  images: string | SponsorImages[];
  imageType: ImageType;
  sponsorImages: SponsorImages[] | null;
};

const uploadEpisodeImages = async (episodeObj: EpisodeImagesUploadVariables) => {
  const formData = new FormData();

  (Object.keys(episodeObj) as (keyof EpisodeImagesUploadVariables)[]).forEach(key => {
    formData.append(key, episodeObj[key]);
  });

  const { data } = await httpService.post<ApiResponse<EpisodeImagesUploadResponse>>(
    `episode-segments/imaging/${episodeObj.episodeId}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );

  return data.result;
};

export const useUploadEpisodeImages = () => {
  return useMutation<
    EpisodeImagesUploadResponse,
    AxiosError<{ error: unknown }>,
    EpisodeImagesUploadVariables
  >(episodeObj => uploadEpisodeImages(episodeObj), {
    retry: 0
  });
};

type EpisodeImagesDeleteVariables = {
  episodeId: string;
  imageId?: string;
  imageType: ImageType;
};

type EpisodeImagesDeleteResponse = {
  episodeId: string;
  images: string | SponsorImages[];
  imageType: ImageType;
  sponsorImages: SponsorImages[] | null;
};

const deleteEpisodeImage = async (imageObj: EpisodeImagesDeleteVariables) => {
  const { data } = await httpService.put<ApiResponse<EpisodeImagesDeleteResponse>>(
    `episode-segments/imaging/${imageObj.episodeId}?_=${new Date().getTime()}`,
    imageObj
  );

  return data.result;
};

export const useDeleteEpisodeImages = () => {
  return useMutation<
    EpisodeImagesDeleteResponse,
    AxiosError<{ error: unknown }>,
    EpisodeImagesDeleteVariables
  >(episodeObj => deleteEpisodeImage(episodeObj), {
    retry: 0
  });
};
