import { AxiosError } from "axios";
import { useMutation } from "react-query";
import httpService from "../httpService";
import {
  SocialSearchResponse,
  SocialSearchVariables,
  SocialCreationVariables,
  SocialCreationResponse,
  SocialEditVariables,
  SocialEditResponse,
  ApiResponse
} from "../Types";

const searchSocials = async (searchObj: SocialSearchVariables) => {
  const { data } = await httpService.get<ApiResponse<SocialSearchResponse>>(
    `podcast-socials/${searchObj.page}/${searchObj.sort}/${searchObj.order}/?st=${
      searchObj.searchTerm || ""
    }&site=${searchObj.site || ""}`
  );
  return data.result;
};

export const useSearchSocials = () => {
  return useMutation<SocialSearchResponse, AxiosError<{ error: unknown }>, SocialSearchVariables>(
    ["socials"],
    searchObj => searchSocials(searchObj),
    {
      retry: 0
    }
  );
};

const createSocial = async (socialObj: SocialCreationVariables) => {
  const { data } = await httpService.post<ApiResponse<SocialCreationResponse>>(
    `podcast-socials`,
    socialObj
  );

  return data.result;
};

export const useCreateSocial = () => {
  return useMutation<
    SocialCreationResponse,
    AxiosError<{ error: unknown }>,
    SocialCreationVariables
  >(["socialCreate"], socialObj => createSocial(socialObj), {
    retry: 0
  });
};

const editSocial = async (socialObj: SocialEditVariables) => {
  const { data } = await httpService.put<SocialEditResponse>(
    `podcast-socials/${socialObj._id}`,
    socialObj
  );

  return data;
};

export const useEditSocial = () => {
  return useMutation<SocialEditResponse, AxiosError<{ error: unknown }>, SocialEditVariables>(
    ["socialEdit"],
    socialObj => editSocial(socialObj),
    {
      retry: 0
    }
  );
};
