import jwtDecode from "jwt-decode";

const getUserId = () => {
	if (!process.env.REACT_APP_JWT_TOKEN) return null;

	const { _id: userId } = jwtDecode(
		localStorage.getItem(process.env.REACT_APP_JWT_TOKEN) || ""
	) as { _id: string };

	return userId;
};

export const openPreview = (
	type: "episode" | "current",
	templateId: string,
	episodeId: string | null,
	linkParams: any,
	open: boolean = false
) => {
	if (!process.env.REACT_APP_JWT_TOKEN) return null;

	const userId = getUserId();
	const linkPrefix = `${process.env.REACT_APP_CLOUD_OVERLAY_BASE}?uid=${userId}&tid=${templateId}`;
	const linkSuffix = type === "episode" ? `&eid=${episodeId}` : "";

	const previewLinkBase = linkPrefix + linkSuffix;

	const linkParameters = Object.keys(linkParams || {})
		.map(key => {
			return linkParams?.[key] ? `${key}=1` : null;
		})
		.filter(Boolean)
		.join("&");

	if (open) {
		window.open(`${previewLinkBase}&${linkParameters}`, "_blank", "noreferrer");
		return null;
	} else {
		return `${previewLinkBase}&${linkParameters}`;
	}
};
