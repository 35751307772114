import React from "react";
import { Box, Button } from "theme-ui";
import Icon from "@mdi/react";
import { mdiCloseCircle } from "@mdi/js";

interface IDrawerProps {
  children: React.ReactNode;
  handleCloseDrawer?: () => void;
  isDrawerOpen: boolean;
  variant?: string;
  width?: string;
  title?: string;
}

export const Drawer: React.FC<IDrawerProps> = ({
  children,
  handleCloseDrawer,
  isDrawerOpen,
  variant = "drawer.base",
  width = "100vw",
  title
}) => {
  return (
    <>
      <Box
        sx={{ right: isDrawerOpen ? "0px" : `-${width}`, width }}
        variant={variant}
      >
        {handleCloseDrawer && (
          <Box variant="drawer.header">
            <div>{title}</div>

            <Button variant="drawerCloseButton" onClick={handleCloseDrawer}>
              <Icon path={mdiCloseCircle} size={1} />
            </Button>
          </Box>
        )}

        <Box sx={{ height: "100%", overflowY: "auto", position: "relative" }}>
          {children}
        </Box>
      </Box>
    </>
  );
};
