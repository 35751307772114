import { AxiosError } from "axios";
import { useQuery } from "react-query";
import httpService from "../httpService";
import { ApiEndPoints, ApiResponse, CommonResponse } from "../Types";
import { useCommonDataStore } from "../../DataStores";

const fetchAppData = async (): Promise<CommonResponse> => {
	const { data } = await httpService.get<ApiResponse<CommonResponse>>(ApiEndPoints.AppData);
	return data.result;
};

export const useFetchAppData = () => {
	const { hydrateData } = useCommonDataStore();
	return useQuery<CommonResponse, AxiosError<{ error: unknown }>>(
		["application-data"],
		() => fetchAppData(),
		{
			enabled: true,
			retry: 0,
			refetchOnWindowFocus: false,
			staleTime: Infinity,
			onSuccess: data => {
				hydrateData(data.socials, data.templates);
			}
		}
	);
};
