import { AxiosError } from "axios";
import { useMutation } from "react-query";
import httpService from "../httpService";
import { ApiResponse } from "../Types";
import { useEpisodeDataStore } from "../../DataStores";
import { useToaster } from "../../Hooks";
import { IEpisodeTopic } from "../../Types";

type EpisodeTopicReorderVariables = { episodeId: string; topics: IEpisodeTopic[] };
type EpisodeTopicReorderResponse = { topics: IEpisodeTopic[] };

const reorderEpisodeTopic = async (
  reorderTopicObj: EpisodeTopicReorderVariables
): Promise<EpisodeTopicReorderResponse> => {
  const { data } = await httpService.put<ApiResponse<EpisodeTopicReorderResponse>>(
    `/episode-topics/reorder/${reorderTopicObj.episodeId}`,
    { topics: reorderTopicObj.topics }
  );

  return data.result;
};

export const useEpisodeTopicReorder = () => {
  const { toaster } = useToaster();
  const { setSectionLoading, setTopics } = useEpisodeDataStore();
  return useMutation<
    EpisodeTopicReorderResponse,
    AxiosError<{ error: unknown }>,
    EpisodeTopicReorderVariables
  >(["reorder-episode-topic"], reorderTopicObj => reorderEpisodeTopic(reorderTopicObj), {
    retry: 0,
    onMutate: () => {
      setSectionLoading("topic", true);
    },
    onSuccess: data => {
      if (data) {
        toaster("Topic Order Updated!");
        setTopics(data.topics, null);
      }
    },
    onSettled: () => {
      setSectionLoading("topic", false);
    }
  });
};
