import { AxiosError } from "axios";
import { useQuery, useMutation } from "react-query";
import httpService from "../httpService";
import {
  EpisodeNewsCreationResponse,
  EpisodeNewsCreationVariables,
  EpisodeNewsUpdateResponse,
  EpisodeNewsUpdateVariables,
  EpisodeNewsDeleteResponse,
  EpisodeNewsDeleteVariables,
  EpisodeNewsReOrderResponse,
  EpisodeNewsReOrderVariables,
  EpisodeNewsFetchResponse,
  ApiResponse
} from "../Types";

const fetchEpisodeNews = async (episodeId: string): Promise<EpisodeNewsFetchResponse> => {
  const { data } = await httpService.get<ApiResponse<EpisodeNewsFetchResponse>>(
    `episode-segments/news/${episodeId}`
  );

  return data.result;
};

export const useFetchEpisodeNews = (episodeId?: string | undefined) => {
  return useQuery<EpisodeNewsFetchResponse, AxiosError<{ error: unknown }>>(
    ["episode-news-fetch", episodeId],
    () => fetchEpisodeNews(episodeId as string),
    {
      retry: 0,
      enabled: !!episodeId
    }
  );
};

const createEpisodeNews = async (
  _id: string,
  episodeNewsObj: EpisodeNewsCreationVariables
): Promise<EpisodeNewsCreationResponse> => {
  const { data } = await httpService.post<ApiResponse<EpisodeNewsCreationResponse>>(
    `episode-segments/news/${_id}`,
    episodeNewsObj
  );

  return data.result;
};

export const useCreateEpisodeNews = (_id: string) => {
  return useMutation<
    EpisodeNewsCreationResponse,
    AxiosError<{ error: unknown }>,
    EpisodeNewsCreationVariables
  >(["episode-news-create"], episodeObj => createEpisodeNews(_id, episodeObj), {
    retry: 0
  });
};

const updateEpisodeNews = async (
  episodeNewsObj: EpisodeNewsUpdateVariables
): Promise<EpisodeNewsUpdateResponse> => {
  const { data } = await httpService.put<ApiResponse<EpisodeNewsUpdateResponse>>(
    `episode-segments/news/${episodeNewsObj.episodeId}`,
    episodeNewsObj.activeItem
  );

  return data.result;
};

export const useUpdateEpisodeNews = () => {
  return useMutation<
    EpisodeNewsUpdateResponse,
    AxiosError<{ error: unknown }>,
    EpisodeNewsUpdateVariables
  >(["episode-news-update"], episodeObj => updateEpisodeNews(episodeObj), {
    retry: 0
  });
};

const orderEpisodeNews = async (episodeNewsObj: EpisodeNewsReOrderVariables) => {
  const { data } = await httpService.patch<ApiResponse<EpisodeNewsReOrderResponse>>(
    `episode-segments/news/${episodeNewsObj.episodeId}`,
    { news: episodeNewsObj.news }
  );

  return data.result;
};

export const useOrderEpisodeNews = () => {
  return useMutation<
    EpisodeNewsReOrderResponse,
    AxiosError<{ error: unknown }>,
    EpisodeNewsReOrderVariables
  >(["episodeNewsOrder"], episodeNewsObj => orderEpisodeNews(episodeNewsObj), {
    retry: 0
  });
};

const deleteEpisodeNews = async (
  episodeNewsObj: EpisodeNewsDeleteVariables
): Promise<EpisodeNewsDeleteResponse> => {
  const { data } = await httpService.delete<ApiResponse<EpisodeNewsDeleteResponse>>(
    `episode-segments/news/${episodeNewsObj.episodeId}/${episodeNewsObj.newsItemId}`
  );

  return data.result;
};

export const useDeleteEpisodeNews = () => {
  return useMutation<
    EpisodeNewsDeleteResponse,
    AxiosError<{ error: unknown }>,
    EpisodeNewsDeleteVariables
  >(["episodeNewsDelete"], episodeNewsObj => deleteEpisodeNews(episodeNewsObj), {
    retry: 0
  });
};
