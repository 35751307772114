import { ThemeUICSSObject } from "theme-ui";

type ColorMap = {
  [key: string]: {
    borderColor: string;
    svgColor: string;
  };
};

const colorMap: ColorMap = {
  connections: {
    borderColor: "accent3",
    svgColor: "accent4"
  },

  dashboard: {
    borderColor: "accent3",
    svgColor: "accent3"
  },

  documentation: {
    borderColor: "accent3",
    svgColor: "accent1"
  },

  podcast: {
    borderColor: "accent3",
    svgColor: "accent5"
  }
};

export const landingPageHeader = (section: string): ThemeUICSSObject => ({
  alignItems: "center",
  bg: "background1",
  borderLeft: "thick",
  borderLeftColor: colorMap[section].borderColor,
  borderRadius: "right",
  color: "text4",
  fontFamily: "heading",
  fontSize: 3,
  gridTemplateColumns: ["1.5rem 1fr"],
  height: "auto",
  justifyContent: "center",
  mb: 3,
  padding: 2,

  ">svg": {
    color: colorMap[section].svgColor
  }
});
