import { AxiosError } from "axios";
import { useMutation } from "react-query";
import httpService from "../httpService";
import { ApiResponse } from "../Types";
import { useEpisodeDataStore } from "../../DataStores";
import { useToaster } from "../../Hooks";
import { IEpisodeTopic } from "../../Types";

type EpisodeTopicNewResponse = { activeIndex: number; topics: IEpisodeTopic[] };

const newEpisodeTopic = async (episodeId: string): Promise<EpisodeTopicNewResponse> => {
  const { data } = await httpService.post<ApiResponse<EpisodeTopicNewResponse>>(
    `/episode-topics/${episodeId}`
  );

  return data.result;
};

export const useEpisodeTopicNew = () => {
  const { toaster } = useToaster();
  const { setSectionLoading, setTopics } = useEpisodeDataStore();
  return useMutation<EpisodeTopicNewResponse, AxiosError<{ error: unknown }>, string>(
    ["create-episode-topic"],
    episodeId => newEpisodeTopic(episodeId),
    {
      retry: 0,
      onMutate: () => {
        setSectionLoading("topic", true);
      },
      onSuccess: data => {
        if (data) {
          toaster("Topic Created!");
          setTopics(data.topics, data.activeIndex);
        }
      },
      onSettled: () => {
        setSectionLoading("topic", false);
      }
    }
  );
};
