import React from "react";
import { Box, Button, Grid } from "theme-ui";
import { Card, Collapse } from "../../../theme/UI";

type DeleteProps = {
  deleteMethod: () => void;
  isColapsed: boolean;
  isVisible?: boolean;
  toggleColapsed: () => void;
};

export const ConfirmDelete: React.FC<DeleteProps> = ({
  deleteMethod,
  isColapsed,
  isVisible = true,
  toggleColapsed
}) => {
  if (!isVisible) return null;

  return (
    <>
      <Card.Body
        sx={{
          m: "1rem",
          mb: "4rem",
          border: "1px dashed",
          borderColor: isColapsed ? "accent6" : "accent4",
          paddingTop: "1rem"
        }}
      >
        <Button onClick={toggleColapsed} sx={{ width: ["100%"] }} variant="danger">
          Delete Item
        </Button>

        <Collapse isOpen={!isColapsed} marginBottom="0">
          <Box mt={3}>
            Are you sure you want to delete this item? <br />
            This action can not be undone.
          </Box>
          <Grid columns={[1, 2]} mt={3}>
            <Button
              onClick={toggleColapsed}
              variant="secondary"
              sx={{
                width: ["100%"]
              }}
            >
              Nope! Keep this item.
            </Button>
            <Button onClick={deleteMethod} variant="danger" sx={{ width: ["100%"] }}>
              Yes, Delete this item.
            </Button>
          </Grid>
        </Collapse>
      </Card.Body>
    </>
  );
};
