import { AxiosError } from "axios";
import { useMutation } from "react-query";
import httpService from "../httpService";
import { ApiResponse, EpisodeTopicResponse } from "../Types";
import { useCommonDataStore, useEpisodeDataStore } from "../../DataStores";

const fetchEpisodeTopics = async (episodeId: string): Promise<EpisodeTopicResponse> => {
  const { data } = await httpService.get<ApiResponse<EpisodeTopicResponse>>(
    `episode-topics/${episodeId}`
  );

  return data.result;
};

export const useEpisodeTopicsFetch = () => {
  const useEpisodeData = useEpisodeDataStore();
  const { templates } = useCommonDataStore();

  return useMutation<EpisodeTopicResponse, AxiosError<{ error: unknown }>, string>(
    ["fetch-episode-topics"],
    episodeId => fetchEpisodeTopics(episodeId),
    {
      retry: 0,
      onMutate: () => {
        useEpisodeData.setSectionLoading("episode-list", true);
      },
      onSuccess: data => {
        if (data) {
          useEpisodeDataStore.setState({
            activeTopic: data.activeTopic,
            topics: data.topics,
            topicImageDimensions: data.images,
            templateId: data.templateId,
            templateInfo: templates.find(item => item._id === data.templateId)
          });
        }
      },
      onSettled: () => {
        useEpisodeData.setSectionLoading("episode-list", false);
      }
    }
  );
};
