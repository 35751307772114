import * as React from "react";

import { Card } from "../../theme/UI";
import { Box } from "theme-ui";

export const ComingSoon: React.FC = () => {
  return (
    <>
      <Card>
        <Card.Body>
          <Box mb={2}>Coming Soon</Box>
        </Card.Body>
      </Card>
    </>
  );
};
