import { AxiosError } from "axios";
import { useQuery, useMutation } from "react-query";
import httpService from "../httpService";
import { ApiResponse } from "../Types";
import { IEpisodeSocials } from "./../../Types/Podcast/Episodes";
import { ISocials } from "../../Types";

type EpisodeSocialsFetchResponse = {
  availableSocials: ISocials[];
  episodeSocials: IEpisodeSocials[];
};

type EpisodeSocialsUpdateVariables = {
  episodeId: string;
  episodeSocials: IEpisodeSocials[];
};

type EpisodeSocialsUpdateResponse = {
  episodeId: string;
  episodeSocials: IEpisodeSocials[];
};

const fetchEpisodeSocials = async (episodeId: string): Promise<EpisodeSocialsFetchResponse> => {
  const { data } = await httpService.get<ApiResponse<EpisodeSocialsFetchResponse>>(
    `episode-segments/socials/${episodeId}`
  );

  return data.result;
};

export const useFetchEpisodeSocials = (episodeId?: string | undefined) => {
  return useQuery<EpisodeSocialsFetchResponse, AxiosError<{ error: unknown }>>(
    ["episode-news-fetch", episodeId],
    () => fetchEpisodeSocials(episodeId as string),
    {
      retry: 0,
      enabled: !!episodeId
    }
  );
};

const updateEpisodeSocials = async (
  socialsObj: EpisodeSocialsUpdateVariables
): Promise<EpisodeSocialsUpdateResponse> => {
  const { data } = await httpService.put<ApiResponse<EpisodeSocialsUpdateResponse>>(
    `episode-segments/socials/${socialsObj.episodeId}`,
    { episodeSocials: socialsObj.episodeSocials }
  );

  return data.result;
};

export const useUpdateEpisodeSocials = () => {
  return useMutation<
    EpisodeSocialsUpdateResponse,
    AxiosError<{ error: unknown }>,
    EpisodeSocialsUpdateVariables
  >(["episode-socials-update"], socialsObj => updateEpisodeSocials(socialsObj), {
    retry: 0
  });
};
