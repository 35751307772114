import { AxiosError } from "axios";
import { useMutation } from "react-query";
import httpService from "../httpService";
import { ApiResponse } from "../Types";
import { useEpisodeDataStore } from "../../DataStores";
import { useToaster } from "../../Hooks";
import { IEpisodeTopic } from "../../Types";

type EpisodeTopicDeleteVariables = { episodeId: string; topicId: string };
type EpisodeTopicDeleteResponse = { activeIndex: number; topics: IEpisodeTopic[] };

const deleteEpisodeTopic = async (
  deleteTopicObj: EpisodeTopicDeleteVariables
): Promise<EpisodeTopicDeleteResponse> => {
  const { data } = await httpService.delete<ApiResponse<EpisodeTopicDeleteResponse>>(
    `/episode-topics/${deleteTopicObj.episodeId}/${deleteTopicObj.topicId}`
  );

  return data.result;
};

export const useEpisodeTopicDelete = () => {
  const { toaster } = useToaster();
  const { setSectionLoading, setTopics } = useEpisodeDataStore();
  return useMutation<
    EpisodeTopicDeleteResponse,
    AxiosError<{ error: unknown }>,
    EpisodeTopicDeleteVariables
  >(["delete-episode-topic"], deleteTopicObj => deleteEpisodeTopic(deleteTopicObj), {
    retry: 0,
    onMutate: () => {
      setSectionLoading("topic", true);
    },
    onSuccess: data => {
      if (data) {
        toaster("Topic deleted...");
        setTopics(data.topics, data.activeIndex);
      }
    },
    onSettled: () => {
      setSectionLoading("topic", false);
    }
  });
};
