import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Grid, Input, Select } from "theme-ui";

import Icon from "@mdi/react";

import { AppRoutes } from "../../../../Types";

import { Pagination } from "./Pagination";
import { Card } from "../../../../theme/UI";
import { useSearchSocials } from "../../../../Api/useSocial/useSocial";
import { CommonSocials } from "../../../../Api/Types";
import { ISocials } from "../../../../Types";
import { socialIconsMap } from "./socialIconMap";
import { useCommonDataStore } from "../../../../DataStores";

export type SocialListState = {
  currentPage: string | number;
  socials: ISocials[];
  totalPages: number;
  totalDocuments: number;
};

export interface ISocialListProps {
  handleNewSocialOpen?: () => void;
  handleOpenDrawer: () => void;
  listState: SocialListState | null;
  setActiveSocial: React.Dispatch<React.SetStateAction<ISocials | undefined>>;
  setListState: React.Dispatch<React.SetStateAction<SocialListState | null>>;
}

export const SocialList: React.FC<ISocialListProps> = ({
  handleOpenDrawer,
  setActiveSocial,
  handleNewSocialOpen,
  listState,
  setListState
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [sort, setSort] = React.useState<string>("name");
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [sortDirection, setSortDirection] = React.useState<string>("asc");
  const [site, setSite] = React.useState<string>("");

  const { socials } = useCommonDataStore();
  const { data, error, isLoading, mutate } = useSearchSocials();

  React.useEffect(() => {
    const fetchDir = queryParams.get("dir") || "asc";
    const fetchSort = queryParams.get("sort") || "name";
    const fetchSearchTerm = queryParams.get("searchTerm") || "";
    const fetchSite = queryParams.get("site") || "";

    setSortDirection(fetchDir);
    setSort(fetchSort);
    setSearchTerm(fetchSearchTerm);
    setSite(fetchSite);

    mutate({
      order: fetchDir,
      page: (queryParams.get("page") as unknown as number) || 1,
      sort: fetchSort,
      searchTerm: fetchSearchTerm,
      site: fetchSite
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isLoading && !error) return;
    data && setListState(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, error]);

  const handlePageChange = async (page: number) => {
    mutate({
      order: sortDirection,
      page,
      sort,
      searchTerm,
      site
    });

    let navLink = `${AppRoutes.SocialList}?page=${page}&sort=${sort}&dir=${sortDirection}`;
    navLink += searchTerm && `&searchTerm=${searchTerm}`;
    navLink += site && `&site=${site}`;
    navigate(navLink);
  };

  const handleFormSubmit = async () => {
    handlePageChange(1);
    setActiveSocial(undefined);
  };

  const handleSocialSelect = (social: ISocials) => {
    setActiveSocial(social);
    handleOpenDrawer();
  };

  return (
    <>
      <Card variant="fullPage">
        <Card.Header>
          <Box>Social Media Listing</Box>

          <Button onClick={handleNewSocialOpen}>Add Social Media</Button>
        </Card.Header>

        <Card.HeaderForm>
          <Box>
            <Input
              type="text"
              placeholder="Search Username"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
            <Select value={site} onChange={e => setSite(e.target.value)}>
              <option value="">All Socials</option>
              {socials.map((social: CommonSocials) => (
                <option key={social._id} value={social.site}>
                  {social.site}
                </option>
              ))}
            </Select>

            <Button onClick={handleFormSubmit}>Search</Button>
          </Box>
        </Card.HeaderForm>

        <Card.Body>
          <Grid variant="socialTable">
            {listState?.socials.map((social: ISocials) => (
              <Grid
                variant="socialList"
                key={social._id}
                onClick={() => handleSocialSelect(social)}
              >
                <Box sx={{ backgroundColor: socialIconsMap[social.site].color }}>
                  <Icon path={socialIconsMap[social.site].icon} size={1} />
                </Box>

                <div>
                  <div>{social.username}</div>
                  <div>{social.site}</div>
                </div>
              </Grid>
            ))}
          </Grid>
          <Grid mt={".5rem"}>
            <Pagination
              currentPage={(listState?.currentPage as number) || 0}
              handlePageChange={handlePageChange}
              totalPages={listState?.totalPages || 0}
            />
          </Grid>
        </Card.Body>
      </Card>
    </>
  );
};
