import React from "react";
import { useBreakpointIndex } from "@theme-ui/match-media";
import _cloneDeep from "lodash/cloneDeep";
import { Grid } from "theme-ui";
import { Collapse, Drawer } from "../../../theme/UI";

import { SocialList, SocialListState } from "./SocailList/SocialList";
import { SocialNew } from "./SocialNew/SocialNew";
import { SocialEdit } from "./SocialEdit/SocialEdit";
import { ISocials } from "../../../Types";

export const Socials: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);
  const [isNewSocialOpen, setIsNewSocialOpen] = React.useState<boolean>(false);
  const [activeSocial, setActiveSocial] = React.useState<ISocials>();
  const breakPointIndex = useBreakpointIndex();
  const columns = ["1fr", "1fr", "2fr 1fr"];

  // Searching ***********************************************
  const [listState, setListState] = React.useState<SocialListState | null>(null);

  const handleEditCallBack = (social: ISocials) => {
    if (!listState || !social) return;
    const newListState = _cloneDeep(listState);

    const socialIndex = newListState?.socials.findIndex(s => s._id === social._id);

    if (socialIndex === -1) return;
    newListState.socials[socialIndex] = social;
    setListState(newListState);
  };
  // Searching ***********************************************

  const handleNewCallBack = (social: ISocials) => {
    if (!listState || !social) return;
    const newListState = _cloneDeep(listState);
    newListState.socials.unshift(social);
    setListState(newListState);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleNewSocialToggle = () => {
    setIsNewSocialOpen(!isNewSocialOpen);
  };

  const handleDeleteCallBack = (id: string) => {
    if (!listState) return;
    const newState = structuredClone(listState);
    newState.socials = newState.socials.filter((social: ISocials) => social._id !== id);
    setListState(newState);
    setActiveSocial(undefined);
  };

  return (
    <>
      <Collapse isOpen={isNewSocialOpen} sx={{ gridColumn: ["span 1", "span 2"] }}>
        <SocialNew
          handleNewCallBack={handleNewCallBack}
          handleNewSocialClose={handleNewSocialToggle}
        />
      </Collapse>

      <Grid columns={columns}>
        <SocialList
          handleOpenDrawer={handleOpenDrawer}
          handleNewSocialOpen={handleNewSocialToggle}
          listState={listState}
          setListState={setListState}
          setActiveSocial={setActiveSocial}
        />

        {breakPointIndex > 1 && (
          <SocialEdit
            activeSocial={activeSocial}
            handleEditCallBack={handleEditCallBack}
            handleDeleteCallBack={handleDeleteCallBack}
          />
        )}
      </Grid>

      {breakPointIndex <= 1 && (
        <Drawer
          isDrawerOpen={isDrawerOpen}
          handleCloseDrawer={handleCloseDrawer}
          title="Social Media Edit"
        >
          <SocialEdit
            activeSocial={activeSocial}
            handleCloseDrawer={handleCloseDrawer}
            handleEditCallBack={handleEditCallBack}
            showHeader={false}
            handleDeleteCallBack={handleDeleteCallBack}
          />
        </Drawer>
      )}
    </>
  );
};
