import { create, StoreApi } from "zustand";
import { IEpisodeTopic, ITemplate, ITemplateImagesDefault, LoaderSections } from "../Types";
import { ContentUpdateResponse } from "../Api";

export interface EpisodeDataStore {
  activeTopic: IEpisodeTopic | null;
  activeTopicEdits: IEpisodeTopic | null;
  isTopicDrawerOpen: boolean;
  topics: IEpisodeTopic[];
  topicImageDimensions: ITemplateImagesDefault | null;
  templateId: string;
  templateInfo: ITemplate | null;
  isLoading: string[];

  activateItem: (topicId: string) => void;
  isButtonDisabled: () => boolean;
  isSectionLoading: (section: LoaderSections) => boolean;
  setSectionLoading: (section: LoaderSections, add?: boolean) => void;
  setTopics: (topics: IEpisodeTopic[], activeIndex?: number | null) => void;
  toggleTopicDrawer: (status: boolean) => void;
  updateActiveTopic: (updateObj: Partial<IEpisodeTopic>) => void;

  handleTopicContentUpdate: (data: ContentUpdateResponse) => void;
}

export const useEpisodeDataStore = create<EpisodeDataStore>(
  (set: StoreApi<EpisodeDataStore>["setState"], get: StoreApi<EpisodeDataStore>["getState"]) => ({
    activeTopic: null,
    activeTopicEdits: null,
    isTopicDrawerOpen: false,
    topics: [],
    topicImageDimensions: null,
    templateId: "",
    templateInfo: null,
    isLoading: [],

    activateItem: topicId => {
      const activeTopic = get().topics.find(topic => topic._id === topicId);
      set({ activeTopic: activeTopic, isTopicDrawerOpen: true });
    },

    isButtonDisabled: () => get().isLoading.length > 0,

    isSectionLoading: section => get().isLoading.includes(section),

    setSectionLoading: (section, add = true) => {
      if (add) {
        set({ isLoading: [...get().isLoading, section] });
      } else {
        set({ isLoading: get().isLoading.filter(item => item !== section) });
      }
    },

    setTopics: (topics, activeIndex = 0) => {
      activeIndex !== null ? set({ topics, activeTopic: topics[activeIndex] }) : set({ topics });
    },

    toggleTopicDrawer: status => {
      set({ isTopicDrawerOpen: status });
    },

    updateActiveTopic: updateObj => {
      const activeTopic = get().activeTopic;
      if (activeTopic) {
        set({ activeTopic: { ...activeTopic, ...updateObj } });
      }
    },

    handleTopicContentUpdate: ({ topics, type, updatedTopicId, url }) => {
      const { activeTopic } = get();
      set({ topics });

      if (activeTopic?._id === updatedTopicId) {
        set({ activeTopic: { ...activeTopic, content: { type, file: url } } });
      }
    }
  })
);
