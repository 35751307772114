import axios from "axios";
import jwtDecode from "jwt-decode";
import React from "react";
import { Button, Flex } from "theme-ui";

export const PreviewControls: React.FC = () => {
  const [userId, setUserId] = React.useState<string>("");

  React.useEffect(() => {
    if (process.env.REACT_APP_JWT_TOKEN) {
      const { _id } = jwtDecode(localStorage.getItem(process.env.REACT_APP_JWT_TOKEN) || "") as {
        _id: string;
      };

      setUserId(_id);
    }
  }, []);

  const handleNextTopic = async (action: string): Promise<void> => {
    try {
      axios.get(
        `${process.env.REACT_APP_REST_API}socket/manual/gtkOverlayAction/?uid=${userId}&action=${action}`
      );
    } catch (error) {
      console.error(error);
    }
  };

  return userId ? (
    <Flex>
      <Button mx={2} onClick={() => handleNextTopic("topic-prev")}>
        Prev Topic
      </Button>
      <Button mx={2} onClick={() => handleNextTopic("topic-next")}>
        Next Topic
      </Button>
    </Flex>
  ) : null;
};
