import React from "react";
import { useParams } from "react-router-dom";
import { useBreakpointIndex } from "@theme-ui/match-media";

import { Box, Button, Checkbox, Flex, Input, Label, Select, Textarea } from "theme-ui";

import { IEpisodeTopic } from "../../../../../../Types";

import { Collapse, Divider } from "../../../../../../theme/UI";
import { FieldSet } from "./FieldSet";
import { TheLabel } from "./Label";
import { useUpdateTopic } from "../../../../../../Api";
import { useEpisodeDataStore } from "../../../../../../DataStores";

interface IActiveTopicForm {
	handleChange: (
		event:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLSelectElement>
			| React.ChangeEvent<HTMLTextAreaElement>
	) => void;
	handleCheckBoxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ActiveTopicForm: React.FC<IActiveTopicForm> = ({
	handleChange,
	handleCheckBoxChange
}) => {
	const { id } = useParams();
	const breakPointIndex = useBreakpointIndex();
	const { mutate } = useUpdateTopic();
	const { activeTopic, topics, isButtonDisabled, toggleTopicDrawer } = useEpisodeDataStore();

	const isDisabled = isButtonDisabled();

	const handleUpdateTopic = async () => {
		if (!activeTopic) return;
		mutate({ episodeId: String(id), topic: activeTopic });
	};

	const handleCloseEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.stopPropagation();
		toggleTopicDrawer(false);
	};

	return (
		<Box px={1} pb={2}>
			<FieldSet>
				<TheLabel>Name:</TheLabel>
				<Input
					autoComplete="off"
					onChange={handleChange}
					name="name"
					placeholder="Topic Name..."
					value={activeTopic?.name || ""}
				/>
			</FieldSet>

			<FieldSet>
				<Textarea
					name="desc"
					onChange={handleChange}
					placeholder="Description..."
					rows={4}
					value={activeTopic?.desc || ""}
				/>
			</FieldSet>

			<FieldSet>
				<Textarea
					name="chat"
					onChange={handleChange}
					placeholder="Chat Description..."
					rows={3}
					value={activeTopic?.chat || ""}
				/>
			</FieldSet>

			<FieldSet>
				<TheLabel>Time:</TheLabel>
				<Input
					mb={0}
					type="number"
					name="timer"
					onChange={handleChange}
					value={activeTopic?.timer || 0}
				/>
			</FieldSet>

			<Divider />

			<Flex>
				<Label sx={{ margin: 0, padding: 0 }}>
					<Checkbox
						checked={activeTopic?.isParent || false}
						disabled={activeTopic?.isChild}
						onChange={handleCheckBoxChange}
						name="isParent"
					/>{" "}
					Is Parent
				</Label>

				<Label sx={{ margin: 0, padding: 0 }}>
					<Checkbox
						checked={activeTopic?.isChild || false}
						disabled={activeTopic?.isParent || !topics.some(topic => topic.isParent)}
						onChange={handleCheckBoxChange}
						name="isChild"
					/>{" "}
					Is Child
				</Label>
			</Flex>

			<Collapse isOpen={!!activeTopic?.isChild}>
				<FieldSet sx={{ mt: 3 }}>
					<Select
						disabled={activeTopic?.isParent || !activeTopic?.isChild}
						name="parentId"
						onChange={handleChange}
						value={activeTopic?.parentId || ""}
					>
						<option value="">Choose Parent...</option>
						{topics.map((topic: IEpisodeTopic) => {
							if (!topic.isParent) return null;
							return (
								<option key={topic._id} value={topic._id}>
									{topic.name}
								</option>
							);
						})}
					</Select>
				</FieldSet>
			</Collapse>

			<Divider />

			<FieldSet>
				<TheLabel>Article:</TheLabel>
				<Input
					autoComplete="off"
					name="articles"
					onChange={handleChange}
					placeholder="Link to article..."
					value={activeTopic?.articles || ""}
				/>
			</FieldSet>

			<FieldSet>
				<Textarea
					name="notes"
					onChange={handleChange}
					placeholder="Notes..."
					rows={5}
					value={activeTopic?.notes || ""}
				/>
			</FieldSet>

			<Divider />

			<Flex mt={3} sx={{ gap: ".5rem" }}>
				<Button disabled={isDisabled} onClick={handleUpdateTopic}>
					Submit Topic Update
				</Button>

				{breakPointIndex < 1 && (
					<Button disabled={isDisabled} onClick={handleCloseEdit}>
						Close
					</Button>
				)}
			</Flex>
		</Box>
	);
};
