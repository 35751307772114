import { toast, TypeOptions } from "react-toastify";

type Toaster = (message: string, type?: TypeOptions) => void;

export interface IUseToasterHook {
  toaster: Toaster;
}

const useToasterHook = (): IUseToasterHook => {
  const toaster: Toaster = (message, type = "success") => {
    return toast(message, {
      position: "top-right",
      autoClose: type === "error" ? 5000 : 3500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      pauseOnFocusLoss: false,
      draggable: false,
      progress: undefined,
      theme: "dark",
      type
    });
  };

  return {
    toaster
  };
};

export default useToasterHook;
