import React from "react";
import { Button, Flex, Grid } from "theme-ui";
import Logo from "../../Assets/logo.png";
import { AppRoutes } from "../../Types";
import { useNavigate } from "react-router-dom";

export const Home: React.FC<{}> = () => {
  const navigate = useNavigate();

  return (
    <Flex
      variant="auths.wrapper"
      sx={{
        flexDirection: "column"
      }}
    >
      <Flex
        variant="auths.container"
        sx={{
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "accent6"
        }}
      >
        <img src={Logo} alt="Gamer Tool Kit Logo" />
      </Flex>

      <Grid variant="auths.buttonWrapper">
        <Button
          onClick={() => navigate(AppRoutes.Login)}
          sx={{ width: ["100%", "25rem"] }}
          type="button"
          variant="auths.submit"
        >
          Sign In
        </Button>
      </Grid>
    </Flex>
  );
};
