import React from "react";
import { Box, Grid } from "theme-ui";
import { Card, Checkbox } from "../../../theme/UI";
import { useBreakpointIndex } from "@theme-ui/match-media";
import { IUserCommandsCommands } from "../../../Api";

interface ICommandSubsProps {
  data: IUserCommandsCommands[];
  displayType?: "video" | "podcast";
  handleCheckbox: (id: string) => Promise<void>;
  subType: string;
  title: string;
}

export const CommandSubs: React.FC<ICommandSubsProps> = ({
  data,
  displayType = "podcast",
  handleCheckbox,
  subType,
  title
}) => {
  const breakPointIndex = useBreakpointIndex();

  return (
    <Card>
      <Card.Header>
        <Box>{title}</Box>
      </Card.Header>

      <Card.Body>
        <Grid variant="table">
          {displayType === "video" &&
            data
              .filter(f => f.subType === subType)
              .map(item => (
                <Grid key={item._id} variant="videoCommandList">
                  {breakPointIndex >= 1 && <Box>{item.command}</Box>}
                  <Box>
                    <Box>{item.usage}</Box>
                    <Box mt={2}>{item.description}</Box>
                  </Box>

                  <Box>
                    <Checkbox
                      checked={item.status}
                      _id={item._id}
                      label={``}
                      onChange={handleCheckbox}
                    />
                  </Box>
                </Grid>
              ))}

          <Grid variant="table">
            {displayType === "podcast" &&
              data
                .filter(f => f.subType === subType)
                .map(item => (
                  <Grid key={item._id} variant="controlList">
                    <Checkbox
                      checked={item.status}
                      _id={item._id}
                      label={`${item.command} - ${item.description}`}
                      onChange={handleCheckbox}
                    />
                  </Grid>
                ))}
          </Grid>
        </Grid>
      </Card.Body>
    </Card>
  );
};
