export const mediaUploader = {
  container: {
    flexDirection: "column",
    gap: ".5rem",
    margin: "0 0 5px 0"
  },

  wrapper: {
    aspectRatio: "16 / 9",
    borderColor: "accent3",
    width: "100%",
    border: ".0625rem solid",
    transition: "border-color .3s ease-in-out",
    fontSize: ".875rem",
    padding: "0",
    overflow: "hidden",
    position: "relative"
  },

  deleteButtonWrapper: {
    position: "absolute",
    top: "0",
    left: ".5rem",
    backgroundColor: "accent3",
    width: "1.5rem",
    height: "1.5rem",
    borderRadius: "0 0 25% 25%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "all .3s ease-in-out",
    zIndex: 100,
    border: 0,
    color: "white"
  },

  spinnerWrapper: {
    position: "absolute",
    top: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },

  spinner: {
    color: "accent3",
    position: "absolute",
    zIndex: 100
  }
};
