import React, { MouseEvent } from "react";
import { Box, Button, Flex, Grid, Text } from "theme-ui";
import { Card, Container } from "../../../../../theme/UI";

import { IEpisodeTopic } from "../../../../../Types";

import Icon from "@mdi/react";
import { mdiContentCopy, mdiLock, mdiTrashCan } from "@mdi/js";
import { topicImageThumbParser } from "../../../../../Utils";

import { useEpisodeId, useDragAndDropTopics } from "../../../../../Hooks";
import { useEpisodeDataStore } from "../../../../../DataStores";
import { useEpisodeTopicCopy, useEpisodeTopicDelete, useEpisodeTopicNew } from "../../../../../Api";

export const ProfileTopicList: React.FC = () => {
  const episodeId = useEpisodeId();
  const { activateItem, activeTopic, templateInfo, topics } = useEpisodeDataStore();

  const { mutate } = useEpisodeTopicCopy();
  const { mutate: newTopicMutate } = useEpisodeTopicNew();
  const { mutate: deleteTopicMutate } = useEpisodeTopicDelete();

  const { handleDragStart, handleDropTopic } = useDragAndDropTopics();

  const handleCopyTheTopic = async (e: MouseEvent<HTMLDivElement>, topicId: string) => {
    mutate({ episodeId: String(episodeId), topicId });
  };

  const handleDeleteTheTopic = async (e: MouseEvent<HTMLDivElement>, topicId: string) => {
    e.stopPropagation();
    episodeId && deleteTopicMutate({ episodeId: String(episodeId), topicId });
  };

  if (!episodeId) return null;

  return (
    <Card>
      <Card.Header>
        <Box>Topics ({topics.length})</Box>
        <Button onClick={() => episodeId && newTopicMutate(episodeId)}>New Topic</Button>
      </Card.Header>

      <Card.Body sx={{ paddingLeft: "0", paddingRight: "0" }}>
        <Container marginBottom={32}>
          <Grid variant="table">
            {topics.map((item: IEpisodeTopic, index: number) => {
              const hasImage = !!templateInfo?.images.topic.maxAmount && item.img;

              return (
                <Grid
                  key={item._id}
                  variant={hasImage ? "episodeTopicList" : "episodeTopicListNoImage"}
                  draggable={true}
                  onDragStart={(e: React.DragEvent<HTMLDivElement>) => handleDragStart(e, item._id)}
                  onDragOver={e => {
                    e.preventDefault();
                  }}
                  onDrop={(e: React.DragEvent<HTMLDivElement>) =>
                    handleDropTopic(e, episodeId, item._id)
                  }
                  sx={{
                    backgroundColor: item._id === activeTopic?._id ? "background3" : "transparent"
                  }}
                >
                  <Box />
                  {hasImage && (
                    <Box variant="grids.episodeTopicList.topicImageContainer">
                      {item.img && (
                        <img
                          src={topicImageThumbParser(item.img)}
                          alt={item.name}
                          style={{ objectFit: "cover" }}
                        />
                      )}
                    </Box>
                  )}

                  <Box
                    variant="grids.episodeTopicList.topicInfo"
                    sx={{ lineClamp: 4, cursor: "pointer" }}
                    onClick={() => activateItem(item._id)}
                  >
                    <Box mb={1}>
                      <Text
                        sx={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          color: "text3"
                        }}
                      >
                        {`${item.order}) ${item.name}`}
                      </Text>
                    </Box>

                    <Text
                      sx={{
                        overflow: "hidden",
                        width: "100%",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        textOverflow: "ellipsis"
                      }}
                    >
                      {item.desc}
                    </Text>
                  </Box>

                  {item.isParent ? (
                    <Box>
                      <Icon path={mdiLock} size={0.75} />
                    </Box>
                  ) : (
                    <Flex sx={{ flexDirection: "column", gap: 2 }}>
                      <Box
                        onClick={e => handleCopyTheTopic(e, item._id)}
                        sx={{ cursor: "pointer" }}
                      >
                        <Icon path={mdiContentCopy} size={0.75} />
                      </Box>

                      <Box
                        onClick={e => handleDeleteTheTopic(e, item._id)}
                        sx={{ cursor: "pointer" }}
                      >
                        <Icon path={mdiTrashCan} size={0.75} />
                      </Box>
                    </Flex>
                  )}

                  <Box />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Card.Body>
    </Card>
  );
};
