import * as React from "react";
import httpService from "../../../Api/httpService";
import { Card } from "../../../theme/UI";
import { Box, Button } from "theme-ui";

export const ChatBotsListing: React.FC = () => {
  const redirect = process.env.REACT_APP_TWITCH_REDIRECT_URL;
  const clientId = process.env.REACT_APP_TWITCH_CLIENT_ID;
  const [authorized, setAuthorized] = React.useState<boolean>(false);

  const scopes = ["chat:read", "chat:edit", "moderator:read:followers", "channel:manage:broadcast"];

  const TWITCH_CONNECTION_LINK = `https://id.twitch.tv/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirect}&response_type=code&scope=${scopes.join(
    "+"
  )}&force_verify=true`;

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await httpService.get(
          `${process.env.REACT_APP_REST_API}twitch-chat/is-connected`
        );

        if (data.result.isConnected) {
          setAuthorized(true);
        }
      } catch (error) {
        console.error(32, error);
      }
    };

    fetchData();
  }, []);

  const handleLink = (): void => {
    window.location.replace(TWITCH_CONNECTION_LINK);
  };

  const handleDisconnect = async (): Promise<void> => {
    try {
      const { data } = await httpService.get(
        `${process.env.REACT_APP_REST_API}twitch-chat/disconnect-from-chat`
      );

      if (data.resultStatus.success) {
        setAuthorized(false);
      }
    } catch (error) {
      console.error(65, error);
    }
  };

  return (
    <>
      <Card>
        <Card.Header>GTK's Twitch Bot</Card.Header>

        <Card.Body>
          <Box mb={2}>
            {authorized ? (
              <>
                <Box mb={3}>Your Twitch account is connected to GTK!</Box>

                <Button onClick={handleDisconnect}>Disconnect from GTK-Bot.</Button>
              </>
            ) : (
              <>
                <Box mb={3}>Allow GTK to read your chat and respond to commands.</Box>

                <Button onClick={handleLink}>Connect to GTK Bot</Button>
              </>
            )}
          </Box>
        </Card.Body>
      </Card>
    </>
  );
};
