import React from "react";
import { Flex, Spinner } from "theme-ui";

export const LoadingPage: React.FC = () => {
  return (
    <Flex
      sx={{
        height: "100vh",
        width: "100vw",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Spinner color="accent3" />
    </Flex>
  );
};
