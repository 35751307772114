import "./animations.css";

const tabsDefault = {
  width: "100%",
  position: "absolute",
  top: "0",

  animationDuration: "0.8s",
  animationFillMode: "forwards",
  animationTimingFunction: "ease-out"
};

const menuItemsDefaults = {
  display: "flex",
  cursor: "pointer",
  borderBottom: "0.0625rem solid",
  borderRadius: "0.375rem 0.375rem 0 0",
  alignItems: "center",
  justifyContent: "center",
  padding: ".375rem",
  transition: "all 0.8s ease-in-out"
};

export const tabs = {
  menu: {
    width: "100%",
    gap: "0"
  },
  menuItem: {
    ...menuItemsDefaults,
    borderColor: "accent3",

    active: {
      ...menuItemsDefaults,
      borderColor: "transparent",
      backgroundColor: "accent3",
      color: "text3"
    }
  },
  wrapper: {
    position: "relative",
    width: "100%",
    height: "100%",
    overflow: "hidden"
  },
  panel: {
    ...tabsDefault,
    left: "-100%",
    // animationName: "tabsSlideOut",

    active: {
      ...tabsDefault,
      left: 0
      // animationName: "tabsSlideIn"
    }
  }
};
