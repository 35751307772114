import { AppRoutes } from "../../../../Types";

import {
  mdiAccountMultipleCheck,
  mdiConnection,
  mdiImageArea,
  mdiLink,
  mdiListBoxOutline,
  mdiNewspaper,
  mdiTelevision
} from "@mdi/js";

export type IHeader = {
  icon: string;
  name: string;
  link: string;
  url?: string;
};

export const profileHeaders: IHeader[] = [
  {
    icon: mdiTelevision,
    name: "Info",
    link: "info",
    url: AppRoutes.EpisodeProfileInfo
  },
  {
    icon: mdiListBoxOutline,
    name: "Topics",
    link: "topics",
    url: AppRoutes.EpisodeProfileTopics
  },
  {
    icon: mdiAccountMultipleCheck,
    name: "Hosts",
    link: "hosts",
    url: AppRoutes.EpisodeProfileHosts
  },
  {
    icon: mdiNewspaper,
    name: "News",
    link: "news",
    url: AppRoutes.EpisodeProfileNews
  },
  {
    icon: mdiConnection,
    name: "Social",
    link: "social-networks",
    url: AppRoutes.EpisodeProfileSocials
  },
  {
    icon: mdiImageArea,
    name: "Images",
    link: "images",
    url: AppRoutes.EpisodeProfileImages
  },
  {
    icon: mdiLink,
    name: "Links",
    link: "links",
    url: AppRoutes.EpisodeProfileLinks
  }
];
