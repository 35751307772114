import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, Flex, Input, Select } from "theme-ui";
import { Card } from "../../../../theme/UI";
import { ISocials } from "../../../../Types";
import { CommonSocials } from "../../../../Api/Types";
import { useEditSocial } from "../../../../Api/useSocial/useSocial";
import httpService from "../../../../Api/httpService";
import { ConfirmDelete } from "../../../Shared/Delete/Delete";
import { useCommonDataStore } from "../../../../DataStores";

export type Options = {
  hosts: boolean;
  logo: boolean;
  news: boolean;
  socialNetworks: boolean;
  sponsors: boolean;
};

interface EpisodeNewProps {
  activeSocial: ISocials | undefined;
  handleCloseDrawer?: () => void;
  showHeader?: boolean;
  handleEditCallBack: (data: ISocials) => void;
  handleDeleteCallBack: (id: string) => void;
}

export const SocialEdit: React.FC<EpisodeNewProps> = ({
  activeSocial,
  handleCloseDrawer,
  showHeader = true,
  handleEditCallBack,
  handleDeleteCallBack
}) => {
  const { socials } = useCommonDataStore();
  const navigate = useNavigate();
  const [isDeleteColapsed, setIsDeleteColapsed] = React.useState<boolean>(true);

  const [theSocial, setTheSocial] = React.useState<ISocials | undefined>(undefined);
  const { data, error, isLoading, mutate } = useEditSocial();

  React.useEffect(() => {
    setTheSocial(activeSocial || undefined);
    setIsDeleteColapsed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSocial]);

  React.useEffect(() => {
    let stillHere = true;

    if (!isLoading && data && stillHere && theSocial) {
      handleEditCallBack({
        _id: theSocial._id,
        site: theSocial.site,
        username: theSocial.username
      });
    }

    return () => {
      stillHere = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, isLoading, navigate]);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (!theSocial) return;
    const { name, value } = e.target;
    setTheSocial({ ...theSocial, [name]: value });
  };

  const handleFormSubmit = async () => {
    if (!theSocial) {
      console.error("Error, Name and Template can not be empty");
      return;
    }

    mutate(theSocial);

    handleCloseDrawer && handleCloseDrawer();
  };

  const deleteHost = async () => {
    if (!theSocial?._id) return;
    const _id = theSocial._id;
    const { data } = await httpService.delete(
      `${process.env.REACT_APP_REST_API}podcast-socials/${_id}`
    );

    if (data?.resultStatus?.success) {
      setIsDeleteColapsed(true);
      handleDeleteCallBack(_id);
    }
  };

  return (
    <Card>
      {showHeader && (
        <>
          <Card.Header>
            <div>Social Media Edit</div>
          </Card.Header>
        </>
      )}

      <Card.Body>
        <Flex sx={{ flexDirection: "column", gap: "1rem" }}>
          <Input
            disabled={!theSocial}
            name="username"
            onChange={handleFormChange}
            placeholder="Username"
            sx={{ mt: ".375rem" }}
            type="text"
            value={theSocial?.username || ""}
          />

          <Select
            disabled={!theSocial}
            name="site"
            onChange={handleFormChange}
            value={theSocial?.site || ""}
          >
            {socials.map((social: CommonSocials) => (
              <option key={social._id} value={social.site}>
                {social.site}
              </option>
            ))}
          </Select>

          <Button disabled={isLoading || !theSocial} onClick={handleFormSubmit} variant="loading">
            Submit Changes
          </Button>
        </Flex>
      </Card.Body>

      <ConfirmDelete
        deleteMethod={deleteHost}
        isColapsed={isDeleteColapsed}
        isVisible={!!theSocial?._id}
        toggleColapsed={() => setIsDeleteColapsed(!isDeleteColapsed)}
      />
    </Card>
  );
};
