const listDefault = {
  gridTemplateColumns: "3.125rem 2fr 7rem",
  gridGap: "0.5rem",
  padding: ".5rem 0.5rem",
  backgroundColor: "background1",
  borderBottom: ".0625rem dashed",
  borderColor: "border1",
  fontSize: "0.875rem",
  "&:hover": {
    bg: "background3"
  }
};

export const grids = {
  table: {
    gridTemplateColumns: "1fr",
    gridGap: "0rem",
    color: "text2"
  },

  episodeList: {
    ...listDefault,
    gridTemplateColumns: "50px 2fr 7rem",
    cursor: "pointer",
    color: "text3",

    ">div:nth-of-type(1)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "2.5rem",
      width: "2.5rem",
      color: "text3",
      borderRadius: "0.375rem",

      fontSize: "0.75rem",
      fontWeight: "bold"
    },

    ">div:nth-of-type(2)": {
      alignItems: "center",
      justifyContent: "right",

      ">div:nth-of-type(1)": {
        color: "text3",
        fontSize: "0.875rem",
        fontWeight: "bold",
        paddingBottom: "0.25rem"
      }
    },

    ">div:nth-of-type(3) >div:nth-of-type(1)": {
      color: "text3",
      fontSize: "0.875rem",
      paddingBottom: "0.25rem"
    }
  },

  socialTable: {
    gridTemplateColumns: ["1fr"],
    gridRowGap: "0rem",
    color: "text2"
  },
  socialList: {
    ...listDefault,
    gridTemplateColumns: "50px 2fr 7rem",
    cursor: "pointer",

    ">div:nth-of-type(1)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "2.5rem",
      width: "2.5rem",
      color: "text3",
      borderRadius: "0.375rem",

      fontSize: "0.75rem",
      fontWeight: "bold"
    },

    ">div:nth-of-type(2)": {
      alignItems: "center",
      justifyContent: "right",

      ">div:nth-of-type(1)": {
        color: "text3",
        fontSize: "0.875rem",
        fontWeight: "bold",
        paddingBottom: "0.25rem"
      }
    }
  },

  browserLinkList: {
    ...listDefault,
    gridTemplateColumns: "2rem 2fr max-content",

    ">div": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },

    ">div:nth-of-type(2)": {
      justifyContent: "left"
    }
  },

  controlList: {
    ...listDefault,
    gridTemplateColumns: "max-content  1fr",
    padding: ".75rem 0.5rem",

    "&:nth-of-type(2n)": {
      backgroundColor: "background3"
    },

    "&:last-of-type": {
      borderBottom: "none"
    },

    "&:hover": {
      bg: "background3",
      color: "text3"
    }
  },

  videoCommandList: {
    ...listDefault,
    gridTemplateColumns: ["1fr 20px", "90px  1fr 20px"],
    padding: ".75rem 0.5rem",
    gap: "10px",

    "&:nth-of-type(2n)": {
      backgroundColor: "background3"
    },

    "&:last-of-type": {
      borderBottom: "none"
    },

    "&:hover": {
      bg: "background3",
      color: "text3"
    },

    ">div:last-of-type": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }
  },

  defaultGridList: {
    ...listDefault,
    gridTemplateColumns: "2rem 2fr max-content"
  },

  listDefaultIcon: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center"
  },

  listDefaultDisplayText: {
    display: "flex",
    justifyContent: "left",
    alignItems: "center"
  },

  videoRequestPlaylistItem: {
    ...listDefault,
    gridTemplateColumns: "5rem 1fr",

    topicImageContainer: {
      width: "100%",
      height: "3.125rem",
      backgroundColor: "accent3",
      overflow: "hidden",
      borderRadius: ".375rem",
      "> img": {
        width: "100%",
        height: "100%"
      }
    },

    itemInfo: {
      height: "70px",
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      title: {},
      text: {}
    }
  },

  episodeTopicList: {
    ...listDefault,
    gridTemplateColumns: ".25rem 5rem 2fr max-content .25rem",
    overflow: "hidden",

    topicImageContainer: {
      width: "100%",
      height: "3.125rem",
      backgroundColor: "accent3",
      overflow: "hidden",
      borderRadius: ".375rem",
      "> img": {
        width: "100%",
        height: "100%"
      }
    },

    topicInfo: {
      height: "60px",
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      title: {},
      text: {}
    }
  },

  episodeTopicListNoImage: {
    ...listDefault,
    gridTemplateColumns: ".25rem 2fr max-content .25rem",
    overflow: "hidden",

    topicInfo: {
      height: "60px",
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      title: {},
      text: {}
    }
  },

  overlayTable: {
    gridTemplateColumns: ["1fr", "1fr 1fr", "1fr 1fr 1fr 1fr"],
    gridGap: "0rem",
    color: "text2"
  },

  overlayList: {
    ...listDefault,
    gridTemplateColumns: "1.5rem 1.5rem 1fr"
    // padding: "1rem 0.5rem"
  },

  hostList: {
    ...listDefault,
    gridTemplateColumns: "50px 2fr 7rem",
    cursor: "pointer",

    ">div:nth-of-type(1)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "2.5rem",
      width: "2.5rem",
      color: "text3",
      borderRadius: "0.375rem",

      fontSize: "0.75rem",
      fontWeight: "bold"
    },

    ">div:nth-of-type(2)": {
      alignItems: "center",
      justifyContent: "right",

      ">div:nth-of-type(1)": {
        color: "text3",
        fontSize: "0.875rem",
        fontWeight: "bold",
        paddingBottom: "0.25rem"
      }
    }
  },

  socialAssistantList: {
    ...listDefault,
    gridTemplateColumns: "2rem 1fr"
  },

  cannedMessageTable: {
    gridTemplateColumns: ["1fr"],
    gridRowGap: "0rem",
    color: "text2"
  },

  cannedMessageList: {
    ...listDefault,
    gridTemplateColumns: "50px 2fr 7rem",
    cursor: "pointer",

    ">div:nth-of-type(1)": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "2.5rem",
      width: "2.5rem",
      color: "text3",
      borderRadius: "0.375rem",

      fontSize: "0.75rem",
      fontWeight: "bold"
    },

    ">div:nth-of-type(2)": {
      alignItems: "center",
      justifyContent: "right",

      ">div:nth-of-type(1)": {
        color: "text3",
        fontSize: "0.875rem",
        fontWeight: "bold",
        paddingBottom: "0.25rem"
      }
    }
  }
};
