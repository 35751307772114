import { buttonDefaults } from "./buttons";

export const auths = {
  wrapper: {
    height: "100vh",
    width: "100vw",
    alignItems: "center",
    justifyContent: "center",
    color: "#ccc",
    backgroundColor: "primaryBg2"
  },

  container: {
    borderRadius: "1rem",
    boxShadow: "0 0 20px rgba(160, 32, 240, 0.2)",
    padding: ".625rem 1.25rem",
    transition: "transform 0.2s",
    width: ["100%", "25rem"],
    textAlign: "center",
    backgroundColor: "#222"
  },

  label: {
    fontSize: "0.875rem",
    color: "primaryFont",
    marginBottom: ".3125rem",
    marginTop: ".625rem",
    textAlign: "left"
  },

  buttonWrapper: {
    alignItems: "center",
    justifyContent: "center",
    gap: ".625rem",
    marginTop: "15px",
    marginBottom: "15px",
    gridTemplateColumns: ["1fr"]
  },

  submit: {
    ...buttonDefaults,
    padding: ".625rem",
    borderRadius: ".3125rem",
    backgroundColor: "accent2",
    width: "100%",
    fontSize: "1rem"
  },

  submitGoogle: {
    ...buttonDefaults,
    padding: ".625rem",
    borderRadius: ".3125rem",
    backgroundColor: "accent3",
    width: "100%",
    fontSize: "1rem"
  }
};
