import useToasterHook from "../useToast/useToast";

type CopyText = (text: string) => void;

export interface IUseCopyHook {
  copyText: CopyText;
}

const useCopyHook = (): IUseCopyHook => {
  const { toaster } = useToasterHook();

  const copyText: CopyText = text => {
    navigator.clipboard
      .writeText(text)
      .then(() => toaster("Copied!"))
      .catch(() => toaster("Error!", "error"));
  };

  return { copyText };
};

export default useCopyHook;
