import React from "react";
import { Navigate } from "react-router-dom";
import { AppRoutes } from "../Types";
import { LoadingPage } from "./LoadingPage";
import { User } from "firebase/auth";
import { useToken } from "../Hooks";
import { firebaseAuth } from "./../Hooks/useAuthHook/Firebase";

interface IProtectedRoute {
  children: React.ReactElement;
}

const ProtectedRoute: React.FC<IProtectedRoute> = ({ children }) => {
  const [authUser, setAuthUser] = React.useState<User | null>(null);
  const [authChecked, setAuthChecked] = React.useState(false);
  const { verifiedToken } = useToken();

  React.useLayoutEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(user => {
      if (user) {
        setAuthUser(user);
      } else {
        setAuthUser(null);
      }
      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, []);

  if (!authChecked) {
    return <LoadingPage />;
  }

  return authUser && verifiedToken ? children : <Navigate to={AppRoutes.Login} />;
};

export default ProtectedRoute;
