import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import httpService from "../../Api/httpService";
import { Card, Container } from "../../theme/UI";
import { Box, Button } from "theme-ui";

import { useCopy } from "../../Hooks";
import { Config } from "../../Config/Config";
import { ShowRunnerTopic } from "./ShowRunnerTopic";
import { Episode, Topic } from "./types";

export const ShowRunner: React.FC = () => {
  const { id } = useParams();
  const { copyText } = useCopy();

  const [episodeState, setEpisodeState] = React.useState<Episode>({
    _id: "",
    airDate: "",
    logo: "",
    name: "",
    number: 0,
    topics: []
  });

  React.useEffect(() => {
    let stillHere = true;

    const fetchData = async () => {
      const { data } = await httpService.get(`episode-data/show-runner/${id}`);

      if (stillHere) {
        setEpisodeState({
          ...data.result.episodeData
        });
      }
    };

    fetchData();

    return () => {
      stillHere = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCopyLink = () => {
    copyText(`${window.location}`);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GTK | ShowRunner: {episodeState.name}</title>

        <meta property="og:title" content={episodeState.name} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`/public/show-runner/${episodeState._id}`} />

        {episodeState.logo && (
          <meta
            property="og:image"
            content={`${Config.REACT_APP_CLOUD_IMAGES}/${episodeState.logo}`}
          />
        )}
      </Helmet>

      <Container sx={{ width: "100vw", padding: "1rem" }}>
        <Box>
          <Card sx={{ marginTop: "1rem" }}>
            <Card.Body>
              <Box>
                <p>{episodeState.name}</p>
              </Box>
            </Card.Body>
          </Card>
        </Box>
        <Box>
          <Box>
            <Card sx={{ marginTop: "1rem" }}>
              <Card.Body>
                <Box>
                  <p>Date: {episodeState.airDate}</p>
                  <p>Episode #{episodeState.number}</p>
                  <p>Topics: {episodeState.topics.length}</p>
                </Box>
              </Card.Body>
            </Card>

            <Card sx={{ marginTop: "1rem" }}>
              <Card.Body>
                <Button onClick={handleCopyLink}>Copy Show Runner Link</Button>
              </Card.Body>
            </Card>

            {episodeState.topics?.map((topic: Topic, index: number) => (
              <Card
                key={topic._id}
                sx={{ marginTop: "1rem", bg: index % 2 ? "background2" : "background1" }}
              >
                <Card.Body>
                  <ShowRunnerTopic topic={topic} logo={episodeState.logo} />
                </Card.Body>
              </Card>
            ))}
          </Box>
        </Box>
      </Container>
    </>
  );
};
