import { buttons } from "./Components/buttons";
import { cards } from "./Components/cards";
import { checkbox } from "./Components/checkBox";
import { colors } from "./Components/colors";
import { divider } from "./Components/divider";
import { forms } from "./Components/forms";
import { grids } from "./Components/grids";
import { mediaUploader } from "./Components/mediaUploader";
import { drawer } from "./Components/Drawer";
import { tabs } from "./Components/tabs";
import { auths } from "./Components/auths";
import { scrollbars } from "./Scrollbars";

// *********************************
import { borders } from "./themeComponents/borders";
import { fontSizes, fontWeights, letterSpacings, lineHeights } from "./themeComponents/fonts";
import { radii } from "./themeComponents/radii";
import { space } from "./themeComponents/space";
// *********************************

export const theme = {
  "*": {
    "&::-webkit-scrollbar": {
      width: ["0", "0.375rem"]
    }
  },

  styles: {
    root: {
      height: "100vh",
      width: "100vw",
      margin: 0,
      padding: 0,
      overflow: "hidden",

      textDecoration: "none",
      "& *, &:before, &:after": {
        BoxSizing: "border-box",
        margin: 0,
        padding: 0,
        textDecoration: "none"
      },
      ...scrollbars
    }
  },

  fonts: {
    body: "Roboto, system-ui, sans-serif",
    heading: "Roboto, system-ui, sans-serif",
    monospace: '"Roboto Mono", monospace'
  },

  auths,
  buttons,
  cards,
  checkbox,
  colors,
  divider,
  drawer,
  forms,
  grids,
  mediaUploader,
  tabs,

  // *********************************
  borders,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  radii,
  sizes: space
  // *********************************
};

export const fontFamilies = `
  @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');`;
