import React from "react";
import Dropzone from "react-dropzone";
import { Box, Button, Flex, Image, Spinner } from "theme-ui";
import { topicImageParser } from "../../../../../../Utils";
import Icon from "@mdi/react";
import { mdiTrashCan } from "@mdi/js";
import { useToaster } from "../../../../../../Hooks";
import { useEpisodeDataStore } from "../../../../../../DataStores";
import { useEpisodeId, useContent } from "../../../../../../Hooks";

export const ActiveTopicContentUploader: React.FC = () => {
  const episodeId = useEpisodeId();
  const { toaster } = useToaster();
  const [isDragOver, setIsDragOver] = React.useState<boolean>(false);
  const { activeTopic, isButtonDisabled, setSectionLoading } = useEpisodeDataStore();
  const { isLoading, addTopicContent, addTopicContentTrans, deleteContent } = useContent();

  const isDisabled = isButtonDisabled();
  const topicContent = activeTopic?.content;
  const topicId = activeTopic?._id;

  const handleFileUploads = async (acceptedFiles: File[]) => {
    setSectionLoading("content");
    const allowedFileTypes = ["image/jpeg", "image/png", "image/webp", "video/mp4"];
    const maxFileSize = 125 * 1024 * 1024;

    if (!topicId || !acceptedFiles[0] || isDisabled) return;

    if (!allowedFileTypes.includes(acceptedFiles[0].type)) {
      toaster("Invalid file type. Only JPEG and PNG are allowed.", "error");
      setSectionLoading("content", false);
      return;
    }

    if (acceptedFiles[0].size > maxFileSize) {
      toaster(`File size exceeds the maximum limit of ${maxFileSize}Kb`, "error");
      setSectionLoading("content", false);
      return;
    }

    addTopicContent(episodeId as string, acceptedFiles[0], topicId);
  };

  const handleDeleteContent = async (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): Promise<void> => {
    e.stopPropagation();

    if (!episodeId || !topicId || isDisabled) return;
    deleteContent(episodeId, topicId);
  };

  const handleUseTransparentImage = async () => {
    if (!topicId) return;

    await addTopicContentTrans(episodeId as string, topicId);
  };

  return (
    <Flex variant="mediaUploader.container">
      <Dropzone onDrop={acceptedFiles => handleFileUploads(acceptedFiles)}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} style={{ width: "100%" }}>
            <input {...getInputProps()} />

            <Box
              variant="mediaUploader.wrapper"
              sx={{ borderColor: isDragOver ? "accent2" : "accent3" }}
              onDragOver={() => setIsDragOver(true)}
              onDragLeave={() => setIsDragOver(false)}
              onDrop={() => setIsDragOver(false)}
            >
              <Box variant="mediaUploader.spinnerWrapper">
                <Spinner
                  sx={{ display: isLoading ? "block" : "none" }}
                  variant="mediaUploader.spinner"
                />
              </Box>

              <Box
                as="button"
                aria-label="Delete Topic Content"
                onClick={handleDeleteContent}
                sx={{ opacity: topicContent?.file ? 1 : 0 }}
                variant="mediaUploader.deleteButtonWrapper"
              >
                <Icon path={mdiTrashCan} size={0.5} />
              </Box>

              {topicContent?.type === "video" && (
                <video
                  controls
                  style={{
                    width: "100%",
                    height: "100%",
                    aspectRatio: "16 / 9"
                  }}
                  src={topicContent?.file || ""}
                />
              )}
              {topicContent?.type === "image" && (
                <Image
                  alt={topicContent?.type}
                  src={topicImageParser(topicContent?.file, 960, 540)}
                  sx={{ width: "100%" }}
                />
              )}
            </Box>

            <Box my={2}>
              <Button disabled={isDisabled} sx={{ width: "100%" }}>
                Choose Content Image/Video
              </Button>
            </Box>
          </div>
        )}
      </Dropzone>

      <Button disabled={isDisabled} onClick={handleUseTransparentImage} sx={{ width: "100%" }}>
        Use Transparent Content
      </Button>
    </Flex>
  );
};
