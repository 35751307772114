import { create, StoreApi } from "zustand";
import httpService from "../Api/httpService";

type Playlist = {
  _id: string;
  playlistName: string;
};

type PlaylistItem = {
  _id: string;
  playlistId: string;
  videoId: string;
  videoThumbnail: string;
  videoTitle: string;
  videoUrl: string;
};

export interface VideoRequestDataStore {
  isLoading: boolean;
  isPlaylistEditOpen: boolean;
  videoPreviewId: string | null;
  userPlaylist: Playlist[];
  activePlaylist: Playlist;
  playlistItems: PlaylistItem[];
  setPreviewVideoId: (videoId: string) => void;
  searchYoutube: (searchTerm: string) => Promise<void>;
  searchResults: any[];

  addToPlaylist: (videoId: string) => void;
  removeFromPlaylist: (_id: string) => void;
  loadInitPlaylist: () => void;
  changeActivePlaylist: (_id: string) => Promise<void>;
  setIsPlaylistEditOpen: (isOpen: boolean) => void;
  editPlaylist: (playlistName: string) => Promise<void>;
  createPlaylist: () => Promise<void>;
  deletePlaylist: () => Promise<void>;
  updatePlaylistItem: (_id: string, videoTitle: string) => Promise<void>;
}

export const useVideoRequestDataStore = create<VideoRequestDataStore>(
  (
    set: StoreApi<VideoRequestDataStore>["setState"],
    get: StoreApi<VideoRequestDataStore>["getState"]
  ) => ({
    isLoading: false,
    isPlaylistEditOpen: false,
    userPlaylist: [],
    videoPreviewId: null,
    setPreviewVideoId: (videoId: string) => {
      set({ videoPreviewId: videoId });
    },
    activePlaylist: {
      _id: "default",
      playlistName: "Default Playlist"
    },
    playlistItems: [],
    searchResults: [],

    loadInitPlaylist: async () => {
      try {
        const { data } = await httpService.get(
          `${process.env.REACT_APP_REST_API}videoOverlayPlaylist/getUserPlaylists`
        );

        set({
          userPlaylist: data.result.playlists,
          playlistItems: data.result.playlistItems
        });

        console.clear();
        console.log("data", data);
      } catch (error) {
        console.error("loadInitPlaylist", error);
      }
    },

    setIsPlaylistEditOpen: (isOpen: boolean) => {
      if (isOpen && get().activePlaylist._id === "default") return;
      set({ isPlaylistEditOpen: isOpen });
    },

    changeActivePlaylist: async (_id: string) => {
      console.log({ _id });
      const playlist = get().userPlaylist.find(
        playlist => playlist._id === _id
      );

      try {
        // fetch playlist items
        const { data } = await httpService.post(
          `${process.env.REACT_APP_REST_API}videoOverlayPlaylist/getPlaylistItems`,
          {
            playlistId: _id
          }
        );

        set({ playlistItems: data.result.playlistItems });

        console.log("data", data);
      } catch (error) {
        console.error("changeActivePlaylist", error);
      }

      if (playlist) {
        set({ activePlaylist: playlist });
      }
    },

    searchYoutube: async (searchTerm: string) => {
      try {
        set({ isLoading: true, searchResults: [] });
        const { data } = await httpService.post(
          `${process.env.REACT_APP_REST_API}videoOverlayPlaylist/searchYoutube`,
          { searchTerm }
        );

        set({ searchResults: data.result.videos });
      } catch (error) {
        console.error("searchYoutube", error);
      } finally {
        set({ isLoading: false });
      }
    },

    addToPlaylist: async (videoId: string) => {
      const playlistId = get().activePlaylist?._id;
      if (!playlistId) return;

      //is video already in playlist?
      const alreadyInPlaylist = get().playlistItems.find(
        (item: any) => item.videoId === videoId
      );

      if (alreadyInPlaylist) return;

      const video = get().searchResults.find(
        (video: any) => video.videoId === videoId
      );

      if (!video) return;

      try {
        const { data } = await httpService.post(
          `${process.env.REACT_APP_REST_API}videoOverlayPlaylist/addVideoToPlaylist`,
          {
            videoId,
            playlistId: playlistId === "default" ? "" : playlistId,
            videoTitle: video.videoTitle,
            videoThumbnail: video.videoThumbnail
          }
        );

        if (data?.result) {
          video._id = data.result._id;
          video.username = data.result.username;
          set({ playlistItems: [...get().playlistItems, video] });
        }
      } catch (error) {
        console.error("addPlaylist", error);
      }
    },

    removeFromPlaylist: async (_id: string) => {
      const playlistId = get().activePlaylist?._id;
      if (!playlistId) return;

      try {
        const { data } = await httpService.post(
          `${process.env.REACT_APP_REST_API}videoOverlayPlaylist/removeVideoFromPlaylist`,
          {
            _id,
            playlistId: playlistId === "default" ? "" : playlistId
          }
        );

        if (data?.result) {
          set({
            playlistItems: get().playlistItems.filter(
              (item: any) => item._id !== _id
            )
          });
        }
      } catch (error) {
        console.error("removeFromPlaylist", error);
      }
    },
    editPlaylist: async (playlistName: string) => {
      const playlistId = get().activePlaylist?._id;
      if (!playlistId) return;

      try {
        const { data } = await httpService.post(
          `${process.env.REACT_APP_REST_API}videoOverlayPlaylist/editPlaylist`,
          {
            playlistId,
            playlistName
          }
        );

        if (data?.result) {
          set({
            userPlaylist: get().userPlaylist.map(playlist =>
              playlist._id === playlistId
                ? { ...playlist, playlistName }
                : playlist
            ),
            activePlaylist: { ...get().activePlaylist, playlistName }
          });
        }
      } catch (error) {
        console.error("editPlaylist", error);
      }
    },

    createPlaylist: async () => {
      try {
        const { data } = await httpService.get(
          `${process.env.REACT_APP_REST_API}videoOverlayPlaylist/createPlaylist`
        );

        console.log("data", data);

        if (data?.result) {
          set({
            userPlaylist: [...get().userPlaylist, data.result],
            activePlaylist: data.result,
            playlistItems: []
          });
        }
      } catch (error) {
        console.error("createPlaylist", error);
      }
    },
    deletePlaylist: async () => {
      const playlistId = get().activePlaylist?._id;
      if (!playlistId) return;

      try {
        const { data } = await httpService.post(
          `${process.env.REACT_APP_REST_API}videoOverlayPlaylist/deletePlaylist`,
          {
            playlistId
          }
        );

        if (data?.result) {
          set({
            userPlaylist: get().userPlaylist.filter(
              playlist => playlist._id !== playlistId
            ),
            activePlaylist: {
              _id: "default",
              playlistName: "Default Playlist"
            },
            playlistItems: []
          });
        }
      } catch (error) {
        console.error("deletePlaylist", error);
      }
    },

    updatePlaylistItem: async (_id: string, videoTitle: string) => {
      try {
        const { data } = await httpService.post(
          `${process.env.REACT_APP_REST_API}videoOverlayPlaylist/updatePlaylistItem`,
          {
            _id,
            videoTitle
          }
        );

        if (data?.result) {
          set({
            playlistItems: get().playlistItems.map(item =>
              item._id === _id ? { ...item, videoTitle } : item
            )
          });
        }
      } catch (error) {
        console.error("updatePlaylistItem", error);
      }
    }
  })
);
