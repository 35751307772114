import * as React from "react";
import Icon from "@mdi/react";
import { mdiChevronDoubleLeft, mdiChevronDoubleRight } from "@mdi/js";

import _range from "lodash/range";

import { Button, Flex } from "theme-ui";

function displayPagination(currentPage: number, totalPages: number): number[] {
  currentPage = Number(currentPage);
  const maxButtons = 7;
  const padding = Math.floor(maxButtons / 2);
  const halfWayPoint = maxButtons - padding;

  if (totalPages <= maxButtons) {
    return _range(1, totalPages + 1);
  } else {
    if (currentPage <= halfWayPoint) {
      return _range(1, maxButtons + 1);
    } else if (currentPage <= totalPages - halfWayPoint) {
      return _range(currentPage - padding, currentPage + padding + 1);
    } else {
      return _range(totalPages - maxButtons + 1, totalPages + 1);
    }
  }
}

export interface IPaginationProps {
  currentPage: number;
  handlePageChange: (page: number) => void;
  totalPages: number;
}

export const Pagination: React.FC<IPaginationProps> = ({
  currentPage,
  handlePageChange,
  totalPages
}) => {
  const paginationArray = displayPagination(currentPage, totalPages);

  return (
    <>
      {totalPages > 1 && (
        <Flex sx={{ margin: "0 auto", padding: ".5rem 0", gap: ".5rem" }}>
          <Button
            aria-label="Previous page of epsiode list"
            disabled={Number(currentPage) === 1}
            onClick={() => handlePageChange(1)}
            variant="secondary"
          >
            <Icon path={mdiChevronDoubleLeft} size={1} />
          </Button>

          {paginationArray.map((page: number) => (
            <Button
              aria-label={`Page ${page} of epsiode list`}
              key={page}
              onClick={() => handlePageChange(page)}
              variant="pagination"
            >
              {page}
            </Button>
          ))}

          <Button
            aria-label="Next page of epsiode list"
            disabled={Number(currentPage) === totalPages}
            onClick={() => handlePageChange(totalPages)}
            variant="secondary"
          >
            <Icon path={mdiChevronDoubleRight} size={1} />
          </Button>
        </Flex>
      )}
    </>
  );
};
