import React from "react";
import { firebaseAuth } from "./Firebase";
import {
  GoogleAuthProvider,
  User,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail
} from "firebase/auth";
import httpService from "../../Api/httpService";
import { useNavigate } from "react-router-dom";
import { AppRoutes } from "../../Types";
import { useToaster } from "..";

type SignOut = (
  e: React.MouseEvent<HTMLDivElement | HTMLButtonElement | HTMLAnchorElement>
) => Promise<void>;

type SignIn = (email: string, password: string) => Promise<void>;

type SignInWithGoogle = (e: React.MouseEvent<HTMLButtonElement>) => Promise<void>;

type SignUp = (email: string, password: string) => Promise<void>;

type UseAuthHook = () => {
  authUser: User | null;
  forgotPassword: ForgotPassword;
  signOut: SignOut;
  signIn: SignIn;
  signInWithGoogle: SignInWithGoogle;
  signUp: SignUp;
};

type ForgotPassword = (email: string) => Promise<void>;

const useAuthHook: UseAuthHook = () => {
  const navigate = useNavigate();
  const { toaster } = useToaster();

  const fetchUserId = async (user: User): Promise<void> => {
    if (!user || !user.email) return;

    try {
      const { data } = await httpService.post(process.env.REACT_APP_REST_API + "auth/firebase", {
        _id: user.uid,
        email: user.email,
        name: user.displayName,
        picture: user.photoURL
      });

      if (data.resultStatus.success && data.result.token && process.env?.REACT_APP_JWT_TOKEN) {
        localStorage.setItem(process.env.REACT_APP_JWT_TOKEN, data.result.token);
        navigate(AppRoutes.EpisodeList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const signOut: SignOut = async e => {
    e.preventDefault();
    localStorage.removeItem(String(process.env.REACT_APP_JWT_TOKEN));
    localStorage.clear();
    await firebaseAuth.signOut().catch(error => {
      console.error(error);
    });
    navigate(AppRoutes.Home);
  };

  const signIn: SignIn = async (email, password) => {
    await signInWithEmailAndPassword(firebaseAuth, email, password)
      .then(userCredential => {
        fetchUserId(userCredential.user);
      })
      .catch(error => {
        toaster("Please try again!", "error");
        console.error(error);
      });
  };

  const signInWithGoogle: SignInWithGoogle = async e => {
    e.preventDefault();
    const provider = new GoogleAuthProvider();

    await signInWithPopup(firebaseAuth, provider)
      .then(result => {
        fetchUserId(result.user);
      })
      .catch(error => {
        toaster("Please try again!", "error");
        console.error(error);
      });
  };

  const signUp: SignUp = async (email, password) => {
    await createUserWithEmailAndPassword(firebaseAuth, email, password)
      .then(userCredential => {
        fetchUserId(userCredential.user);
      })
      .catch(error => {
        toaster("Sorry, please try again later", "error");
        console.error(error);
      });
  };

  const forgotPassword: ForgotPassword = async email => {
    await sendPasswordResetEmail(firebaseAuth, email)
      .then(data => {
        toaster("Your request has been sent!", "success");
        navigate(AppRoutes.Login);
      })
      .catch(error => {
        toaster("Sorry, please try again later", "error");
        console.error(error);
      });
  };

  return {
    authUser: firebaseAuth.currentUser,
    forgotPassword,
    signOut,
    signIn,
    signInWithGoogle,
    signUp
  };
};

export default useAuthHook;
