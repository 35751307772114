import React from "react";
import { Grid } from "theme-ui";
import _cloneDeep from "lodash/cloneDeep";
import { IUserCommandsCommands, useFetchCommands, useUpdateCommands } from "../../../Api";
import { CommandSubs } from "../../Shared/CommandSubs/CommandSub";

export const Commands: React.FC = () => {
  const [topicVoteState, setTopicVoteState] = React.useState<IUserCommandsCommands[]>([]);

  const {
    data: fetchData,
    isLoading: fetchIsLoading,
    error: fetchError
  } = useFetchCommands("podcastOverlays");

  const {
    data: updateData,
    isLoading: updateIsLoading,
    error: updateError,
    mutate: updateMutate
  } = useUpdateCommands();

  React.useEffect(() => {
    if (!fetchData || fetchIsLoading || fetchError) return;

    setTopicVoteState(fetchData.commands);
  }, [fetchData, fetchIsLoading, fetchError]);

  React.useEffect(() => {
    if (!updateData || updateIsLoading || updateError) return;

    setTopicVoteState(prev => {
      const newState = _cloneDeep(prev);
      const index = newState.findIndex(f => f._id === updateData.setting._id);
      newState[index] = { ...newState[index], status: Boolean(updateData.setting.status) };

      return [...newState];
    });
  }, [updateData, updateIsLoading, updateError]);

  const handleCheckbox = async (_id: string): Promise<void> => {
    const newState = _cloneDeep(topicVoteState);
    const index = newState.findIndex(f => f._id === _id);

    if (!newState?.[index]) return;
    newState[index].status = !newState[index].status;
    updateMutate(newState[index]);
  };

  const sections = [
    {
      subType: "common",
      title: "Common"
    },

    {
      subType: "hostVoting",
      title: "Host Voting"
    },

    {
      subType: "topicVoting",
      title: "Topic Voting"
    },

    {
      subType: "chatVoting",
      title: "Chatter Voting"
    }
  ];

  return (
    <>
      <Grid columns={["1fr", " 1fr"]}>
        {sections.map(section => (
          <CommandSubs
            data={topicVoteState}
            displayType="podcast"
            handleCheckbox={handleCheckbox}
            subType={section.subType}
            title={section.title}
            key={section.subType}
          />
        ))}
      </Grid>
    </>
  );
};
