import React from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Helmet } from "react-helmet";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { AppRouter } from "./Routes";
import { PaperProvider } from "./theme/themeProvider";
import { Flex } from "theme-ui";
import { AppHydration } from "./AppHydration";

const App: React.FC = () => {
	const queryClient = new QueryClient();

	React.useEffect(() => {
		return () => {
			console.log("cleanup");
			queryClient.clear();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>GTK | Gamer Toolkit</title>
				<meta name="description" content="Podcasting Overlays"></meta>
			</Helmet>

			<ToastContainer />
			<GoogleOAuthProvider clientId={process.env?.REACT_APP_GOOGLE_CLIENT_ID || ""}>
				<QueryClientProvider client={queryClient}>
					<ReactQueryDevtools initialIsOpen={false} />
					<AppHydration />
					<PaperProvider>
						<Flex>
							<AppRouter />
						</Flex>
					</PaperProvider>
				</QueryClientProvider>
			</GoogleOAuthProvider>
		</>
	);
};

export default App;
