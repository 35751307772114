import React from "react";
import Icon from "@mdi/react";
import { mdiMenuDown } from "@mdi/js";

import { Box, Flex } from "theme-ui";
import { useAuth, useToken } from "../../../../Hooks";
import * as styles from "./HeaderUser.styles";

type IHeaderUserProps = {
  isUserMenuOpen: boolean;
  setIsUserMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userMenuButtonRef: React.RefObject<HTMLDivElement>;
};

export const HeaderUser: React.FC<IHeaderUserProps> = ({
  isUserMenuOpen,
  setIsUserMenuOpen,
  userMenuButtonRef
}) => {
  const { authUser } = useAuth();
  const { verifiedToken } = useToken();

  React.useEffect(() => {
    const toggleRefCurrent = userMenuButtonRef.current;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" || event.key === " ") {
        handleToggleUserMenu();
      }
    };

    if (toggleRefCurrent) {
      toggleRefCurrent.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (toggleRefCurrent) {
        toggleRefCurrent.removeEventListener("keydown", handleKeyDown);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleToggleUserMenu = () => {
    setIsUserMenuOpen((prevState: boolean) => !prevState);
  };

  return (
    <Flex
      aria-label="User Account Menu Button"
      aria-expanded={isUserMenuOpen ? true : undefined}
      onClick={handleToggleUserMenu}
      ref={userMenuButtonRef}
      role="button"
      tabIndex={0}
      sx={styles.userMenuWrapper}
    >
      <Flex sx={styles.userIcon}>
        {authUser?.photoURL ? (
          <img src={authUser?.photoURL} alt="user avatar" />
        ) : (
          verifiedToken?.name?.charAt(0).toUpperCase()
        )}
      </Flex>

      <Box sx={styles.username}>{verifiedToken?.name}</Box>
      <div>
        <Icon path={mdiMenuDown} size="24" />
      </div>
    </Flex>
  );
};
