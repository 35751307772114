import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Box, Button, Grid, Input } from "theme-ui";
import { AppRoutes } from "../../../../Types";
import { Pagination } from "./Pagination";
import { Card } from "../../../../theme/UI";
import { useSearchMessages } from "../../../../Api";
import { CannedMessage } from "../../../../Api/Types";

export interface CannedMessageListProps {
  handleNewMessageOpen?: () => void;
  handleOpenDrawer: () => void;
  setActiveMessage: React.Dispatch<React.SetStateAction<CannedMessage | undefined>>;
  setListState: React.Dispatch<React.SetStateAction<CannedMessageListState | null>>;
  listState: CannedMessageListState | null;
}

export type CannedMessageListState = {
  currentPage: string | number;
  messages: CannedMessage[];
  totalPages: number;
};

export const CannedMessageList: React.FC<CannedMessageListProps> = ({
  handleNewMessageOpen,
  handleOpenDrawer,
  setActiveMessage,
  setListState,
  listState
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [sort, setSort] = React.useState<string>("airDate");
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [sortDirection, setSortDirection] = React.useState<string>("desc");

  const { data, error, isLoading, mutate } = useSearchMessages();

  React.useEffect(() => {
    const fetchDir = queryParams.get("dir") || "desc";
    const fetchSort = queryParams.get("sort") || "message";
    const fetchSearchTerm = queryParams.get("searchTerm") || "";

    setSortDirection(fetchDir);
    setSort(fetchSort);
    setSearchTerm(fetchSearchTerm);

    mutate({
      order: fetchDir,
      page: (queryParams.get("page") as unknown as number) || 1,
      sort: fetchSort,
      searchTerm: fetchSearchTerm
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isLoading && !error) return;
    data && setListState(data);
  }, [data, isLoading, error, setListState]);

  const handlePageChange = async (page: number) => {
    mutate({
      order: sortDirection,
      page,
      sort,
      searchTerm
    });

    let navLink = `${AppRoutes.CannedMessagesList}?page=1&sort=${sort}&dir=${sortDirection}`;
    navLink += searchTerm && `&searchTerm=${searchTerm}`;
    navigate(navLink);
  };

  const handleFormSubmit = async () => {
    handlePageChange(1);
    setActiveMessage(undefined);
  };

  const handleMessageSelect = (message: CannedMessage) => {
    setActiveMessage(message);
    handleOpenDrawer();
  };

  return (
    <>
      <Card variant="fullPage">
        <Card.Header>
          <Box>Canned Messages</Box>
          <Button onClick={handleNewMessageOpen}>Add Canned Message</Button>
        </Card.Header>

        <Card.HeaderForm>
          <Box>
            <Input
              type="text"
              placeholder="Search Message"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />

            <Box />

            <Button onClick={handleFormSubmit}>Search</Button>
          </Box>
        </Card.HeaderForm>

        <Card.Body>
          <Grid variant="cannedMessageTable">
            {listState?.messages.map((message: CannedMessage) => (
              <Grid
                aria-label={`Canned Message List Item: ${message.message}`}
                key={message._id}
                variant="cannedMessageList"
                onClick={() => handleMessageSelect(message)}
              >
                <Box sx={{ backgroundColor: "#212121" }}></Box>

                <Box>
                  <div>{message.name}</div>
                  <div>{message.message}</div>
                </Box>
              </Grid>
            ))}

            <Pagination
              currentPage={(listState?.currentPage as number) || 0}
              handlePageChange={handlePageChange}
              totalPages={listState?.totalPages || 0}
            />
          </Grid>
        </Card.Body>
      </Card>
    </>
  );
};
