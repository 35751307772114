import { ThemeUIProvider } from "theme-ui";
import { theme } from "./theme";

interface ThemeProviderProps {
  children: React.ReactNode;
}

export const PaperProvider: React.FC<ThemeProviderProps> = ({ children }) => (
  <ThemeUIProvider theme={theme}>{children}</ThemeUIProvider>
);
