import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import httpService from "../../Api/httpService";
import { Card, Container } from "../../theme/UI";
import { Box, Button, Flex } from "theme-ui";

import { Config } from "../../Config/Config";

type Topic = {
  _id: string;
  articles: string;
  desc: string;
  img: string;
  name: string;
  notes: string;
  video: string;
};

type Episode = {
  _id: string;
  airDate: string;
  logo: string;
  name: string;
  number: number;
  podcastName: string;
  topics: Topic[];
};

export const YoutubeDesc: React.FC = () => {
  const { id } = useParams();
  const [useTitle, setUseTitle] = React.useState<boolean>(true);
  const [useDescription, setUseDescription] = React.useState<boolean>(true);

  const removeCurlyBraces = (str: string) => {
    return str.replace(/{{.*?}}/g, "");
  };

  const [episodeState, setEpisodeState] = React.useState<Episode>({
    _id: "",
    airDate: "",
    logo: "",
    name: "",
    number: 0,
    podcastName: "",
    topics: []
  });

  React.useEffect(() => {
    //
  }, []);

  React.useEffect(() => {
    let stillHere = true;

    const fetchData = async () => {
      const { data } = await httpService.get(`episode-data/show-runner/${id}`);

      if (stillHere) {
        setEpisodeState({
          ...data.result.episodeData
        });
      }
    };

    fetchData();

    return () => {
      stillHere = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>GTK | Youtube Description: {episodeState.name}</title>

        <meta property="og:title" content={episodeState.name} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={`/public/youtube-desc/${episodeState._id}`} />

        {episodeState.logo && (
          <meta
            property="og:image"
            content={`${Config.REACT_APP_CLOUD_IMAGES}/${episodeState.logo}`}
          />
        )}
      </Helmet>

      <Container sx={{ width: "100vw", padding: "1rem" }}>
        <Box>
          <Card sx={{ marginTop: "1rem" }}>
            <Card.Body>
              <Box>
                <p>{episodeState.name}</p>
              </Box>
            </Card.Body>
          </Card>
        </Box>
        <Box>
          <Box>
            <Card sx={{ marginTop: "1rem" }}>
              <Card.Body>
                <Box>
                  <p>Date: {episodeState.airDate}</p>
                  <p>Episode #{episodeState.number}</p>
                  <p>Topics: {episodeState.topics.length}</p>
                </Box>
              </Card.Body>
            </Card>

            <Card sx={{ marginTop: "1rem" }}>
              <Card.Body>
                <Flex sx={{ gap: 2 }}>
                  <Button onClick={() => setUseTitle(!useTitle)}>Title</Button>
                  <Button onClick={() => setUseDescription(!useDescription)}>Description</Button>
                </Flex>
              </Card.Body>
            </Card>

            <Card sx={{ marginTop: "1rem" }}>
              <Card.Body>
                {episodeState.podcastName}
                <br />
                Episode {episodeState.number}: {episodeState.name}
              </Card.Body>
              <Card.Body sx={{ lineHeight: 1.5 }}>
                {episodeState.topics?.map((topic: Topic) => (
                  <React.Fragment key={topic._id}>
                    {(useTitle || useDescription) && "0:00 - "}
                    {useTitle && topic.name}
                    {useTitle && useDescription && ": "}
                    {useDescription && removeCurlyBraces(topic.desc)}
                    <br />
                  </React.Fragment>
                ))}
              </Card.Body>
            </Card>
          </Box>
        </Box>
      </Container>
    </>
  );
};
