import { AxiosError } from "axios";
import { useMutation, useQueryClient } from "react-query";
import httpService from "../httpService";
import { ApiResponse } from "../Types";
import { IEpisodeTopic } from "../../Types";
import { useToaster } from "../../Hooks";
import { useEpisodeDataStore } from "../../DataStores";

type UpdateEpisodeTopicVariables = { episodeId: string; topic: IEpisodeTopic };

type UpdateEpisodeTopicResponse = {
  activeIndex: number;
  topics: IEpisodeTopic[];
};

const updateTopic = async (
  episodeId: string,
  topic: IEpisodeTopic
): Promise<UpdateEpisodeTopicResponse> => {
  const { data } = await httpService.put<ApiResponse<UpdateEpisodeTopicResponse>>(
    `episode-topics/${episodeId}`,
    topic
  );

  return data.result;
};

export const useUpdateTopic = () => {
  const { toaster } = useToaster();
  const { setSectionLoading, setTopics } = useEpisodeDataStore();
  const queryClient = useQueryClient();
  return useMutation<
    UpdateEpisodeTopicResponse,
    AxiosError<{ error: unknown }>,
    UpdateEpisodeTopicVariables
  >(["update-episode-topic"], ({ episodeId, topic }) => updateTopic(episodeId, topic), {
    retry: 0,
    onMutate: () => {
      setSectionLoading("topic", true);
    },
    onSuccess: data => {
      if (data) {
        toaster("Topic Updated");
        setTopics(data.topics, data.activeIndex);
        queryClient.invalidateQueries(["update-episode-topic"]);
      }
    },
    onSettled: () => {
      setSectionLoading("topic", false);
    }
  });
};
