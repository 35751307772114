import React from "react";
import jwtDecode from "jwt-decode";
import { Tooltip } from "react-tooltip";
import { Card } from "../../../theme/UI";
import { Box, Flex, Grid } from "theme-ui";
import Icon from "@mdi/react";
import { mdiContentCopy, mdiOpenInNew } from "@mdi/js";
import { useCopy } from "../../../Hooks";
import { overlayActions, overlayVideoActions, overlayVotingActions } from "./ControlArrays";
import { useCommonDataStore } from "../../../DataStores";

type ControlLinks = {
	name: string;
	url: string;
};

export const Controls: React.FC = () => {
	const [userId, setUserId] = React.useState<string>("");
	const { copyText } = useCopy();
	const { templates } = useCommonDataStore();

	React.useEffect(() => {
		if (process.env.REACT_APP_JWT_TOKEN) {
			const { _id } = jwtDecode(localStorage.getItem(process.env.REACT_APP_JWT_TOKEN) || "") as {
				_id: string;
			};

			setUserId(_id);
		}
	}, []);

	const links: ControlLinks[] = [
		{
			name: "Overlay Controls Dock",
			url: `${process.env.REACT_APP_CONTROL_URL}/controlDock/${userId}`
		},
		{
			name: "Chat Dock",
			url: `${process.env.REACT_APP_CONTROL_URL}/chatDock/${userId}`
		}
	];

	const handleLink = (link: string): void => {
		window.open(link, "_blank", "noreferrer");
	};

	const handleCopyControls = (action: string, socket: string = "gtkOverlayAction"): void => {
		const controlUrl = `${process.env.REACT_APP_REST_API}socket/client/overlay-controls?uid=${userId}&socket=${socket}&action=${action}`;
		copyText(controlUrl);
	};

	const handleTemplateCopy = (templateId: string): void => {
		const templateLink = `${process.env.REACT_APP_REST_API}user-templates/client?uid=${userId}&tid=${templateId}`;
		copyText(templateLink);
	};

	const handleCopyVoting = (action: string): void => {
		const controlUrl = `${process.env.REACT_APP_REST_API}socket/client/overlay-controls/host-vote?uid=${userId}&socket=gtkVoting&action=${action}`;
		copyText(controlUrl);
	};

	return (
		<>
			<Card>
				<Card.Header>
					<Box>Overlay Controls (OBS Docks)</Box>
				</Card.Header>

				<Card.Body>
					<Grid variant="table">
						{links.map((item, index) => (
							<Grid key={index} variant="controlList">
								<Flex sx={{ gap: ".5rem" }}>
									<Box
										data-tooltip-id={`my-tooltip-${index}-${item.name}}-copy`}
										onClick={() => copyText(item.url)}
										sx={{ cursor: "pointer" }}
									>
										<Icon path={mdiContentCopy} size={0.75} />
										<Tooltip
											id={`my-tooltip-${index}-${item.name}}-copy`}
											place="top"
											variant="info"
											content="Copy"
										/>
									</Box>

									<Box
										data-tooltip-id={`my-tooltip-${index}-${item.name}}-view`}
										onClick={() => handleLink(item.url)}
										sx={{ cursor: "pointer" }}
									>
										<Icon path={mdiOpenInNew} size={0.75} />
										<Tooltip
											id={`my-tooltip-${index}-${item.name}}-view`}
											place="top"
											variant="info"
											content="View"
										/>
									</Box>
								</Flex>

								<Box>{item.name}</Box>
							</Grid>
						))}
					</Grid>
				</Card.Body>
			</Card>

			<Card sx={{ marginTop: "2rem" }}>
				<Card.Header>
					<Box>Activate Templates</Box>
				</Card.Header>
				<Card.Body>
					<Grid variant="table">
						{templates.map((template, index) => (
							<Grid key={index} variant="controlList">
								<Box
									data-tooltip-id={`control-tooltip-${index}-${template.name}}-copy`}
									onClick={() => handleTemplateCopy(template._id)}
									sx={{ cursor: "pointer" }}
								>
									<Icon path={mdiContentCopy} size={0.75} />
									<Tooltip
										id={`control-tooltip-${index}-${template.name}}-copy`}
										place="top"
										variant="info"
										content="Copy"
									/>
								</Box>

								<Box>{template.name}</Box>
							</Grid>
						))}
					</Grid>
				</Card.Body>
			</Card>

			<Card sx={{ marginTop: "2rem" }}>
				<Card.Header>
					<Box>Overlay Controls</Box>
				</Card.Header>
				<Card.Body>
					<Grid variant="table">
						{overlayActions.map((item, index) => (
							<Grid key={index} variant="controlList">
								<Box
									data-tooltip-id={`control-tooltip-${index}-${item.name}}-copy`}
									onClick={() => handleCopyControls(item.action, item?.type)}
									sx={{ cursor: "pointer" }}
								>
									<Icon path={mdiContentCopy} size={0.75} />
									<Tooltip
										id={`control-tooltip-${index}-${item.name}}-copy`}
										place="top"
										variant="info"
										content="Copy"
									/>
								</Box>

								<Box>{item.name}</Box>
							</Grid>
						))}
					</Grid>
				</Card.Body>
			</Card>

			<Card sx={{ marginTop: "2rem" }}>
				<Card.Header>
					<Box>Host Voting</Box>
				</Card.Header>
				<Card.Body>
					<Grid variant="table">
						{overlayVotingActions.map((item, index) => (
							<Grid key={index} variant="controlList">
								<Box
									data-tooltip-id={`control-tooltip-${index}-${item.name}}-copy`}
									onClick={() => handleCopyVoting(item.action)}
									sx={{ cursor: "pointer" }}
								>
									<Icon path={mdiContentCopy} size={0.75} />
									<Tooltip
										id={`control-tooltip-${index}-${item.name}}-copy`}
										place="top"
										variant="info"
										content="Copy"
									/>
								</Box>

								<Box>{item.name}</Box>
							</Grid>
						))}
					</Grid>
				</Card.Body>
			</Card>

			<Card sx={{ marginTop: "2rem" }}>
				<Card.Header>
					<Box>Overlay Video Controls</Box>
				</Card.Header>
				<Card.Body>
					<Grid variant="table">
						{overlayVideoActions.map((item, index) => (
							<Grid key={index} variant="controlList">
								<Box
									data-tooltip-id={`video-tooltip-${index}-${item.name}}-copy`}
									onClick={() => handleCopyControls(item.action, "gtkOverlayVideoPlayer")}
									sx={{ cursor: "pointer" }}
								>
									<Icon path={mdiContentCopy} size={0.75} />
									<Tooltip
										id={`video-tooltip-${index}-${item.name}}-copy`}
										place="top"
										variant="info"
										content="Copy"
									/>
								</Box>

								<Box>{item.name}</Box>
							</Grid>
						))}
					</Grid>
				</Card.Body>
			</Card>
		</>
	);
};

// http://localhost:8002/api/v1/socket/client/overlay-controls/host-vote?uid=640bef8f88f7663004024d65&socket=gtkVoting&action=!sv1
