import * as React from "react";
import { useBreakpointIndex } from "@theme-ui/match-media";
import { Grid } from "theme-ui";
import { Drawer } from "../../../../theme/UI/Drawer";

import { EpisodeList } from "./EpisodeList/EpisodeList";
import { EpisodeNew } from "./EpisodeNew/EpisodeNew";

export const EpisodeListing: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);
  const breakPointIndex = useBreakpointIndex();
  const columns = ["1fr", "1fr", "5fr 2fr"];

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  return (
    <>
      <Grid columns={columns}>
        <EpisodeList handleOpenDrawer={handleOpenDrawer} />
        {breakPointIndex > 1 && <EpisodeNew />}
      </Grid>

      {breakPointIndex <= 1 && (
        <Drawer
          isDrawerOpen={isDrawerOpen}
          handleCloseDrawer={handleCloseDrawer}
          title="Create New Episode"
        >
          <EpisodeNew showHeader={false} />
        </Drawer>
      )}
    </>
  );
};
