export const fontSizes = [0, ".75rem", "0.875rem", "1rem", "1.25rem", "1.5rem", "2rem"];

export const lineHeights = {
  content: 1
};

export const fontWeights = {
  normal: 400,
  medium: 500,
  bold: 700,
  black: 900
};

export const letterSpacings = {
  normal: "normal",
  tracked: "0.1em",
  tight: "-0.05em",
  mega: "0.5rem"
};
