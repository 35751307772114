import React from "react";
import { useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { Box, Button, Checkbox, Flex, Grid, Label } from "theme-ui";
import { Card } from "../../../../../theme/UI";
import { useBreakpointIndex } from "@theme-ui/match-media";
import { ILinkArray, ITemplate, StorageKeys } from "../../../../../Types";
import jwtDecode from "jwt-decode";
import Icon from "@mdi/react";
import { mdiContentCopy, mdiOpenInNew } from "@mdi/js";
import { openPreview } from "./Utils/openPreview";
import { EpisodePreview } from "./Preview/Preview";
import { useFetchEpisode } from "../../../../../Api/useEpisodes/useEpisodes";
import { useCopy, useStorage } from "../../../../../Hooks";

import { IFrameColorPicker } from "./Preview/ColorPicker";

type LinkParams = {
	[key: string]: boolean;
};

export const ProfileBrowserLinks: React.FC = () => {
	const { id } = useParams();
	const { copyText } = useCopy();
	const breakPointIndex = useBreakpointIndex();
	const [userId, setUserId] = React.useState<string>("");
	const [linkParams, setLinkParams] = React.useState<LinkParams>();
	const [template, setTemplate] = React.useState<ITemplate | null>(null);
	const { setLocalStorage, getLocalStorage } = useStorage();
	const [iFrameBgColor, setIFrameBgColor] = React.useState("#ddd");
	const [showColorPicker, setShowColorPicker] = React.useState(false);

	const LINK = `${process.env.REACT_APP_CLOUD_OVERLAY_URL}${template?._id}&uid=${userId}`;

	const storageKey = `${StorageKeys.LinkParams}${id}`;
	const previewBgKey = `${StorageKeys.OverlayPreviewBgColor}${id}`;

	const { data, isLoading, error } = useFetchEpisode(id as string);

	React.useEffect(() => {
		let stillHere = true;
		if (error || isLoading || !stillHere) return;

		if (!data?.template) return;

		setTemplate(data.template);

		const linkParamsObj: LinkParams = {};

		data.template.linkArray.forEach((item: ILinkArray) => {
			linkParamsObj[item.param] = false;
		});

		const storageData = getLocalStorage(storageKey);

		const params = {
			...linkParamsObj,
			...storageData
		};

		setLinkParams(params);

		const previewBgColor = getLocalStorage(previewBgKey);
		previewBgColor && setIFrameBgColor(previewBgColor);

		return () => {
			stillHere = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, error, isLoading, getLocalStorage]);

	React.useEffect(() => {
		if (!linkParams) return;
		setLocalStorage(storageKey, linkParams);
	}, [linkParams, storageKey, setLocalStorage]);

	React.useEffect(() => {
		if (process.env.REACT_APP_JWT_TOKEN) {
			const { _id } = jwtDecode(localStorage.getItem(process.env.REACT_APP_JWT_TOKEN) || "") as {
				_id: string;
			};
			setUserId(_id);
		}
	}, []);

	const handleCopyLink = (param: string, link = false) => {
		const str = param ? `${LINK}&${param}=1` : `${LINK}`;

		if (link) {
			window.open(str, "_blank");
			return;
		}

		copyText(str);
	};

	const handleCheckbox = (checked: boolean, param: string): void => {
		setLinkParams({ ...linkParams, [param]: checked });
	};

	const handleSelectAllCheckBoxes = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		value: boolean = true
	): void => {
		const obj: LinkParams = {};
		template &&
			template.linkArray.forEach((item: ILinkArray) => {
				obj[item.param] = value;
			});

		setLinkParams(obj);
	};

	const copyCurrentEpisodePreviewLink = (): void => {
		const link = openPreview("current", template?._id as string, null, linkParams);
		if (link) {
			copyText(link);
		}
	};

	const handlePreviewBGColor = (color: any) => {
		setIFrameBgColor(color.hex);
		setLocalStorage(previewBgKey, color.hex);
	};

	const anyChecked = Object.values(linkParams || {}).some(Boolean);

	const previewSrc = anyChecked
		? openPreview("episode", template?._id as string, id as string, linkParams)
		: null;

	const columns = ["1fr", "1fr", "1fr 2fr"];

	return (
		<>
			<Grid columns={columns}>
				<Card variant="profileFullPage">
					<Card.Header>
						<Box>Browser Links</Box>
						<Flex sx={{ gap: ".5rem" }}>
							<Button onClick={e => handleSelectAllCheckBoxes(e, !anyChecked)}>
								{anyChecked ? "Clear All" : "Check all"}
							</Button>
							<Button
								disabled={!anyChecked}
								onClick={() =>
									openPreview("episode", template?._id as string, id as string, linkParams, true)
								}
							>
								Preview
							</Button>
							<Button disabled={!anyChecked} onClick={copyCurrentEpisodePreviewLink}>
								Copy
							</Button>
						</Flex>
					</Card.Header>
					{breakPointIndex <= 1 && (
						<EpisodePreview iFrameBgColor={iFrameBgColor} previewSrc={previewSrc} />
					)}

					<Card.Body>
						<Grid variant="table" sx={{ gridTemplateColumns: ["1fr", "1fr", "1fr"] }}>
							{template?.linkArray.map((link, index) => (
								<Grid key={index} variant="browserLinkList">
									<Box>
										<Label sx={{ margin: 0, padding: 0 }}>
											<Checkbox
												checked={!!linkParams?.[link.param]}
												onChange={e => handleCheckbox(e.target.checked, link.param)}
											/>
										</Label>
									</Box>

									<Box>{link.name}</Box>
									<Flex sx={{ gap: ".5rem" }}>
										<Box
											data-tooltip-id={`my-tooltip-${index}-${link.param}}-copy`}
											onClick={() => handleCopyLink(link.param)}
											sx={{ cursor: "pointer" }}
										>
											<Icon path={mdiContentCopy} size={0.75} />
											<Tooltip
												id={`my-tooltip-${index}-${link.param}}-copy`}
												place="top"
												variant="info"
												content="Copy"
											/>
										</Box>
										<Box
											data-tooltip-id={`my-tooltip-${index}-${link.param}}-view`}
											onClick={() => handleCopyLink(link.param, true)}
											sx={{ cursor: "pointer" }}
										>
											<Icon path={mdiOpenInNew} size={0.75} />
											<Tooltip
												id={`my-tooltip-${index}-${link.param}}-view`}
												place="top"
												variant="info"
												content="View"
											/>
										</Box>
									</Flex>
								</Grid>
							))}
						</Grid>
					</Card.Body>
				</Card>
				{breakPointIndex > 1 && (
					<Card>
						<Card.Body
							sx={{
								padding: " .5rem .5rem",
								position: "relative",
								overflow: "hidden"
							}}
						>
							<IFrameColorPicker
								{...{
									handlePreviewBGColor,
									showColorPicker,
									setShowColorPicker
								}}
							/>

							<EpisodePreview iFrameBgColor={iFrameBgColor} previewSrc={previewSrc} />
						</Card.Body>
					</Card>
				)}
			</Grid>
		</>
	);
};
