import React from "react";
import { Box, Button, Grid } from "theme-ui";
import * as styles from "./styles";
import { ShowRunnerTopicImage } from "./utils/ShowRunnerTopicImage";
import { Topic } from "./types";
import { ShowRunnerTopicNotes } from "./ShowRunnerTopicNotes";
import { ShowRunnerVideo } from "./ShowRunnerVideo";
import { Collapse, Divider } from "../../theme/UI";

interface ShowRunnerTopicProps {
  logo: string;
  topic: Topic;
}

export const ShowRunnerTopic: React.FC<ShowRunnerTopicProps> = ({ logo, topic }) => {
  const [isVideoPlayerOpen, setIsVideoPlayerOpen] = React.useState(false);
  const [isProdNotesOpen, setIsProdNotesOpen] = React.useState(false);

  const toggleVideoOpen = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsVideoPlayerOpen(!isVideoPlayerOpen);
  };

  const handleProdNotesToggle = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsProdNotesOpen(!isProdNotesOpen);
  };

  const handleOpenArticle = (e: React.MouseEvent) => {
    e.preventDefault();
    window.open(topic.articles.trim(), "_blank");
  };

  return (
    <Box sx={styles.TopicCard}>
      <Grid key={topic._id} sx={styles.TopicCardGrid}>
        <Box>
          <ShowRunnerTopicImage image={topic.img} name={topic.name} logo={logo} />
        </Box>
        <Box>
          <Box sx={{ fontWeight: "600", mb: "4px" }}>{topic.name}</Box>
          <Box sx={{ whiteSpace: "pre-wrap" }}>{topic.desc}</Box>
          <Box sx={styles.Options}>
            {topic?.articles?.trim() && <Button onClick={handleOpenArticle}>Article</Button>}
            {topic?.content?.file && <Button onClick={toggleVideoOpen}>Content</Button>}
            {topic?.notes && <Button onClick={handleProdNotesToggle}> Notes</Button>}
          </Box>

          <Collapse title="Video" isOpen={isProdNotesOpen}>
            <Box my={0} sx={{ whiteSpace: "pre-wrap" }}>
              <Divider sx={{ color: "#333" }} />
              {topic.notes}
            </Box>
          </Collapse>

          <ShowRunnerVideo isVideoPlayerOpen={isVideoPlayerOpen} content={topic.content} />
          <ShowRunnerTopicNotes hostNotes={topic.hostNotes} id={topic._id} />
        </Box>
      </Grid>
    </Box>
  );
};
