import { Box, Card as ThemeCard, ThemeUIStyleObject } from "theme-ui";

interface ICardProps {
  children: React.ReactNode;
  sx?: ThemeUIStyleObject;
  variant?: string;
}

interface ICardExt {
  Body: React.FC<ICardProps>;
  Footer: React.FC<ICardProps>;
  Header: React.FC<ICardProps>;
  HeaderForm: React.FC<ICardProps>;
}

export const Card: React.FC<ICardProps> & ICardExt = ({
  children,
  sx = {},
  variant = "primary"
}) => {
  return (
    <ThemeCard sx={sx} variant={variant}>
      {children}
    </ThemeCard>
  );
};

const CardHeader: React.FC<ICardProps> = ({
  children,
  sx = {},
  variant = "cards.header"
}) => {
  return (
    <Box sx={sx} variant={variant}>
      {children}
    </Box>
  );
};

const CardHeaderForm: React.FC<ICardProps> = ({
  children,
  sx = {},
  variant = "cards.headerForm"
}) => {
  return (
    <Box sx={sx} variant={variant}>
      {children}
    </Box>
  );
};

const CardBody: React.FC<ICardProps> = ({
  children,
  sx = {},
  variant = "cards.body"
}) => {
  return (
    <Box sx={sx} variant={variant}>
      {children}
    </Box>
  );
};

const CardFooter: React.FC<ICardProps> = ({
  children,
  sx = {},
  variant = "cards.footer"
}) => {
  return (
    <Box sx={sx} variant={variant}>
      {children}
    </Box>
  );
};

Card.Body = CardBody;
Card.Footer = CardFooter;
Card.Header = CardHeader;
Card.HeaderForm = CardHeaderForm;
