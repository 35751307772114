import React from "react";

import { Box, Grid, ThemeUIStyleObject } from "theme-ui";

interface IFieldSetProps {
  children: React.ReactNode;
  mb?: number;
  sx?: ThemeUIStyleObject | null;
  variant?: string;
  isVertical?: boolean;
}

export const FieldSet: React.FC<IFieldSetProps> = ({
  children,
  mb = 3,
  sx = null,
  variant = "",
  isVertical = false
}) => {
  const childCount = React.Children.count(children);

  const columns: { [key: string]: string[] } = {
    "1": ["1fr"],
    "2": ["1fr", "1fr 85%"],
    "3": ["1fr", "1fr 1fr 1fr"]
  };

  const defaultStyle: ThemeUIStyleObject = {
    alignItems: "center",
    justifyContent: "center"
  };

  const defaultStyleVert: ThemeUIStyleObject = {
    display: "flex",
    flexDirection: "column",
    gap: 2
  };

  return isVertical ? (
    <Box sx={sx || defaultStyleVert} variant={variant}>
      {children}
    </Box>
  ) : (
    <Grid
      mb={mb}
      sx={sx || defaultStyle}
      variant={variant}
      columns={columns[childCount]}
    >
      {children}
    </Grid>
  );
};

interface IHintProps {
  children: React.ReactNode;
  mt?: number;
  sx?: ThemeUIStyleObject | null;
  variant?: string;
}

export const Hint: React.FC<IHintProps> = ({
  children,
  mt = 1,
  sx,
  variant
}) => {
  const defaultStyle: ThemeUIStyleObject = {
    backgroundColor: "accent3",
    borderRadius: ".25rem",
    padding: ".5rem"
  };

  return (
    <Box mt={mt} sx={sx || defaultStyle} variant={variant}>
      {children}
    </Box>
  );
};
