import React from "react";
import _cloneDeep from "lodash/cloneDeep";
import _findIndex from "lodash/findIndex";

import { useEpisodeDataStore } from "../../DataStores/useEpisodeDataStore";
import { IEpisodeTopic } from "../../Types";
import { sortingTopics } from "../../Components/PodcastOverlays/Episodes/Profile/Topics/Utils/Sorting";
import { useEpisodeTopicReorder } from "../../Api";

const useDragAndDropTopics = () => {
  const globalDataStore = useEpisodeDataStore();
  const { mutate } = useEpisodeTopicReorder();

  if (globalDataStore === undefined) {
    throw new Error("useGlobalDataHook must be used within a GlobalDataProvider");
  }

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, _id: string) => {
    e.stopPropagation();
    e.dataTransfer.setData("_id", _id);
  };

  const handleDropTopic = async (
    e: React.DragEvent<HTMLDivElement>,
    episodeId: string,
    _id: string
  ): Promise<void> => {
    e.preventDefault();
    const dragId = e.dataTransfer.getData("_id");
    if (dragId === _id) return;

    const tempTopics = _cloneDeep(globalDataStore.topics);

    const dragIndex = _findIndex(tempTopics, (f: IEpisodeTopic) => f._id === dragId);
    const dropIndex = _findIndex(tempTopics, (f: IEpisodeTopic) => f._id === _id);

    const dragTopic = tempTopics[dragIndex];

    if (dragIndex > dropIndex) {
      tempTopics.splice(dragIndex, 1);
      tempTopics.splice(dropIndex, 0, dragTopic);
    }

    if (dragIndex < dropIndex) {
      tempTopics.splice(dropIndex + 1, 0, dragTopic);
      tempTopics.splice(dragIndex, 1);
    }

    const sortedTopics = sortingTopics(tempTopics);

    mutate({ episodeId, topics: sortedTopics });
  };

  return {
    handleDragStart,
    handleDropTopic
  };
};

export default useDragAndDropTopics;
