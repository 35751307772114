import React from "react";

import { Button, Grid, Input } from "theme-ui";
import { Card } from "../../../../theme/UI";
import { CannedMessage } from "../../../../Api/Types";
import { useCreateMessage } from "../../../../Api";

interface CannedMessageNewProps {
  showHeader?: boolean;
  handleNewCallBack: (message: CannedMessage) => void;
  handleNewMessageClose?: () => void;
}

export const CannedMessageNew: React.FC<CannedMessageNewProps> = ({
  showHeader = true,
  handleNewCallBack,
  handleNewMessageClose
}) => {
  const [messageName, setMessageName] = React.useState<string>("");
  const [message, setMessage] = React.useState<string>("");
  const { data, error, isLoading, mutate } = useCreateMessage();

  React.useEffect(() => {
    let stillHere = true;

    if (!isLoading && data && stillHere) {
      handleNewCallBack(data);
      setMessageName("");
      setMessage("");
    }

    return () => {
      stillHere = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, isLoading]);

  const handleFormSubmit = async () => {
    if (!messageName || !message) {
      console.error("Error, Name and Message can not be empty");
      return;
    }

    mutate({ name: messageName, message });
  };

  return (
    <Card>
      {showHeader && (
        <>
          <Card.Header>
            <div>Add Canned Message</div>
          </Card.Header>
        </>
      )}

      <Card.Body>
        <Grid columns={["1fr", "3fr 2fr 80px 80px"]}>
          <Input
            type="text"
            placeholder="Message Name"
            value={messageName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessageName(e.target.value)}
          />

          <Input
            type="text"
            placeholder="Message"
            value={message}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
          />

          <Button disabled={isLoading || !messageName || !message} onClick={handleFormSubmit}>
            <span>Submit</span>
          </Button>

          <Button onClick={handleNewMessageClose} variant="secondary">
            <span>Close</span>
          </Button>
        </Grid>
      </Card.Body>
    </Card>
  );
};
