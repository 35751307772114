import React from "react";
import { Box, Button, Flex, Input, Select } from "theme-ui";
import { Card } from "../../../../theme/UI";
import { IHosts, ISocials } from "../../../../Types";
import _cloneDeep from "lodash/cloneDeep";
import { generateID } from "../../../../Utils";
import httpService from "../../../../Api/httpService";
import { ConfirmDelete } from "../../../Shared/Delete/Delete";
import { useCommonDataStore } from "../../../../DataStores";
import { useToaster } from "../../../../Hooks";

export type Options = {
  hosts: boolean;
  logo: boolean;
  news: boolean;
  socialNetworks: boolean;
  sponsors: boolean;
};

interface EpisodeNewProps {
  activeHost: any;
  showHeader?: boolean;
  handleEditCallBack: (data: any) => void;
  handleDeleteCallBack: (_id: string) => void;
}

const defaultState: IHosts = {
  _id: "",
  name: "",
  socials: []
};

export const HostEdit: React.FC<EpisodeNewProps> = ({
  activeHost,
  handleDeleteCallBack,
  handleEditCallBack,
  showHeader = true
}) => {
  const [activeHostState, setActiveHostState] = React.useState<IHosts>(defaultState);
  const { socials: commonSocials } = useCommonDataStore();
  const [isDeleteColapsed, setIsDeleteColapsed] = React.useState<boolean>(true);
  const { toaster } = useToaster();

  React.useEffect(() => {
    setActiveHostState(activeHost || defaultState);
    setIsDeleteColapsed(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeHost]);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setActiveHostState({ ...activeHostState, [name]: value });
  };

  const handleSocialChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    _id: string
  ) => {
    if (!activeHostState) return;
    const { name, value } = e.target;
    const newSocials = _cloneDeep(activeHostState.socials);
    const socialIndex = newSocials.findIndex(social => social._id === _id);

    if (socialIndex === -1) return;
    newSocials[socialIndex] = { ...newSocials[socialIndex], [name]: value };
    setActiveHostState({ ...activeHostState, socials: newSocials });
  };

  const handleAddSocial = () => {
    if (!activeHostState) return;
    const newSocials = _cloneDeep(activeHostState.socials);
    newSocials.push({
      _id: generateID(),
      site: commonSocials?.[0]?.site || "",
      username: ""
    });
    setActiveHostState({ ...activeHostState, socials: newSocials });
  };

  const handleDeleteSocial = (_id: string) => {
    if (!activeHostState) return;
    const newSocials = _cloneDeep(activeHostState.socials);
    const newState = newSocials.filter(social => social._id !== _id);
    setActiveHostState({ ...activeHostState, socials: newState });
  };

  const handleFormSubmit = async () => {
    if (!activeHostState) {
      console.error("Error, Name and Template can not be empty");
      return;
    }

    const { data } = await httpService.put(
      `${process.env.REACT_APP_REST_API}podcast-hosts/${activeHostState._id}`,
      activeHostState
    );

    if (data?.resultStatus?.success) {
      toaster("Host Updated");
      theCallback();
    }
  };

  const deleteHost = async () => {
    if (!activeHostState) return;
    const _id = activeHostState._id;
    const { data } = await httpService.delete(
      `${process.env.REACT_APP_REST_API}podcast-hosts/${_id}`
    );

    if (data?.resultStatus?.success) {
      setIsDeleteColapsed(true);
      handleDeleteCallBack(_id);
    }
  };

  const theCallback = () => {
    if (!activeHostState) return;
    handleEditCallBack(activeHostState);
  };

  return (
    <Card>
      {showHeader && (
        <>
          <Card.Header>
            <div>Host Edit</div>
          </Card.Header>
        </>
      )}

      <Card.Body>
        <Flex sx={{ flexDirection: "column", gap: "1rem" }}>
          <Input
            name="name"
            onChange={handleFormChange}
            placeholder="Name"
            sx={{ mt: ".375rem" }}
            type="text"
            value={activeHostState.name}
            disabled={!activeHostState?._id}
          />

          <Button disabled={!activeHostState?._id} onClick={handleFormSubmit} variant="loading">
            Submit Changes
          </Button>
        </Flex>
      </Card.Body>

      {activeHostState?._id && activeHostState?.socials.length === 0 && (
        <>
          <Card.Body>
            <Button
              onClick={handleAddSocial}
              sx={{ width: ["100%", "100%", "auto"] }}
              variant="secondary"
            >
              Add Social
            </Button>
          </Card.Body>
        </>
      )}

      <Card.Body>
        {activeHostState?.socials.map((social: ISocials, index: number) => (
          <Box
            key={social._id}
            mb={4}
            pt={4}
            sx={{ borderTop: "1px dashed", borderColor: "accent3" }}
          >
            <Box>
              <Select
                name="site"
                onChange={e => handleSocialChange(e, social._id)}
                value={social.site}
              >
                {!activeHostState.socials[index].site && <option value="">Select Social</option>}

                {commonSocials.map(site => (
                  <option key={site._id} value={site.site}>
                    {site.site}
                  </option>
                ))}
              </Select>
            </Box>
            <Box mt={3}>
              <Input
                name="username"
                onChange={e => handleSocialChange(e, social._id)}
                value={social.username}
              />
            </Box>
            <Flex mt={3} sx={{ justifyContent: "space-between" }}>
              <Flex>
                <Button onClick={handleAddSocial} variant="secondary">
                  Add Social
                </Button>
                <Button ml={2} onClick={handleFormSubmit}>
                  Submit
                </Button>
              </Flex>

              <Button ml={2} onClick={() => handleDeleteSocial(social._id)} variant="danger">
                Delete
              </Button>
            </Flex>
          </Box>
        ))}
      </Card.Body>

      <ConfirmDelete
        deleteMethod={deleteHost}
        isColapsed={isDeleteColapsed}
        isVisible={!!activeHostState?._id}
        toggleColapsed={() => setIsDeleteColapsed(!isDeleteColapsed)}
      />
    </Card>
  );
};
