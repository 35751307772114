import React from "react";
import { Box, Flex, Spinner } from "theme-ui";
import Icon from "@mdi/react";
import { mdiVideoOffOutline } from "@mdi/js";

interface IEpisodePreviewProps {
  iFrameBgColor: string;
  previewSrc: string | null;
}

export const EpisodePreview: React.FC<IEpisodePreviewProps> = ({
  iFrameBgColor,
  previewSrc
}) => {
  const frameRef = React.useRef<HTMLIFrameElement>(null);
  const [ratio, setRatio] = React.useState<number>(0);
  const [showIframe, setShowIframe] = React.useState(false);

  React.useEffect(() => {
    const setDimensions = () => {
      if (!frameRef.current) return;

      const ratio = frameRef.current.clientWidth / 1920;
      setRatio(ratio);
    };

    setDimensions();

    const resizeObserver = new ResizeObserver(() => {
      setDimensions();
    });

    if (frameRef.current) {
      resizeObserver.observe(frameRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  React.useEffect(() => {
    setShowIframe(false);

    const timeout = setTimeout(() => {
      setShowIframe(true);
    }, 800);

    return () => clearTimeout(timeout);
  }, [previewSrc]);

  const height = 1080 * ratio + "px";

  return (
    <>
      <Box ref={frameRef} sx={{ width: "100%", height: height }}>
        <Box
          sx={{
            width: "1920px",
            height: "1080px",
            position: "relative",
            backgroundColor: previewSrc ? "white" : "black",
            transform: `scale(${ratio})`,
            transformOrigin: "left top",
            transition: "backgroundColor 2s ease-in-out"
          }}
        >
          <Flex
            sx={{
              position: "absolute",
              left: "0",
              top: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              transition: "opacity 0.3s ease-in-out",
              opacity: previewSrc ? 0 : 1,
              zIndex: "50",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column"
            }}
          >
            <Icon path={mdiVideoOffOutline} size={10} color="white" />
            <Box sx={{ fontSize: ["3.5rem", "2rem"] }}>
              Select a Link to view
            </Box>
          </Flex>

          <Box
            sx={{
              position: "absolute",
              left: "0",
              top: "0",
              width: "100%",
              height: "100%",
              backgroundColor: "black",
              transition: "opacity 0.3s ease-in-out",
              opacity: showIframe ? 0 : 1,
              zIndex: "50",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Spinner size={300} color="accent3" />
          </Box>
          {showIframe && previewSrc && (
            <iframe
              title="preview"
              src={previewSrc}
              style={{
                position: "absolute",
                left: "0",
                top: "0",
                width: "100%",
                height: "100%",
                border: "none",
                overflow: "hidden",
                zIndex: "40",
                backgroundColor: iFrameBgColor,
                transition: "background-color 0.3s ease-in-out"
              }}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
