import React from "react";

type GetLocalStorage = (key: string) => any;
type SetLocalStorage<T> = (key: string, value: T) => void;

type StorageTypes = unknown;

export interface IUseStorageHook {
  getLocalStorage: GetLocalStorage;
  setLocalStorage: SetLocalStorage<StorageTypes>;
}

const useStorageHook = (): IUseStorageHook => {
  const setLocalStorageBase: SetLocalStorage<StorageTypes> = (key, value) => {
    if (!value) {
      return;
    }

    window.localStorage.setItem(key, JSON.stringify(value));
  };

  const setLocalStorage = React.useCallback<SetLocalStorage<StorageTypes>>(
    (key, value) => setLocalStorageBase(key, value),
    []
  );

  const getLocalStorageBase = (key: string) => {
    const value = window.localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  };

  const getLocalStorage = React.useCallback(
    (key: string) => getLocalStorageBase(key),
    []
  );

  return { getLocalStorage, setLocalStorage };
};

export default useStorageHook;
