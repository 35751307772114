import React from "react";
import { Box, Button, Flex, Grid, Input } from "theme-ui";
import _cloneDeep from "lodash/cloneDeep";
import _findIndex from "lodash/findIndex";

import { Card, Collapse } from "../../../../../theme/UI";
import { NewsTickerNew } from "./NewsTickerNew/NewsTickerNew";

import { mdiDrag, mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import { IEpisodeTicker } from "../../../../../Types";
import { useEpisodeId, useToaster } from "../../../../../Hooks";
import {
  useUpdateEpisodeNews,
  useDeleteEpisodeNews,
  useOrderEpisodeNews,
  useFetchEpisodeNews
} from "../../../../../Api";

interface ProfileNewsTickerProps {}

export const ProfileNewsTicker: React.FC<ProfileNewsTickerProps> = () => {
  const episodeId = useEpisodeId();
  const { toaster } = useToaster();
  const [isNewTickerOpen, setIsNewTickerOpen] = React.useState<boolean>(false);
  const [tickerItems, setTickerItems] = React.useState<IEpisodeTicker[]>([]);
  const [activeItem, setActiveItem] = React.useState<IEpisodeTicker | null>(null);

  const {
    data: newsUpdateData,
    error: newsUpdateError,
    isLoading: newsUpdateIsLoading,
    mutate: newsUpdateMutate
  } = useUpdateEpisodeNews();

  const {
    data: newsDeleteData,
    error: newsDeleteError,
    isLoading: newsDeleteIsLoading,
    mutate: newsDeleteMutate
  } = useDeleteEpisodeNews();

  const {
    data: newsOrderData,
    error: newsOrderError,
    isLoading: newsOrderIsLoading,
    mutate: newsOrderMutate
  } = useOrderEpisodeNews();

  const {
    data: newsFetchData,
    error: newsFetchError,
    isLoading: newsFetchIsLoading
  } = useFetchEpisodeNews(episodeId);

  React.useEffect(() => {
    if (newsFetchData && !newsFetchIsLoading && !newsFetchError) {
      setTickerItems(newsFetchData.news);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsFetchData, newsFetchError, newsFetchIsLoading]);

  const handleCloseNewTicker = () => {
    setIsNewTickerOpen(false);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (!activeItem) return;
    setActiveItem({ ...activeItem, text: e.target.value });
  };

  const handleCloseEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    setActiveItem(null);
  };

  React.useEffect(() => {
    if (newsUpdateData && !newsUpdateIsLoading && !newsUpdateError) {
      setTickerItems(newsUpdateData.news);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsUpdateData, newsUpdateError, newsUpdateIsLoading]);

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): Promise<void> => {
    e.stopPropagation();
    if (!activeItem || !episodeId) return;
    newsUpdateMutate({ episodeId, activeItem });
  };

  const handleDragStart = (e: React.DragEvent<HTMLDivElement>, _id: string) => {
    e.stopPropagation();
    e.dataTransfer.setData("_id", _id);
  };

  const handleDragDrop = async (
    event: React.DragEvent<HTMLDivElement>,
    _id: string
  ): Promise<void> => {
    event.preventDefault();
    if (!episodeId || activeItem?._id === _id) return;
    const dragId = event.dataTransfer.getData("_id");
    if (dragId === _id) return;

    const newTickerItems = _cloneDeep(tickerItems);

    const dragIndex = _findIndex(newTickerItems, (f: IEpisodeTicker) => f._id === dragId);
    const dropIndex = _findIndex(newTickerItems, (f: IEpisodeTicker) => f._id === _id);

    const dragTopic = newTickerItems[dragIndex];

    if (dragIndex > dropIndex) {
      newTickerItems.splice(dragIndex, 1);
      newTickerItems.splice(dropIndex, 0, dragTopic);
    }

    if (dragIndex < dropIndex) {
      newTickerItems.splice(dropIndex + 1, 0, dragTopic);
      newTickerItems.splice(dragIndex, 1);
    }

    newsOrderMutate({ episodeId, news: newTickerItems });
  };

  React.useEffect(() => {
    if (newsOrderData && !newsOrderIsLoading && !newsOrderError) {
      setTickerItems(newsOrderData.news);
      toaster("News Ticker Order Updated", "success");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsOrderData, newsOrderError, newsOrderIsLoading]);

  const handleNewCallBack = React.useCallback(
    (news: IEpisodeTicker[]) => {
      setTickerItems(news);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleRemoveItem = async (e: any, _id: string): Promise<void> => {
    e.stopPropagation();
    if (!episodeId) return;
    newsDeleteMutate({ episodeId, newsItemId: _id });
  };

  React.useEffect(() => {
    if (newsDeleteData && !newsDeleteIsLoading && !newsDeleteError) {
      setTickerItems(newsDeleteData.news);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newsDeleteData, newsDeleteError, newsDeleteIsLoading]);

  const handleSetActiveItem = (item: IEpisodeTicker) => {
    if (activeItem?._id !== item._id) {
      setActiveItem(item);
    }
  };

  if (newsFetchIsLoading) return <div>Loading...</div>;

  return (
    <>
      <Collapse isOpen={isNewTickerOpen}>
        <NewsTickerNew
          handleNewCallBack={handleNewCallBack}
          handleNewClose={handleCloseNewTicker}
          profileId={episodeId as string}
        />
      </Collapse>

      <Card variant="profileFullPage">
        <Card.Header>
          <Box>News Ticker</Box>
          <Button onClick={() => setIsNewTickerOpen(true)}>New Item</Button>
        </Card.Header>

        <Card.Body>
          <Grid variant="table">
            {tickerItems?.map((item: IEpisodeTicker, index: number) => (
              <Grid
                key={item._id}
                variant="defaultGridList"
                onClick={() => handleSetActiveItem(item)}
                draggable={activeItem?._id !== item._id}
                onDragStart={(e: React.DragEvent<HTMLDivElement>) => handleDragStart(e, item._id)}
                onDragOver={e => {
                  e.preventDefault();
                }}
                onDrop={(e: React.DragEvent<HTMLDivElement>) => handleDragDrop(e, item._id)}
              >
                <Box variant="grids.listDefaultIcon">
                  <Icon path={mdiDrag} size={1} />
                </Box>
                <Box variant="grids.listDefaultDisplayText">{item.text}</Box>
                <Box sx={{ textAlign: "right" }}>
                  <Button onClick={e => handleRemoveItem(e, item._id)}>
                    <Icon path={mdiTrashCan} size={0.75} />
                  </Button>
                </Box>

                <Collapse
                  isOpen={activeItem?._id === item._id}
                  sx={{
                    gridColumnStart: "2"
                  }}
                >
                  <Grid
                    sx={{
                      gap: ".5rem",
                      width: "100%"
                    }}
                  >
                    <Input
                      onChange={handleOnChange}
                      value={activeItem?.text || ""}
                      type="text"
                      placeholder="News Ticker Text"
                      sx={{ width: ["100%", "75%", "80%"] }}
                    />
                    <Flex sx={{ gap: ".5rem" }}>
                      <Button sx={{ width: "6rem" }} onClick={handleSubmit}>
                        Submit
                      </Button>

                      <Button sx={{ width: "6rem" }} onClick={handleCloseEdit}>
                        Close
                      </Button>
                    </Flex>
                  </Grid>
                </Collapse>
              </Grid>
            ))}
          </Grid>
        </Card.Body>
      </Card>
    </>
  );
};
