import React from "react";

import { mdiChevronDown } from "@mdi/js";
import Icon from "@mdi/react";
import { menuArray } from "./menuArray";

import { useLocation, useNavigate } from "react-router-dom";
import { Box, Flex, Grid } from "theme-ui";
import { SubMenu } from "./SubMenu";
import * as styles from "./Sidebar.styles";

interface ISideBarProps {
  isSideBarOpen: boolean;
}

export const SideBar: React.FC<ISideBarProps> = ({ isSideBarOpen }) => {
  const navigate = useNavigate();
  const [openMenuId, setOpenMenuId] = React.useState<string>("");

  const location = useLocation();
  const currentPath = location.pathname.split("/");

  React.useEffect(() => {
    const currentMenu = menuArray.find(menu => menu.activePath.includes(currentPath[2]));

    if (currentMenu) {
      setOpenMenuId(currentMenu._id);
    }
  }, [currentPath]);

  const handleMenuClick = (e: React.MouseEvent<HTMLAnchorElement>, _id: string, path: string) => {
    e.preventDefault();

    if (openMenuId !== _id) {
      setOpenMenuId(_id);
      navigate(path, { replace: false });
    }
  };

  return (
    <Box sx={styles.sidebarWrapper(isSideBarOpen)}>
      <Box sx={styles.scroller}>
        <Box sx={styles.navTitle}>Navigation</Box>

        <ul>
          {menuArray.map(menu => (
            <li key={menu._id}>
              <a
                href={menu.path}
                onClick={e => handleMenuClick(e, menu._id, menu.path)}
                tabIndex={isSideBarOpen ? 0 : -1}
              >
                <Grid sx={styles.menuItem(menu._id === openMenuId)}>
                  <Flex sx={styles.icon}>
                    <Icon path={menu.icon} color={menu.iColor} />
                  </Flex>

                  <Box sx={styles.title}>{menu.displayName}</Box>

                  {menu.subMenu.length ? (
                    <Flex sx={styles.subMenuIcon}>
                      <Icon path={mdiChevronDown} />
                    </Flex>
                  ) : null}
                </Grid>
              </a>
              <SubMenu
                menuId={menu._id}
                openMenuId={openMenuId}
                isSideBarOpen={isSideBarOpen}
                subMenu={menu.subMenu}
              />
            </li>
          ))}
        </ul>
      </Box>
    </Box>
  );
};
