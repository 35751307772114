interface IControl {
	name: string;
	action: string;
	type?: string;
}

export const overlayActions: IControl[] = [
	{ name: "Next Topic", action: "topic-next" },
	{ name: "Previous Topic", action: "topic-prev" },
	{ name: "Pause Timer", action: "timer-pause" },
	{ name: "Resume Timer", action: "timer-resume" },
	{
		type: "gtkApplicationAction",
		name: "Refresh / Reset Overlay",
		action: "overlay-reset"
	},
	{ type: "gtkVoting", name: "Clear Votes", action: "clear-votes" }
];

export const overlayVideoActions: IControl[] = [
	{ name: "Play", action: "video-play" },
	{ name: "Pause", action: "video-pause" },
	{ name: "Stop", action: "video-stop" },
	{ name: "Seek Backwards", action: "video-seek-backward" },
	{ name: "Seek Forwards", action: "video-seek-forward" },
	{ name: "Mute", action: "video-volume-mute" },
	{ name: "Volume Down", action: "video-volume-down" },
	{ name: "Volume Up", action: "video-volume-up" },
	{ name: "Video Small", action: "video-size-small" },
	{ name: "Video Normal", action: "video-size-normal" },
	{ name: "Video Full Screen", action: "video-size-fullscreen" },
	{ name: "Video Custom Size 1", action: "video-size-custom-1" },
	{ name: "Video Custom Size 2", action: "video-size-custom-2" },
	{ name: "Video Custom Size 3", action: "video-size-custom-3" }
];

export const overlayVotingActions: IControl[] = [
	{ name: "Up Vote Host 1", action: "!v1" },
	{ name: "Down Vote Host 1", action: "!d1" },
	{ name: "Super Vote Host 1", action: "!sv1" },
	{ name: "Host 1 Wins", action: "!win1" },

	{ name: "Up Vote Host 2", action: "!v2" },
	{ name: "Down Vote Host 2", action: "!d2" },
	{ name: "Super Vote Host 2", action: "!sv2" },
	{ name: "Host 2 Wins", action: "!win2" },

	{ name: "Up Vote Host 3", action: "!v3" },
	{ name: "Down Vote Host 3", action: "!d3" },
	{ name: "Super Vote Host 3", action: "!sv3" },
	{ name: "Host 3 Wins", action: "!win3" },

	{ name: "Up Vote Host 4", action: "!v4" },
	{ name: "Down Vote Host 4", action: "!d4" },
	{ name: "Super Vote Host 4", action: "!sv4" },
	{ name: "Host 4 Wins", action: "!win4" }
];
