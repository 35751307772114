import React from "react";
import { Box } from "theme-ui";
import { Link } from "react-router-dom";
import { AppRoutes } from "../../../Types";
import * as styles from "./Sidebar.styles";

interface ISubMenuProps {
  menuId: string;
  openMenuId: string;
  isSideBarOpen: boolean;
  subMenu: {
    displayName: string;
    path: AppRoutes;
  }[];
}

export const SubMenu: React.FC<ISubMenuProps> = ({
  menuId,
  openMenuId,
  isSideBarOpen,
  subMenu
}) => {
  if (subMenu.length < 1) {
    return null;
  }

  return (
    <Box as="ul" sx={styles.subMenu(menuId === openMenuId, subMenu.length)}>
      {subMenu.map((subMenu, subIndex) => (
        <Box as="li" key={`${menuId}-${subIndex}`} sx={styles.subMenuItem}>
          <Link tabIndex={menuId === openMenuId && isSideBarOpen ? 0 : -1} to={subMenu.path}>
            {subMenu.displayName}
          </Link>
        </Box>
      ))}
    </Box>
  );
};
