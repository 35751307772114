import React, { ReactNode } from "react";
import { Box, BoxProps } from "theme-ui";
import { useCollaspe } from "./UseCollapse";

interface CollaspableProps {
  isOpen: boolean;
  children: ReactNode;

  marginBottom?: string;
  transitionSpeed?: string;
  transitionProperty?: string;
}

export const Collapse: React.FC<CollaspableProps & BoxProps> = ({
  isOpen = true,
  children,
  marginBottom = "12px",
  sx,
  transitionSpeed = "0.6s",
  transitionProperty = "all"
}) => {
  const { height, childRef } = useCollaspe(isOpen);

  return (
    <Box
      ref={childRef}
      sx={{
        minHeight: isOpen ? height : "0",
        height: isOpen ? height : "0",
        overflow: "hidden",
        marginBottom: isOpen ? marginBottom : "0",
        transition: `${transitionProperty} ${transitionSpeed} ease-in-out`,
        ...sx
      }}
    >
      {children}
    </Box>
  );
};

export default Collapse;
