import * as React from "react";
import { Box, Button, Grid, Input } from "theme-ui";
import { Card } from "../../../../theme/UI";

export interface IEpisodeListProps {
  handleNewHostToggle?: () => void;
  handleOpenDrawer: () => void;
  listState: any;
  setActiveHost: any;
}

export const HostList: React.FC<IEpisodeListProps> = ({
  handleNewHostToggle,
  handleOpenDrawer,
  listState,
  setActiveHost
}) => {
  const [searchTerm, setSearchTerm] = React.useState<string>("");

  const listStatefiltered = listState.filter((item: any) => {
    return item.name.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const handleSocialSelect = (host: any) => {
    setActiveHost(host);
    handleOpenDrawer();
  };

  return (
    <>
      <Card variant="fullPage">
        <Card.Header>
          <Box>Hosts</Box>

          <Button onClick={handleNewHostToggle}>Add New Host</Button>
        </Card.Header>

        <Card.HeaderForm>
          <Box>
            <Input
              type="text"
              name="hostSearch"
              placeholder="Search"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </Box>
        </Card.HeaderForm>

        <Card.Body>
          <Grid variant="table">
            {listStatefiltered.map((item: any) => (
              <Grid variant="hostList" key={item._id} onClick={() => handleSocialSelect(item)}>
                <Box sx={{ backgroundColor: "accent3" }}>{item.name.charAt(0).toUpperCase()}</Box>

                <div>
                  <div>{item.name}</div>
                  <div>Socials ({item.socials.length})</div>
                </div>
              </Grid>
            ))}
          </Grid>
        </Card.Body>
      </Card>
    </>
  );
};
