const inputDefaults = {
  padding: "0.375rem .5rem 0.375rem .5rem",
  border: "thin",
  borderColor: "accent6",
  borderRadius: "medium",
  backgroundColor: "background3",
  fontSize: 2,
  color: "text2",
  outline: "none",
  "&:focus": {
    color: "text1",
    borderColor: "accent3",
    backgroundColor: "background2"
  }
};

export const forms = {
  checkbox: {
    mr: 2,
    "input ~ &": {
      color: "accent2"
    },

    "input:checked ~ &": {
      color: "accent2"
    },

    "input:focus ~ &": {
      color: "accent2",
      backgroundColor: "transparent",
      border: "thin",
      borderColor: "accent2"
    }
  },
  input: {
    ...inputDefaults
  },
  label: {
    fontSize: 2,
    fontWeight: "medium",
    color: "text1",
    marginBottom: 2,
    alignItems: "center"
  },
  select: {
    ...inputDefaults
  },

  textarea: {
    ...inputDefaults,
    height: "auto"
  },

  cb: {
    accentColor: "green",
    bg: "red"
  },

  switch: {
    "input ~ &": {
      backgroundColor: "background4"
    },

    "input:checked ~ &": {
      backgroundColor: "accent3"
    }
  }
};
