import { AxiosError } from "axios";
import { useQuery, useMutation } from "react-query";
import httpService from "../httpService";

import {
  EpisodeSearchResponse,
  EpisodeSearchVariables,
  EpisodeFetchResponse,
  EpisodeCreationVariables,
  EpisodeCreationResponse,
  ApiResponse
} from "../Types";

const searchEpisodes = async (
  searchObj: EpisodeSearchVariables
): Promise<EpisodeSearchResponse> => {
  const { data } = await httpService.get<ApiResponse<EpisodeSearchResponse>>(
    `episodes/as/${searchObj.page}/${searchObj.sort}/${searchObj.order}?tid=${
      searchObj?.templateId || ""
    }&st=${searchObj.searchTerm || ""}`
  );
  return data.result;
};

export const useSearchEpisodes = () => {
  return useMutation<EpisodeSearchResponse, AxiosError<{ error: unknown }>, EpisodeSearchVariables>(
    ["episode-search"],
    searchObj => searchEpisodes(searchObj),
    {
      retry: 0
    }
  );
};

export const useFetchEpisode = (_id: string) => {
  return useQuery<{}, AxiosError<{ error: unknown }>, EpisodeFetchResponse>(
    ["episode", _id],
    async () => {
      const { data } = await httpService.get<ApiResponse<EpisodeFetchResponse>>(`episodes/${_id}`);
      return data.result;
    },
    { refetchOnWindowFocus: false, retry: 0 }
  );
};

const createEpisode = async (episodeObj: EpisodeCreationVariables) => {
  const { data } = await httpService.post<ApiResponse<EpisodeCreationResponse>>(
    `episodes`,
    episodeObj
  );

  return data.result;
};

export const useCreateEpisode = () => {
  return useMutation<
    EpisodeCreationResponse,
    AxiosError<{ error: unknown }>,
    EpisodeCreationVariables
  >(["episodeCreate"], episodeObj => createEpisode(episodeObj), {
    retry: 0
  });
};

const deleteEpisode = async (episodeId: string): Promise<boolean> => {
  const { data } = await httpService.delete<ApiResponse<boolean>>(`episodes/${episodeId}`);

  return data.result;
};

export const useDeleteEpisode = () => {
  return useMutation<boolean, AxiosError<{ error: unknown }>, string>(
    ["episodeInfoDelete"],
    episodeId => deleteEpisode(episodeId),
    {
      retry: 0
    }
  );
};
