export const topicImageParser = (
  image: string | undefined,
  width: number | undefined,
  height: number | undefined
) => {
  return image || `https://placehold.jp/3d4070/3d4070/${width || 200}x${height || 200}.png`;
};

export const topicImageThumbParser = (image: string | undefined) => {
  return image || `https://placehold.jp/3d4070/3d4070/200.png?text=+`;
};
