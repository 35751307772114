import * as React from "react";
import { useBreakpointIndex } from "@theme-ui/match-media";
import _cloneDeep from "lodash/cloneDeep";
import { Grid } from "theme-ui";
import { Collapse, Drawer } from "../../../theme/UI";

import { HostList } from "./HostList/HostList";
import { HostNew } from "./HostNew/HostNew";
import httpService from "../../../Api/httpService";
import { HostEdit } from "./HostEdit/HostEdit";

export const HostListing: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = React.useState<boolean>(false);
  const [isNewHostOpen, setIsNewHostOpen] = React.useState<boolean>(false);
  const [listState, setListState] = React.useState<any[]>([]);
  const [activeHost, setActiveHost] = React.useState<any>();
  const breakPointIndex = useBreakpointIndex();
  const columns = ["1fr", "1fr", "5fr 3fr"];

  React.useEffect(() => {
    let stillHere = true;

    const fetchData = async () => {
      const { data } = await httpService.get(process.env.REACT_APP_REST_API + "podcast-hosts");

      if (stillHere && data.resultStatus.success) {
        setListState(data.result.hosts);
      }
    };

    fetchData();

    return () => {
      stillHere = false;
    };
  }, []);

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleNewHostToggle = () => {
    setIsNewHostOpen(!isNewHostOpen);
  };

  const handleNewCallBack = async (host: any, hosts: any) => {
    if (!listState || !host) return;
    await setListState(hosts);
  };

  const handleEditCallBack = (host: any) => {
    if (!listState || !host) return;
    const newListState = _cloneDeep(listState);

    const hostIndex = newListState?.findIndex(s => s._id === host._id);

    if (hostIndex === -1) return;
    newListState[hostIndex] = host;
    setListState(newListState);
  };

  const handleDeleteCallBack = (_id: string) => {
    if (!listState || !_id) return;
    const newListState = _cloneDeep(listState);
    const newState = newListState.filter(host => host._id !== _id);

    setListState(newState);
    setActiveHost(undefined);
  };

  return (
    <>
      <Collapse isOpen={isNewHostOpen} sx={{ gridColumn: ["span 1", "span 2"] }}>
        <HostNew handleNewCallBack={handleNewCallBack} handleNewHostToggle={handleNewHostToggle} />
      </Collapse>

      <Grid columns={columns}>
        <HostList
          handleNewHostToggle={handleNewHostToggle}
          handleOpenDrawer={handleOpenDrawer}
          listState={listState}
          setActiveHost={setActiveHost}
        />

        {breakPointIndex > 1 && (
          <HostEdit
            activeHost={activeHost}
            handleDeleteCallBack={handleDeleteCallBack}
            handleEditCallBack={handleEditCallBack}
          />
        )}
      </Grid>

      {breakPointIndex <= 1 && (
        <Drawer isDrawerOpen={isDrawerOpen} handleCloseDrawer={handleCloseDrawer} title="Edit Host">
          <HostEdit
            activeHost={activeHost}
            handleEditCallBack={handleEditCallBack}
            handleDeleteCallBack={handleDeleteCallBack}
            showHeader={false}
          />
        </Drawer>
      )}
    </>
  );
};
