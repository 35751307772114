const drawerDefaults = {
  top: 18,
  position: "absolute",
  height: `calc(100vh - 4.5rem)`,
  background: "background1",
  boxSizing: "border-box",
  boxShadow: "1.25rem 1.25rem 1.25rem 1rem rgba(0, 0, 0, 0.25)",
  zIndex: 75,
  transition: "right 0.75s ease-in-out",
  overflow: "hidden"
};

export const drawer = {
  base: {
    ...drawerDefaults
  },
  header: {
    borderBottom: "1px solid",
    borderColor: "accent3",
    padding: ".75rem",
    marginBottom: "0.75rem",
    display: "flex",
    justifyContent: "space-between"
  },
  innerTabs: {
    ...drawerDefaults,
    top: "0",
    width: "200px"
  }
};
