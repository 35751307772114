import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useBreakpointIndex } from "@theme-ui/match-media";

import { AppRoutes } from "../../../../../Types";

import { Pagination } from "./Pagination";
import { Box, Button, Grid } from "theme-ui";
import { Card } from "../../../../../theme/UI";
import { useSearchEpisodes } from "../../../../../Api/useEpisodes/useEpisodes";
import { Link } from "react-router-dom";
import { EpisodeSearch } from "../EpisodeSearch/EpisodeSearch";

export interface IEpisodeListProps {
  handleOpenDrawer: () => void;
}

type Episode = {
  _id: string;
  name: string;
  templateName: string;
  current: boolean;
  airDate: string;
};

type EpisodeListState = {
  currentPage: string | number;
  episodes: Episode[];
  totalPages: number;
};

export const EpisodeList: React.FC<IEpisodeListProps> = ({ handleOpenDrawer }) => {
  const navigate = useNavigate();
  const breakPointIndex = useBreakpointIndex();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [listState, setListState] = React.useState<EpisodeListState | null>(null);
  const [sort, setSort] = React.useState<string>("airDate");
  const [searchTerm, setSearchTerm] = React.useState<string>("");
  const [sortDirection, setSortDirection] = React.useState<string>("desc");
  const [templateId, setTemplateId] = React.useState<string>("");

  const { data, error, isLoading, mutate } = useSearchEpisodes();

  React.useEffect(() => {
    const fetchDir = queryParams.get("dir") || "desc";
    const fetchSort = queryParams.get("sort") || "airDate";
    const fetchSearchTerm = queryParams.get("searchTerm") || "";
    const fetchTemplateId = queryParams.get("templateId") || "";

    setSortDirection(fetchDir);
    setSort(fetchSort);
    setSearchTerm(fetchSearchTerm);
    setTemplateId(fetchTemplateId);

    mutate({
      order: fetchDir,
      page: (queryParams.get("page") as unknown as number) || 1,
      sort: fetchSort,
      searchTerm: fetchSearchTerm,
      templateId: fetchTemplateId
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isLoading && !error) return;
    data && setListState(data);
  }, [data, isLoading, error]);

  const handlePageChange = async (page: number) => {
    mutate({
      order: sortDirection,
      page,
      sort,
      searchTerm,
      templateId
    });

    let navLink = `${AppRoutes.EpisodeList}?page=1&sort=${sort}&dir=${sortDirection}`;
    navLink += templateId && `&templateId=${templateId}`;
    navLink += searchTerm && `&searchTerm=${searchTerm}`;
    navigate(navLink);
  };

  return (
    <>
      <Card variant="fullPage">
        <Card.Header>
          <Box>Episode Listing</Box>
          {breakPointIndex <= 1 && <Button onClick={handleOpenDrawer}>New Episode</Button>}
        </Card.Header>

        <EpisodeSearch
          handleFormSubmit={() => handlePageChange(1)}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          setTemplateId={setTemplateId}
          templateId={templateId}
        />

        <Card.Body>
          <Grid variant="table">
            {listState?.episodes.map((episode: Episode) => (
              <Link key={episode._id} to={`${AppRoutes.EpisodeProfileLink}${episode._id}/topics`}>
                <Grid
                  aria-label={`Episode List Item: ${episode.name}`}
                  key={episode._id}
                  variant="episodeList"
                >
                  <Box sx={{ backgroundColor: "accent3" }}>
                    {episode?.templateName?.split(":")?.[0] || "GTK"}
                  </Box>

                  <div>
                    <div>{episode.name}</div>
                    <div>{episode?.templateName}</div>
                  </div>

                  <Box sx={{ textAlign: "right" }}>
                    <div>{episode.current ? "Current" : <div>&nbsp;</div>}</div>
                    <div>{episode.airDate}</div>
                  </Box>
                </Grid>
              </Link>
            ))}

            <Pagination
              currentPage={(listState?.currentPage as number) || 0}
              handlePageChange={handlePageChange}
              totalPages={listState?.totalPages || 0}
            />
          </Grid>
        </Card.Body>
      </Card>
    </>
  );
};
