import React from "react";
import { Box, Image } from "theme-ui";
import { Collapse, Divider } from "../../theme/UI";

interface ShowRunnerVideoProps {
  isVideoPlayerOpen: boolean;
  content: {
    type: string;
    file: string;
  };
}

export const ShowRunnerVideo: React.FC<ShowRunnerVideoProps> = ({ isVideoPlayerOpen, content }) => {
  const videoRef = React.useRef<HTMLVideoElement>(null);

  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  }, [isVideoPlayerOpen]);

  return content.type ? (
    <>
      <Collapse title="Video" isOpen={isVideoPlayerOpen}>
        <Box my={0}>
          <Divider sx={{ color: "#333" }} />

          <Box sx={{ width: ["100%", "100%", "300px"] }}>
            {content.type === "video" && (
              <video ref={videoRef} controls width="100%">
                <source src={content.file} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}

            {content.type === "image" && <Image src={content.file} alt="content" />}
          </Box>
        </Box>
      </Collapse>
    </>
  ) : null;
};
