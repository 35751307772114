import { ThemeUICSSObject } from "theme-ui";

export const header: ThemeUICSSObject = {
  width: "100vw",
  height: "4.5rem",
  gap: ".5rem",
  gridTemplateColumns: [
    "3.125rem 6.25rem 1fr",
    "3.125rem 6.25rem 1fr auto",
    "3.125rem 15.3125rem 1fr auto auto"
  ],
  alignItems: "center",
  justifyContent: "left",
  backgroundColor: "background3",
  position: "fixed",
  zIndex: "100",
  padding: 0,
  paddingRight: "1rem"
};

export const menuButton: ThemeUICSSObject = {
  cursor: "pointer",
  height: "100%",
  color: "font",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "background3",
  "&:hover": {
    filter: "brightness(1.35)"
  },
  svg: {
    width: "1.25rem"
  }
};

export const logo: ThemeUICSSObject = {
  fontSize: 4,
  textTransform: "uppercase",
  letterSpacing: "mega"
};
