import { mdiFile, mdiPodcast, mdiConnection, mdiViewDashboard } from "@mdi/js";
import { AppRoutes } from "../../../Types";

export const menuArray = [
  {
    _id: "1",
    displayName: "Dashboard",
    icon: mdiViewDashboard,
    iColor: "#8f5fe8",
    path: AppRoutes.DashBoard,
    activePath: "dashboard",
    subMenu: []
  },
  {
    _id: "2",
    displayName: "Talk Show Overlays",
    icon: mdiPodcast,
    iColor: "#ffab00",
    path: AppRoutes.EpisodeList,
    activePath: "podcast",
    subMenu: [
      { displayName: "Episodes", path: AppRoutes.EpisodeList },
      { displayName: "Hosts", path: AppRoutes.HostList },
      { displayName: "Controls", path: AppRoutes.ControlList },
      { displayName: "Social Networks", path: AppRoutes.SocialList },
      { displayName: "Overlays", path: AppRoutes.OverlayList },
      { displayName: "Commands", path: AppRoutes.CommandList },
      { displayName: "Canned Messages", path: AppRoutes.CannedMessagesList }
    ]
  },
  {
    _id: "4",
    displayName: "Connect to GTK",
    icon: mdiConnection,
    iColor: "#fc424a",
    path: AppRoutes.ConnectionTwitch,
    activePath: "connections",
    subMenu: [
      { displayName: "Twitch", path: AppRoutes.ConnectionTwitch },
      { displayName: "Youtube (coming soon)", path: AppRoutes.ConnectionYoutube }
    ]
  },

  {
    _id: "7",
    displayName: "Documentation",
    icon: mdiFile,
    iColor: "#0090e7",
    path: AppRoutes.Documentation,
    activePath: "documentation",
    subMenu: []
  }
];
