import React from "react";
import Icon from "@mdi/react";
import { mdiLogout } from "@mdi/js";

import { Box, Flex, Link } from "theme-ui";
import * as styles from "./HeaderUserMenu.styles";
import { useAuth } from "../../../../Hooks";

interface IHeaderUserMenuProps {
  isUserMenuOpen: boolean;
  setIsUserMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  userMenuButtonRef?: React.RefObject<HTMLDivElement>;
}

export const HeaderUserMenu: React.FC<IHeaderUserMenuProps> = ({
  isUserMenuOpen,
  setIsUserMenuOpen,
  userMenuButtonRef
}) => {
  const { signOut } = useAuth();
  const menuRef = React.useRef<HTMLUListElement>(null);

  const handleSignOut = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    await signOut(e);
  };

  const links = [
    {
      link: "/logout",
      label: "Log Out",
      icon: mdiLogout,
      action: handleSignOut
    }
  ];

  const handleKeyDown = (e: React.KeyboardEvent<HTMLUListElement | HTMLDivElement>) => {
    if (!menuRef.current) return;

    const items = menuRef.current.querySelectorAll<HTMLAnchorElement>("a");
    let index = Array.prototype.indexOf.call(items, document.activeElement);

    switch (e.key) {
      case "ArrowDown":
        index = (index + 1) % items.length;
        items[index].focus();
        e.preventDefault();
        break;
      case "ArrowUp":
        index = (index - 1 + items.length) % items.length;
        items[index].focus();
        e.preventDefault();
        break;
      case "Escape":
        userMenuButtonRef?.current?.focus();
        setIsUserMenuOpen(false);
        break;
      default:
        break;
    }
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target as Node) &&
        event.target !== userMenuButtonRef?.current
      ) {
        setIsUserMenuOpen(false);
      }
    };

    if (!menuRef.current) return;

    if (isUserMenuOpen && menuRef.current) {
      menuRef.current.querySelector<HTMLAnchorElement>("a")?.focus();
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isUserMenuOpen, setIsUserMenuOpen, userMenuButtonRef]);

  return (
    <Box
      as="ul"
      aria-labelledby="dropdownButton"
      className="dropdown-menu"
      ref={menuRef}
      role="menu"
      onKeyDown={handleKeyDown}
      sx={styles.userMenuWrapper(isUserMenuOpen)}
    >
      {links.map(({ action, link, label, icon }) => (
        <Flex as="li" key={label} role="none" sx={styles.userMenuWrapperItem}>
          <Link
            aria-label={label}
            href={link}
            onClick={action}
            role="menuitem"
            tabIndex={-1}
            sx={styles.userMenuWrapperItemLink}
          >
            <div>
              <Icon path={icon} color="red" />
            </div>
            <div>{label}</div>
          </Link>
        </Flex>
      ))}
    </Box>
  );
};
