import React from "react";

import { Box, Flex, Spinner } from "theme-ui";

export const Loading: React.FC = () => {
	return (
		<Flex
			sx={{
				position: "fixed",
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				zIndex: 9999
			}}
		>
			<Box
				sx={{
					margin: "auto"
				}}
			>
				<Spinner sx={{ opacity: ".1", width: "50vw", height: "50vh" }} />
			</Box>
		</Flex>
	);
};
