export enum AppRoutes {
  Login = "/login",
  SignUp = "/sign-up",
  Forgot = "/forgot-password",
  Home = "/home",
  DashBoard = "/admin/dashboard",

  Episodes = "/admin/podcast/episodes",
  EpisodeList = "/admin/podcast/episodes/list",
  EpisodeNew = "/admin/podcast/episodes/new",

  EpisodeProfile = "/admin/podcast/episodes/profile/:id",
  EpisodeProfileLink = "/admin/podcast/episodes/profile/",

  EpisodeProfileInfo = "/admin/podcast/episodes/profile/:id/info",
  EpisodeProfileTopics = "/admin/podcast/episodes/profile/:id/topics",
  EpisodeProfileHosts = "/admin/podcast/episodes/profile/:id/hosts",
  EpisodeProfileNews = "/admin/podcast/episodes/profile/:id/news",
  EpisodeProfileSocials = "/admin/podcast/episodes/profile/:id/social-networks",
  EpisodeProfileLinks = "/admin/podcast/episodes/profile/:id/links",
  EpisodeProfileImages = "/admin/podcast/episodes/profile/:id/images",

  Hosts = "/admin/podcast/hosts",
  HostList = "/admin/podcast/hosts/list",
  HostNew = "/admin/podcast/hosts/new",
  HostProfile = "/admin/podcast/hosts/:id",
  HostProfileLink = "/admin/podcast/hosts/",

  Socials = "/admin/podcast/social-networks",
  SocialList = "/admin/podcast/social-networks/list",
  SocialNew = "/admin/podcast/social-networks/new",
  SocialProfile = "/admin/podcast/social-networks/:id",
  SocialProfileLink = "/admin/podcast/social-networks/",

  Overlays = "/admin/podcast/overlays",
  OverlayList = "/admin/podcast/overlays/list",

  TemplateNew = "/admin/podcast/templates/new",
  TemplateProfile = "/admin/podcast/templates/:id",
  TemplateProfileLink = "/admin/podcast/templates/",

  Controls = "/admin/podcast/controls",
  ControlList = "/admin/podcast/controls/list",

  Commands = "/admin/podcast/commands",
  CommandList = "/admin/podcast/commands/list",

  CannedMessages = "/admin/podcast/cannedMessages",
  CannedMessagesList = "/admin/podcast/cannedMessages/list",

  ChatBots = "/admin/chat-bots",
  ChatBotsConnectTwitch = "/admin/chat-bots/connect-twitch",

  Connections = "/admin/connections",
  ConnectionTwitch = "/admin/connections/twitch",
  ConnectionTwitchRedirect = "/admin/connections/connect-twitch",

  ConnectionYoutube = "/admin/connections/youtube",

  Documentation = "/admin/documentation",

  ShowRunner = "/public/show-runner/:id",
  ShowRunnerLink = "/public/show-runner/",

  YoutubeDesc = "/public/youtube-desc/:id",
  YoutubeDescLink = "/public/youtube-desc/"
}
